import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Radio } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Modal } from 'antd';
import { AiOutlineZoomIn } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { useNavigate, useParams } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';

function ERacks() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [rackId, setRackId] = useState(null);
	const [fracks, setFRacks] = useState([]);
	const [racks, setRacks] = useState([]);
	const [mainStockRacks, setMainStockRacks] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [stocks, setStocks] = useState([]);
	const [rId, setRId] = useState([]);
	const [racksDetail, setRacksDetail] = useState([]);
	const [productState, setProductState] = useState('');
	const [productTotalQuantity, setProductTotalQuantity] = useState();
	const [productId, setProductID] = useState('');
	const [stores, setStores] = useState([]);
	const [storeId, setStoreId] = useState([]);
	const [stockLocation, setStockLocation] = useState('');
	const [selectStoreRack, setSelectStoreRack] = useState([]);
	const [ambName, setAmbName] = useState('');
	const [sellerName, setSellerName] = useState('');
	const [storeName, setStoreName] = useState('');
	const [selectedRadios, setSelectedRadios] = useState([]);

	const [location, setLocation] = useState(null);
	const [weather, setWeather] = useState(null);

	function success(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setLocation({ latitude, longitude });
		// console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=db0e108a510e5ec03389a47fdbeefddf&units=metric`
		)
			.then((response) => response.json())
			.then((data) => {
				setWeather(data);
				// console.log(data);
			})
			.catch((error) => console.log(error));
	}
	function error() {
		console.log('Unable to retrieve your location');
	}

	const handleCreateRack = (values) => {};
	useEffect(() => {
		setFRacks(racks?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) })));
	}, [racks]);

	const ownedRacks = racks?.filter((own) => own?.type === 'owned');
	const onRentalRacks = racks?.filter((own) => own?.type === 'onrental');
	const myRentalRacks = racks?.filter((own) => own?.type === 'myrental');

	const filteredData = fracks?.filter((item) => {
		return item?.name?.includes(searchTerm);
	});

	const updateSend = (values) => {};

	const handleSelectRack = (e, rack) => {
		if (e?.target?.checked) {
			setRId((prev) => [
				...prev,
				{ rackId: rack._id, name: rack.name, qty: 0 },
			]);
		} else if (e?.target?.checked === false) {
			setRId(rId.filter((rack) => rack !== e?.target?.value));
		}
	};

	const handleChange = (e, option) => {
		if (e?.target.checked === true) {
			selectedRadios.push(e?.target?.value);
			setRId((prev) => [
				...prev,
				{
					rackId: option._id,
					name: option.name,
					price: 0,
					front: option?.front,
					back: option?.back,
					height: option?.height,
					rackType: option?.rackType,
				},
			]);
		} else if (e?.target?.checked === false) {
			setSelectedRadios(
				selectedRadios.filter((rack) => rack !== e?.target?.value)
			);
			const filteredId = rId.filter(({ Id }) => Id !== option._id);
			setRId(filteredId);
		}
	};

	function fn_handleRacksData(e, rack) {
		const qty = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].qty = qty;
		setRacksDetail(tempRacks);
	}
	function fn_handleRacksData2(e, rack) {
		const price = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.Id === rack.Id);
		tempRacks[itemIndex].price = price;
		setRacksDetail(tempRacks);
	}

	const addTransferIn = (values) => {
		const params = {
			productID: productId,
			sellerID: localStorage.getItem('sellerId'),
			storeID: id,
			racksDetail: racksDetail,
			remaining: racksDetail.reduce((acc, i) => {
				return acc + parseInt(i?.qty);
			}, 0),
		};
		console?.log(params);
		axios
			.patch(`${ApiUrl}/mainStockRack/transferFromStore`, params)
			.then((res) => {
				if (res?.data?.status) {
					toast.success('Product Transfer to Main Stock Successfully');
				}
			});
	};

	const handleStoreTransfer = (values) => {
		try {
			const params = {
				productID: productId,
				fromStoreId: id,
				toStoreId: values?.storeID?.value,
				racksDetail: racksDetail,
				remaining: racksDetail.reduce((acc, i) => {
					return acc + parseInt(i?.qty);
				}, 0),
			};
			console.log(params);
			axios
				.patch(`${ApiUrl}/e-stock/transferStoreToStore`, params)
				.then((res) => {
					if (res?.data?.status) {
						toast.success('Product Transfer to Store Sucessfully');
					}
				})
				.catch((err) => {
					toast.error(err?.response?.data?.error);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const handleRackRent = (values) => {};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						{/******************  Create Racks Modal Start ******************/}
						<div
							className='modal fade'
							id='createRacks'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleCreateRack(e?.target);
								}}
							>
								<div class='modal-dialog'>
									<div class='modal-content'>
										<div class='modal-header'>
											<h1
												class='modal-title fs-5'
												id='exampleModalLabel'
											>
												Create Racks
											</h1>
											<button
												type='button'
												class='btn-close'
												data-bs-dismiss='modal'
												aria-label='Close'
											></button>
										</div>
										<div className='modal-body text-start'>
											<span style={{ fontWeight: '600' }}>
												Store Stock Name :
											</span>
											<input
												className='form-control mt-1 mb-2'
												placeholder='WareHouseName'
												value={stocks?.[0]?.warehouseName}
												disabled
											/>
											<span style={{ fontWeight: '600' }}>
												Number of Racks to be Made:
											</span>
											<input
												type='number'
												name='rackQuantity'
												className='form-control mt-1 mb-2'
												placeholder='Enter Quantity'
											/>
											<span style={{ fontWeight: '600' }}>Rack Type:</span>
											<br />
											<Radio.Group
												defaultValue={''}
												buttonStyle='solid'
												className='mx-1 mt-1 mb-2'
												name='rackType'
											>
												<Radio.Button value='Floor Rack'>
													Floor Rack
												</Radio.Button>
												<Radio.Button value='Hanger Rack'>
													Hanger Rack
												</Radio.Button>
												<Radio.Button value='Shelf Rack'>
													Shelf Rack
												</Radio.Button>
											</Radio.Group>
											<br />
											<span style={{ fontWeight: '600' }}>Rack Size:</span>
											<br />
											<div className='row mb-1'>
												<div
													className='col-2'
													style={{ display: 'grid', placeItems: 'center' }}
												>
													<label>Front</label>
												</div>
												<div className='col-5'>
													<Radio.Group
														defaultValue={''}
														buttonStyle='solid'
														className='mx-1'
														name='frontUnit'
													>
														<Radio.Button value='ft'>Feet</Radio.Button>
														<Radio.Button value='inch'>Inch</Radio.Button>
														<Radio.Button value='meter'>Meter</Radio.Button>
													</Radio.Group>
												</div>
												<div className='col-5'>
													<input
														placeholder='Enter Front Size'
														name='frontSize'
														style={{
															height: '2rem',
															borderRadius: '0.3rem',
															border: '1px solid #DEE2E6',
															paddingLeft: '0.5rem',
														}}
													/>
												</div>
											</div>
											<div className='row mb-1'>
												<div
													className='col-2'
													style={{ display: 'grid', placeItems: 'center' }}
												>
													<label>Back</label>
												</div>
												<div className='col-5'>
													<Radio.Group
														defaultValue={''}
														buttonStyle='solid'
														className='mx-1'
														name='backUnit'
													>
														<Radio.Button value='ft'>Feet</Radio.Button>
														<Radio.Button value='inch'>Inch</Radio.Button>
														<Radio.Button value='meter'>Meter</Radio.Button>
													</Radio.Group>
												</div>
												<div className='col-5'>
													<input
														placeholder='Enter Back Size'
														name='backSize'
														style={{
															height: '2rem',
															borderRadius: '0.3rem',
															border: '1px solid #DEE2E6',
															paddingLeft: '0.5rem',
														}}
													/>
												</div>
											</div>
											<div className='row mb-1'>
												<div
													className='col-2'
													style={{ display: 'grid', placeItems: 'center' }}
												>
													<label>Height</label>
												</div>
												<div className='col-5'>
													<Radio.Group
														defaultValue={''}
														buttonStyle='solid'
														className='mx-1'
														name='heightUnit'
													>
														<Radio.Button value='ft'>Feet</Radio.Button>
														<Radio.Button value='inch'>Inch</Radio.Button>
														<Radio.Button value='meter'>Meter</Radio.Button>
													</Radio.Group>
												</div>
												<div className='col-5'>
													<input
														placeholder='Enter Height'
														name='heightSize'
														style={{
															height: '2rem',
															borderRadius: '0.3rem',
															border: '1px solid #DEE2E6',
															paddingLeft: '0.5rem',
														}}
													/>
												</div>
											</div>
										</div>
										<div class='modal-footer'>
											<button
												type='button'
												class='btn btn-secondary'
												data-bs-dismiss='modal'
											>
												Close
											</button>
											<button
												type='submit'
												class='btn btn-success'
												data-bs-dismiss='modal'
												style={{
													backgroundColor: '#ffd700',
													color: 'black',
													border: 'none',
												}}
											>
												Create Racks
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
						{/****************** Move to Main Modal Start ******************/}
						<div
							class='modal fade'
							id='moveToMain'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Product Transfer to Main Stock
										</h5>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<form>
											<div className='col-md-12 text-start mb-2'>
												<div>
													<label
														className='mb-2'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Product
													</label>
													<select
														className='form-control'
														onChange={(e) => {
															setProductID(e?.target?.value);
														}}
														name='productID'
														id='productId'
														style={{ textTransform: 'capitalize' }}
													>
														<option value={''}>---Select Product---</option>
														{fracks &&
															fracks?.map((item) => {
																if (item?.product !== '') {
																	return (
																		<option value={item?.product}>
																			{item?.productName}
																		</option>
																	);
																}
															})}
													</select>
												</div>
												<div
													className='my-2'
													style={{ fontSize: '1.1rem', fontWeight: '500' }}
												>
													Select Main Stock Racks:
												</div>
												<div>
													{mainStockRacks &&
														mainStockRacks?.map((rack) => (
															<>
																<input
																	type='checkbox'
																	value={rack?._id}
																	disabled={rack?.state === 'occupied' && true}
																	onChange={(e) => handleSelectRack(e, rack)}
																/>
																<b className='mx-1'>{rack?.name}</b>
																<span style={{ fontSize: '12px' }}>
																	(F : {rack?.front}, B : {rack?.back}, H :{' '}
																	{rack?.height})
																</span>
																<span
																	style={{ fontSize: '13.5px', color: 'gray' }}
																	className='ms-1'
																>
																	[{rack?.rackType}]
																</span>
																<br />
															</>
														))}
												</div>
												<div className='row'>
													{rId?.map((rackData) => (
														<div className='col-6 mt-2 mb-3'>
															<input
																type='number'
																style={{ width: '200px' }}
																placeholder={
																	'Enter Quantity for ' + rackData?.name
																}
																className='racks_input'
																onChange={(e) =>
																	fn_handleRacksData(e, rackData)
																}
															/>
														</div>
													))}
												</div>
											</div>
											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
													onClick={(e) => {
														e.preventDefault();
														addTransferIn(e.target);
													}}
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
														border: 'none',
													}}
												>
													Save changes
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/****************** Move to Store Modal Start ******************/}
						<div
							class='modal fade'
							id='moveToStore'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Product Transfer to Store
										</h5>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleStoreTransfer(e?.target);
											}}
										>
											<div className='col-md-12 text-start mb-2'>
												<div>
													<label
														className='mb-2'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Product
													</label>
													<select
														className='form-select'
														onChange={(e) => {
															setProductID(e?.target?.value);
														}}
														name='productID'
														id='productId'
														style={{ textTransform: 'capitalize' }}
													>
														<option value={''}>---Select Product---</option>
														{fracks &&
															fracks?.map((item) => {
																if (item?.product !== '') {
																	return (
																		<option value={item?.product}>
																			{item?.productName}
																		</option>
																	);
																}
															})}
													</select>
												</div>
												<div>
													<label
														className='mb-2 mt-3'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Store
													</label>
													<select
														className='form-select'
														name='storeID'
														id='storeId'
														style={{ textTransform: 'capitalize' }}
														onChange={(e) => setStoreId(e?.target?.value)}
													>
														<option
															value={''}
															selected
														>
															---Select Store where Product Transfer---
														</option>
														{stores &&
															stores?.map((item) => {
																if (item?._id !== id) {
																	return (
																		<option value={item?._id}>
																			{item?.name}
																		</option>
																	);
																}
															})}
													</select>
												</div>
												{stockLocation && (
													<>
														<p
															style={{
																fontSize: '1.05rem',
																textAlign: 'right',
																margin: '0.5rem 0',
															}}
														>
															<MdLocationOn />
															&nbsp;
															{stockLocation}
														</p>
													</>
												)}
												<div
													className='mt-3'
													style={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
													}}
												>
													{selectStoreRack ? (
														selectStoreRack?.map((rack) => {
															return (
																<div className='text-start mb-2'>
																	<input
																		type='checkbox'
																		disabled={
																			rack?.state === 'occupied' && true
																		}
																		onChange={(e) => handleSelectRack(e, rack)}
																	/>
																	<label>
																		<b>{rack?.name}</b>{' '}
																		<span style={{ fontSize: '12px' }}>
																			(F : {rack?.front}, B : {rack?.back}, H :{' '}
																			{rack?.height}) [{rack?.rackType}]
																		</span>
																	</label>
																</div>
															);
														})
													) : (
														<>No Stock Available</>
													)}
												</div>
												<div>
													{rId?.map((rackData) => (
														<div className='col-6 mt-2 mb-3'>
															<input
																type='number'
																style={{ width: '200px' }}
																placeholder={
																	'Enter capacity for ' + rackData?.name
																}
																className='racks_input'
																onChange={(e) =>
																	fn_handleRacksData(e, rackData)
																}
															/>
														</div>
													))}
												</div>
											</div>
											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
														border: 'none',
													}}
												>
													Save Changes
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/****************** Transfer for Rent Start ******************/}
						<div
							class='modal fade'
							id='transferForRent'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Racks Transfer for Rent
										</h5>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleRackRent(e?.target);
											}}
										>
											<div className='col-md-12 text-start mb-2'>
												<div>
													<label
														className='mb-2'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Select Rack for Rent
													</label>
													<div>
														{fracks &&
															fracks?.map((item) => {
																if (item?.type === 'owned') {
																	return (
																		<>
																			<input
																				type='checkbox'
																				disabled={
																					item?.state === 'occupied' && true
																				}
																				onChange={(e) => handleChange(e, item)}
																			/>
																			<b className='mx-1'>{item?.name}</b>
																			<span style={{ fontSize: '12px' }}>
																				(F : {item?.front}, B : {item?.back}, H
																				: {item?.height})
																			</span>
																			<span
																				style={{ fontSize: '14px' }}
																				className='mx-1'
																			>
																				[{item?.rackType}]
																			</span>
																			<br />
																		</>
																	);
																}
															})}
													</div>
												</div>
												<div className='row'>
													{rId.length > 0 && (
														<label
															className='mt-2 col-12'
															style={{ fontSize: '1.1rem', fontWeight: '500' }}
														>
															Enter Racks Rent price per Month
														</label>
													)}
													{rId?.map((rackData) => (
														<div className='col-6 mt-2 mb-3'>
															<input
																type='number'
																style={{ width: '200px' }}
																placeholder={
																	'Enter Price for ' + rackData?.name
																}
																className='racks_input'
																onChange={(e) =>
																	fn_handleRacksData2(e, rackData)
																}
															/>
														</div>
													))}
												</div>
												<div>
													<label
														className='mb-1'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Rack Image
													</label>
													<input
														type='file'
														id='image'
														name='image'
														className='form-control'
														placeholder='Paste Image'
													/>
												</div>
											</div>
											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
														border: 'none',
													}}
												>
													Save Changes
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between'>
									<h5 className='kk ps-3'>Manage E-Racks</h5>
									<div>
										<button
											className='btn btn-success'
											data-bs-toggle='modal'
											data-bs-target='#transferForRent'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Transfer for Rent
										</button>
										<button
											className='btn btn-success ms-2'
											data-bs-toggle='modal'
											data-bs-target='#moveToStore'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Move to Store
										</button>
										<button
											className='btn btn-success ms-2'
											data-bs-toggle='modal'
											data-bs-target='#moveToMain'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Move to Main Rack
										</button>
										<button
											className='btn btn-success ms-2'
											data-bs-toggle='modal'
											data-bs-target='#createRacks'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Create Rack
										</button>
									</div>
								</div>

								<div className='all_categories'>
									<ul
										class='nav nav-pills mb-3'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='pills-Owned-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-Owned'
												type='button'
												role='tab'
												aria-controls='pills-Owned'
												aria-selected='true'
											>
												Owned
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link '
												id='pills-rentSide-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-rentSide'
												type='button'
												role='tab'
												aria-controls='pills-rentSide'
												aria-selected='false'
											>
												My Rental
											</button>
										</li>
									</ul>

									<div
										class='tab-content'
										id='pills-tabContent'
									>
										{/* owned data */}
										<div
											class='tab-pane fade show active'
											id='pills-Owned'
											role='tabpanel'
											aria-labelledby='pills-Owned-tab'
										>
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {ownedRacks?.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Rack'
													/>
													{/* <button type="button" class="btn btn-primary ms-2" data-bs-toggle="modal" data-bs-target="#exampleModall" style={{width:"15rem"}}>
                          Transfer Racks
                        </button> */}
												</div>
											</div>
											<div className='row g-4'>
												{filteredData?.map((item) => {
													if (item?.type === 'owned') {
														if (!item?.forSell) {
															return (
																<div className='col-md-3'>
																	{/* owned Racks */}
																	<div
																		className='p-2'
																		style={{
																			backgroundColor: '#fff',
																			border: ' 1px solid rgb(243, 239, 239)',
																			borderRadius: '10px',
																		}}
																	>
																		<div
																			className='order'
																			style={{
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'space-between',
																			}}
																		>
																			<div
																				className=''
																				data-bs-toggle='modal'
																				data-bs-target='#exampleModal12'
																				onClick={() => setRackId(item?._id)}
																			>
																				<h4>{item?.name}</h4>
																			</div>
																			<div style={{ zIndex: '999' }}>
																				{item?.hasDimensions && (
																					<span
																						style={{
																							fontSize: '0.95rem',
																							backgroundColor:
																								'rgb(220 231 244)',
																							padding: '0.2rem 0.08rem',
																							borderRadius: '0.1rem',
																							textAlign: 'center',
																						}}
																					>
																						front:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.front}
																						</span>
																						, back:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.back}
																						</span>
																						, height:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.height}
																						</span>
																					</span>
																				)}
																			</div>
																		</div>

																		<div className='d-flex justify-content-between pt-3'>
																			<p>
																				{item?.state === 'empty' ? (
																					<span
																						style={{
																							textTransform: 'capitalize',
																						}}
																					>
																						{item?.state}
																					</span>
																				) : (
																					<>
																						Product : <b>{item?.productName}</b>
																						<br />
																						Qty :{' '}
																						<b>
																							{item?.productQty}{' '}
																							{item?.productUnit}
																						</b>
																					</>
																				)}
																			</p>
																			{item?.state === 'empty' ? (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<AiFillCloseCircle
																						size={20}
																						color='red'
																					/>
																				</div>
																			) : (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<i
																						class='fa-solid fa-check'
																						style={{
																							fontSize: '18px',
																							color: ' #ffd700',
																						}}
																					></i>
																				</div>
																			)}
																		</div>
																		<hr />
																		{item?.hasDimensions && (
																			<div className='mb-2'>
																				<span>
																					Type : <b>{item?.rackType}</b>
																				</span>
																			</div>
																		)}
																		<div>
																			<button
																				onClick={() =>
																					navigate(
																						`/viewRackProduct/${item?._id}`
																					)
																				}
																				class='btn w-100 text-right btn-success'
																			>
																				View Rack
																			</button>
																		</div>
																	</div>

																	{/* owned Racks Modal */}

																	<div
																		class='modal fade'
																		id='exampleModal12'
																		tabindex='-1'
																		aria-labelledby='exampleModalLabel'
																		aria-hidden='true'
																	>
																		<div class='modal-dialog w-100'>
																			<div class='modal-content w-100'>
																				<div class='modal-body w-100'>
																					<form
																						onSubmit={(e) => {
																							e.preventDefault();
																							updateSend(e.target);
																						}}
																					>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Width (ft)
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<input
																									className='form-control'
																									name='width'
																									placeholder='Enter Size'
																								/>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Length (ft)
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<input
																									className='form-control'
																									name='height'
																									placeholder='Enter Size'
																								/>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									Type
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<select
																									class='form-control-input-2 p-1'
																									aria-label='Default select example'
																									name='type'
																								>
																									<option
																										value=''
																										disabled
																										selected
																									>
																										---select---
																									</option>
																									<option value='owned'>
																										Owned
																									</option>
																									<option value='myrental'>
																										My Rental
																									</option>
																									{/* <option value="onrental">On Rent</option> */}
																								</select>
																							</div>
																						</div>
																						<div className='row  pt-4  align-items-center'>
																							<div className='col-md-2'>
																								<label
																									for='inputPassword6'
																									className='form-label'
																								>
																									State
																								</label>
																							</div>
																							<div className='col-md-10'>
																								<select
																									class='form-control-input-2'
																									name='state'
																								>
																									<option
																										value=''
																										selected
																										disabled
																									>
																										---Select---
																									</option>
																									<option value='occupied'>
																										Occupied
																									</option>
																									<option value='empty'>
																										Empty
																									</option>
																								</select>
																							</div>
																						</div>
																						<div className='row'>
																							<div className='col-lg-6 col-md-12 py-3'>
																								<button
																									type='button'
																									class='cancel-btn btn-lg'
																									data-bs-dismiss='modal'
																								>
																									Cancel
																								</button>
																							</div>
																							<div className='col-lg-6 col-md-12 py-3'>
																								<button
																									type='submit'
																									class='add-last-btn btn-lg'
																									data-bs-dismiss='modal'
																									style={{
																										backgroundColor: '#ffd700',
																										color: 'black',
																										border: 'none',
																									}}
																								>
																									Save
																								</button>
																							</div>
																						</div>
																					</form>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														}
													}
												})}
											</div>
										</div>

										{/* myrental data */}
										<div
											class='tab-pane fade '
											id='pills-rentSide'
											role='tabpanel'
											aria-labelledby='pills-rentSide-tab'
										>
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {myRentalRacks?.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Rack'
													/>
												</div>
											</div>
											<div className='row g-4'>
												{filteredData &&
													filteredData.map((item) => {
														if (item?.type === 'myrental') {
															if (item?.forSell) {
																return (
																	<div className='col-md-3'>
																		<div
																			className='p-2'
																			style={{
																				backgroundColor: '#fff',
																				border: ' 1px solid rgb(243, 239, 239)',
																				borderRadius: '10px',
																			}}
																		>
																			<div
																				className='order'
																				style={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'space-between',
																				}}
																			>
																				<div>
																					<h4>{item?.name}</h4>
																				</div>
																				<div style={{ zIndex: '999' }}>
																					{item?.hasDimensions && (
																						<span
																							style={{
																								fontSize: '0.95rem',
																								backgroundColor:
																									'rgb(220 231 244)',
																								padding: '0.2rem 0.08rem',
																								borderRadius: '0.1rem',
																								textAlign: 'center',
																							}}
																						>
																							front:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.front}
																							</span>
																							, back:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.back}
																							</span>
																							, height:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.height}
																							</span>
																						</span>
																					)}
																				</div>
																			</div>

																			<div className='d-flex justify-content-between pt-3'>
																				<p>
																					{item?.state === 'empty' ? (
																						<span
																							style={{
																								textTransform: 'capitalize',
																							}}
																						>
																							{item?.state}
																						</span>
																					) : (
																						<>
																							Product :{' '}
																							<b>{item?.productName}</b>
																							<br />
																							Qty :{' '}
																							<b>
																								{item?.productQty}{' '}
																								{item?.productUnit}
																							</b>
																						</>
																					)}
																				</p>
																				{item?.state === 'empty' ? (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<AiFillCloseCircle
																							size={20}
																							color='red'
																						/>
																					</div>
																				) : (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<i
																							class='fa-solid fa-check'
																							style={{
																								fontSize: '18px',
																								color: ' #ffd700',
																							}}
																						></i>
																					</div>
																				)}
																			</div>
																			<hr />
																			{item?.hasDimensions && (
																				<div className='mb-2'>
																					<span>
																						Type : <b>{item?.rackType}</b>
																					</span>
																				</div>
																			)}
																			<div>
																				<button
																					onClick={() =>
																						navigate(
																							`/viewRackProduct/${item?._id}`
																						)
																					}
																					class='btn w-100 text-right btn-success'
																				>
																					View Rack
																				</button>
																			</div>
																		</div>
																	</div>
																);
															}
														}
													})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default ERacks;
