import axios from "axios";
import DetailSlider from "./DetailSlider";
import ApiUrl from "../BaseUrl";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BasicDetails = ({ singleProduct, catName, countryName }) => {
  const { id } = useParams()
  const [data, setData] = useState("");
  const handleSingleProduct = async () => {
    try {
      axios.get(`${ApiUrl}/productSelling/getById?id=${id}`).then((res) => {
        if (res?.data) {
          setData(res?.data?.multiImages);
        } else {
          axios.get(`${ApiUrl}/recycling/getById?id=${id}`).then((res) => {
            setData(res?.data?.multiImages);
          })
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleSingleProduct()
  }, [])
  return (
    <div>
      <div>
        <div>
          <div className="row g-3">
            <div className="col-lg-5 col-md-5">
              <DetailSlider
                data={data}
                onDataChange={handleSingleProduct}
              />
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="details __h-100">
                <div className="mb-3 text-start">
                  <span className="h5 font-weight-normal mr-1 text-success">
                    {catName}
                  </span>
                </div>
                <div className="mb-2 text-start">
                  <span className="h5 font-weight-normal mr-1">
                    {singleProduct?.name}
                    <span className="h6 mx-2">({singleProduct?.state})</span>
                  </span>
                </div>
                <div className="d-flex mb-2">
                  <div>Unit Price :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-2 h6">
                      {singleProduct?.productPrice} Rs /{" "}
                      {singleProduct?.unit}
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>Company Comission :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-2 h6">
                      {singleProduct?.companyComission}%
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>Ambassador Comission :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-2 h6">
                      {singleProduct?.ambassadorComission} %
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>Referral Comission :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-2 h6">
                      {singleProduct?.referralComission} %
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>Selling Price :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-2 h6">
                      {singleProduct?.retailPrice} / {singleProduct?.unit}
                    </span>
                  </div>
                </div>
                <hr />
                {singleProduct?.daySelect && (
                  <div className="d-flex mb-2">
                    <div>Life Day from Sale Day :</div>
                    <div>
                      <span id="set-tax-amount" className=" mx-1 h6">
                        {parseInt(singleProduct?.daySelect?.y) ===
                          0 ? null : (
                          <>{singleProduct?.daySelect?.y} Year</>
                        )}
                        &nbsp;
                        {parseInt(singleProduct?.daySelect?.m) ===
                          0 ? null : (
                          <>{singleProduct?.daySelect?.m} Month</>
                        )}
                        &nbsp;
                        {parseInt(singleProduct?.daySelect?.w) ===
                          0 ? null : (
                          <>{singleProduct?.daySelect?.w} Week</>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div className="d-flex mb-2">
                  <div>Product Code :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-1 h6">
                      {singleProduct?.code}
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>Product Quality :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-1 h6">
                      {singleProduct?.quality}
                    </span>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div>
                    <span id="set-tax-amount" className="mx-1 h6">
                      Made in {countryName}
                    </span>
                  </div>
                </div>
                <hr />

                <div className="text-start mb-2">
                  <div className="ms-1">
                    <h6>Delivery :</h6>
                  </div>
                  <div>
                    {singleProduct?.delivery?.map((delivery) => {
                      if (delivery?.check !== "") {
                        return (
                          <>
                            <span id="set-tax-amount" className="mx-1">
                              <span className="text-success fw-bold">
                                {delivery?.check}
                              </span>{" "}
                              <span className="fw-bold">
                                (COD : {delivery?.cod})
                              </span>{" "}
                              Charges :{" "}
                              <span className="text-success fw-bold">
                                {delivery?.charges}
                              </span>
                            </span>
                            <br />
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
                <hr />

                <div className="mb-2 text-start">
                  <div className="h6">Product Production Time :</div>
                  <div>
                    <span id="set-tax-amount" className="mx-1">
                      {singleProduct?.productionUnit?.d}{" "}
                      {singleProduct?.unit} in One Day
                      <br />
                      {singleProduct?.productionUnit?.w}{" "}
                      {singleProduct?.unit} in One Week
                      <br />
                      {singleProduct?.productionUnit?.m}{" "}
                      {singleProduct?.unit} in One Month
                      <br />
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;