import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ApiUrl from '../screen/Pages/BaseUrl';
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import { Input } from 'antd';
import { AiOutlineCopy } from 'react-icons/ai';

function Nav({ press }) {
	const navigate = useNavigate();
	const [darkTheme, setDarkTheme] = React.useState(false);
	const [demand, setDemand] = useState([]);
	const [checkCategoryId, setCheckCategoryId] = useState('');
	const [checkCategory, setCheckCategory] = useState([]);

	const handleCopy = (ID) => {
		toast.success('Wallet ID Copied');
		copy(ID);
	};

	const filterCategory = checkCategory?.filter(
		(cat) => cat?._id === checkCategoryId
	);
	// console.log(filterCategory,"============filtercat")

	const filteredDemand = demand?.filter(
		(data) => data?.category === filterCategory[0]?.title
	);
	console.log(filteredDemand);

	return (
		<>
			<ToastContainer />
			<div
				className={`Nav2 px-3 shadow-sm bg-white d-flex justify-content-between align-items-center `}
			>
				<div
					className='q'
					style={{
						fontSize: '17px',
						color: ' #ffd700',
					}}
				>
					<i
						class='fa-solid fa-bars '
						onClick={press}
						id='toggle_desktop'
					></i>
					<a
						class='btn btn-primary'
						data-bs-toggle='offcanvas'
						href='#offcanvasExample'
						role='button'
						aria-controls='offcanvasExample'
						id='toggle_mobile'
					>
						<i class='fa-solid fa-bars'></i>
					</a>
				</div>

				<div className='nav'>
					<div className='text-success me-2 fw-bold'>
						<span
							title='Copy ID'
							style={{ cursor: 'pointer' }}
							onClick={() => handleCopy(localStorage.getItem('sellerId'))}
						>
							<span
								className='me-2'
								style={{ color: 'black' }}
							>
								Click to Copy Wallet ID :
							</span>
							<AiOutlineCopy className='copyIconNavBar' />
							<span className='idMenuBar'>
								{localStorage.getItem('sellerId')}
							</span>
						</span>
					</div>
					<div class='dropdown p-2'>
						<i
							className='fa-solid fa-bell'
							type='button'
							id='dropdownMenuButton1'
							data-bs-toggle='dropdown'
							aria-expanded='false'
						></i>
						<span
							className='notification-badge'
							style={{
								position: 'absolute',
								top: '-10px',
								right: '-10px',
								backgroundColor: 'red',
								color: 'white',
								fontSize: '12px',
								width: '20px',
								height: '20px',
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: '0.5rem',
								marginRight: '0.45rem',
							}}
						>
							{filteredDemand?.length > 0 ? filteredDemand?.length : 0}
						</span>
						{/* </div> */}

						<ul
							class='dropdown-menu p-0'
							aria-labelledby='dropdownMenuButton1'
						>
							{filteredDemand.length > 0 ? (
								filteredDemand?.map((demand) => (
									<li>
										<a class='dropdown-item justify-content-between d-flex align-items-center'>
											<div>
												<img
													src={`${ApiUrl}/images/${demand?.img}`}
													alt=''
													style={{
														height: '55px',
														borderRadius: '0.5rem',
														width: '55px',
													}}
												/>
											</div>
											<div className='ps-4'>
												<p>
													{demand?.description}
													<br />
													<span
														style={{
															backgroundColor: '#66db6f4b',
															color: 'green',
															borderRadius: '40px',

															fontSize: '11px',
															fontWeight: '500',
														}}
													>
														{demand?.category}
													</span>
												</p>
											</div>
										</a>
									</li>
								))
							) : (
								<p className='pt-3 ms-2'>
									<b className='text-danger'>No Notification</b>
								</p>
							)}
						</ul>
					</div>

					<div class='dropdown p-2'>
						<i
							class='fa-brands fa-reddit'
							type='button'
							id='dropdownMenuButton1'
							data-bs-toggle='dropdown'
							aria-expanded='false'
							style={{ fontSize: '37px' }}
						></i>

						<ul
							class='dropdown-menu p-2'
							aria-labelledby='dropdownMenuButton1'
						>
							<li>
								{' '}
								<a
									href=''
									className='d-flex'
									style={{
										marginRight: '10px',
										color: 'gray',
										fontWeight: '500',
									}}
								>
									<i
										class='fa-solid fa-table-cells-large'
										style={{ color: 'gray' }}
									></i>
									Dashboard
								</a>
							</li>

							<li>
								{' '}
								<a
									href=''
									style={{ color: 'gray', fontWeight: '500' }}
								>
									<i
										class='fa-solid fa-display'
										style={{ color: 'gray' }}
									></i>
									Catlog
								</a>
							</li>

							<li>
								{' '}
								<a
									href=''
									style={{ color: 'gray', fontWeight: '500' }}
								>
									<i
										class='fa-solid fa-store'
										style={{ color: 'gray' }}
									></i>
									Logout
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default Nav;
