import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'antd';
import withAuth from '../../../hooks/withAuth';

function Packages() {
	const [checkValue, setCheckValue] = useState(false);
	const [packageData, setPackageData] = useState([]);
	const className = 'offcanvas offcanvas-end offcanvas-backdrop.show';
	const changeClassName =
		'offcanvas offcanvas-end d-none  offcanvas-backdrop.show2';

	const [packages, setPackages] = useState([]);

	const [packagesSingle, setPackagesSingle] = useState({});

	const [sellerid, setSellerID] = useState([]);

	const sendData = (values) => {};

	const updateData = (values) => {};

	const onDeleteStudent = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				axios.delete(`${ApiUrl}/packages/deleteByID?id=` + id).then((res) => {
					console.log(res.data);

					toast('e-stock deleted successfully!');
				});
			},
		});
	};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<ToastContainer />
							<div className='container-fluid py-4'>
								<h5 className='kk ps-3'>Seller Package Details</h5>

								{/* <div className="min-box my-4">
                  <div className="products-table">
                    <table
                      className="table border rounded align-middle"
                      style={{ borderColor: "#4f4f51" }}
                    >
                      <thead className="bg-light">
                        <tr>
                          <th>Title</th>
                          <th>Minimum Store</th>
                          <th>Store Price</th>
                          <th>Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                          <td>{packageData?.title}</td>
                          <td>{packageData?.minimumStore}</td>
                          <td>{packageData?.storePrice}</td>
                          <td>{packageData?.type}</td>

                          <td>
                            <span
                              className="text-ligh  "
                              style={{
                                color: "#278a1a",
                                fontSize: "10px",
                                backgroundColor: "#41ff2848",
                                borderRadius: 20,
                                padding: "5px 10px",
                              }}
                            >
                              Active
                            </span>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div> */}

								<div className='package_card'>
									<div className='card'>
										<div className='package_cardData'>
											<div className='fw-bold'>Package Title:</div>
											<div>{packageData?.title}</div>
										</div>

										<div className='package_cardData'>
											<div className='fw-bold'>Minimum Store:</div>
											<div>{packageData?.minimumStore}</div>
										</div>

										<div className='package_cardData'>
											<div className='fw-bold'>Per Store Price:</div>
											<div>${packageData?.storePrice}</div>
										</div>

										<div className='package_cardData'>
											<div className='fw-bold'>Package Type:</div>
											<div>{packageData?.type}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Packages);
