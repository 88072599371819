// import React, { useState } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate } from 'react-router-dom';
// import { Countries } from 'countries-states-cities-service';
// import { sellerLogin } from '../Pages/ApiEndpoint';

// import './Login.css'
// function Login() {
// 	const navigation = useNavigate('');
// 	const countries = Countries?.getCountries();
// 	const [username, setUsername] = useState('');
// 	const [password, setPassword] = useState('');
// 	const handleSubmit = async (values) => {
// 		if (values?.email?.value?.length === 0) {
// 			toast.error('Please Enter a email');
// 		} else if (values?.password?.value?.length === 0) {
// 			toast.error('Please Enter a password');
// 		} else {
// 			const params = {
// 				yourEmail: username,
// 				createPassword: password,
// 			};

// 			// print form data

// 			console.log('params', params);

// 			const data = await fetch(`${sellerLogin}`, {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 				},
// 				body: JSON.stringify(params),
// 			});
// 			const response = await data.json();

// 			// store the token in local storage

// 			if (data.ok) {
// 				console.log('response', response);
// 				localStorage.setItem('token', response?.token);
// 				localStorage.setItem('seller-user', JSON.stringify(response));
// 				localStorage.setItem('sellerId', response?.sellerID);
// 				localStorage.setItem('qcid', response?.qualityControllerID);
// 				toast.success('Login Successful');
// 				setPassword('');
// 				setUsername('');
// 				navigation('/dashboard');
// 			} else {
// 				console.log('responseaksjdhakjsdhladkjlakdj');

// 				toast.error('Invalid Credentials');
// 			}
// 		}
// 	};
// 	return (
// 		<>
// 			<div className='containers'>
// 				<ToastContainer />
// 				<div className='rows'>
// 					<div className='col-md-6'>
// 						<div className='login3'>
// 						<img
//                 src='login2.avif'
//                 alt="Login Illustration"
//               />
// 						</div>
// 					</div>
// 					<div className='col-md-6'>
// 						<section className='login2'  style={{boxShadow:"0 !important", marginLeft:"-20%"}}>
						
// 								{/* <h5 style={{ marginBottom: '20px' }}>Login</h5> */}
// 								<form
// 									onSubmit={(e) => {
// 										e.preventDefault();
// 										handleSubmit(e.target);
// 									}}
// 									className='login'
// 								>
// 									 <h1>L</h1>
//                 <h5>Welcome back,</h5>
//                 <p >A few steps to communicate with us</p>
                
// 				<div className="form">

				
// 									 <label style={{ fontSize: '12px' }}>Email</label>
// 									<br />
// 									<input
// 										type='email'
// 										name='yourEmail'
// 										onChange={(e) => setUsername(e.target.value)}
// 										class='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '10px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='Enter Your Email'
// 									/> 
									  

// 									<label style={{ fontSize: '12px' }}>Password</label>
// 									<br />
// 									<input
// 										type='password'

										
// 										name='createPassword'
// 										onChange={(e) => setPassword(e.target.value)}
// 										class='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '30px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='Enter your password'
// 									/>
// 				<button
// 										type='submit'
// 										class='btn'
// 										style={{
// 											fontSize: '13px',
// 											width: '95%',
// 											backgroundColor: '#ffd700',
// 											borderColor: '#ffd700',
// 											marginTop: '-20px',
// 										}}
// 									>
// 										Login
// 									</button>

// 									</div>
// 								</form>
							
// 						</section>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// }

// export default Login;




import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Countries } from 'countries-states-cities-service';
import { sellerLogin } from '../Pages/ApiEndpoint';
import StyledInput from '../../Input/Style'; // Import StyledInput component
import { MdOutlineCompare } from "react-icons/md";
import { TbArrowAutofitRight } from "react-icons/tb";

import './Login.css';

function Login() {
    const navigation = useNavigate('');
    const countries = Countries?.getCountries();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username) {
            toast.error('Please enter an email');
        } else if (!password) {
            toast.error('Please enter a password');
        } else {
            const params = {
                yourEmail: username,
                createPassword: password,
            };

            try {
                const response = await fetch(`${sellerLogin}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });

                if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem('token', data?.token);
                    localStorage.setItem('seller-user', JSON.stringify(data));
                    localStorage.setItem('sellerId', data?.sellerID);
                    localStorage.setItem('qcid', data?.qualityControllerID);
                    toast.success('Login Successful');
                    setUsername('');
                    setPassword('');
                    navigation('/dashboard');
                } else {
                    toast.error('Invalid Credentials');
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        }
    };

    return (
        <>
            <div className='containers'>
                <ToastContainer />
                <div className='rows'>
                    <div className='col-md-6'>
                        <div className='login3'>
                            <img
                                src='login2.avif'
                                alt="Login Illustration"
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <section className='login2' style={{ boxShadow: "0 !important", marginLeft: "-20%" }}>
                            <form onSubmit={handleSubmit} className='login'>
                                <h1>L</h1>
                                <h5>Welcome back,</h5>
                                <p>A few steps to communicate with us my seller</p>
                                <div className="form">
                                    <StyledInput
                                        type="email"
                                        placeholderText="E-Mail"
                                        Icon={TbArrowAutofitRight}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <StyledInput
                                        type="password"
                                        placeholderText="Password"
                                        Icon={MdOutlineCompare}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type='submit'
                                        className='btn'
                                        style={{
                                            fontSize: '13px',
                                            width: '95%',
                                            backgroundColor: '#ffd700',
                                            borderColor: '#ffd700',
                                            marginTop: '-20px',
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
