import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import { getCategory, getRecCategory } from '../ApiEndpoint';
import withAuth from '../../../hooks/withAuth';

function ProductCategory() {
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		const fetchSellerData = async () => {
			const result = await fetch(getCategory);
			const catdata = await result.json();
			console.log('Product selling Category', catdata);
			return catdata;
		};

		const fetchRecycleData = async () => {
			const result = await fetch(getRecCategory);
			const catdata = await result.json();
			console.log('Product recycle Category', catdata);
			return catdata;
		};

		const fetchData = async () => {
			const sellerData = await fetchSellerData();
			const recycleData = await fetchRecycleData();
			const combinedData = [...sellerData, ...recycleData];
			setCategories(combinedData);
		};

		fetchData();
	}, []);

	const countActiveCategories = () => {
		const activeCategories = categories.filter(
			(category) => category.status === 'active'
		);
		return activeCategories.length;
	};

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							// {
							//   extend: "csv",
							//   className: "btn btn-success bg-success",
							// },
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	}, []);

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage Product Categories</h5>
								<div className='row pb-4'>
									<div className='col-md-12'>
										<div className='counterbox'>
											Total Categories
											<span className='badge badge-primary'>
												{categories?.length}
											</span>
										</div>
										<div className='counterbox'>
											Active Categories
											<span className='badge badge-primary'>
												{countActiveCategories()}
											</span>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table p-3 shadow'>
										<table
											id='table'
											className='table border rounded align-middle'
											style={{ borderColor: '#4f4f51' }}
										>
											<thead className='bg-light'>
												<tr>
													<th>
														<input
															className='form-check-input'
															type='checkbox'
															value=''
															id='flexCheckDefault'
														/>
													</th>
													<th>#Id</th>

													<th>Title</th>
													<th>Admin Commission</th>
													<th>Category Title</th>
													<th>Status</th>
												</tr>
											</thead>

											<tbody>
												{categories?.map((item, index) => {
													return (
														<>
															<tr>
																<td>
																	{' '}
																	<input
																		className='form-check-input'
																		type='checkbox'
																		value=''
																		id='flexCheckDefault'
																	/>
																</td>
																<td>MP{item?._id}</td>

																<td>{item?.categoryTitle}</td>
																<td>{item?.adminMinimumCommision}</td>
																<td>{item?.category}</td>
																<td>
																	<span
																		className='text-ligh  '
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																		}}
																	>
																		{item?.status}
																	</span>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(ProductCategory);
