export const endpoint = 'http://localhost:3000';
// export const endpoint = 'http://165.22.215.169:80';

// seller end points

//ogin
export const sellerLogin = `${endpoint}/sellers/login`;

// category selling api

export const getCategory = `${endpoint}/productcategory/selling`;

// category recycling api

export const getRecCategory = `${endpoint}/productcategory/recycling`;

// sub category api

export const getsubCategory = `${endpoint}/product/subcategoriesselling`;

// sub category recycling api

export const getRecsubCategory = `${endpoint}/product/subcategoriesrecycling`;

// product selling api

export const addProduct = `${endpoint}/product/sellingss`;
export const editProduct = `${endpoint}/product/sellingss`;
export const delProduct = `${endpoint}/product/sellingss`;
export const getProduct = `${endpoint}/product/sellingss`;

//product  recycling api

export const addRecProduct = `${endpoint}/product/recycling`;
export const editRecProduct = `${endpoint}/product/recycling`;
export const delRecProduct = `${endpoint}/product/recycling`;
export const getRecProduct = `${endpoint}/product/recycling`;

// ware hoiuse end point

export const getWarehouse = `${endpoint}/qualityControllers/getwarehouse`;

// get stores

export const getStores = `${endpoint}/qualityControllers/getstore`;
