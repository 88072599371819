import React, { useEffect, useState } from 'react';
import BasicDetails from './BasicDetails';
import StockDetails from './StockDetails';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { Countries } from 'countries-states-cities-service';
import withAuth from '../../../hooks/withAuth';

const ProductInfo = () => {
	const { id } = useParams();
	const [singleProduct, setSingleProduct] = useState();
	const [catName, setCatName] = useState('');
	const [countryName, setCountryName] = useState('');
	const [categories, setCategories] = useState([]);
	const countries = Countries?.getCountries();
	useEffect(() => {
		axios.get(`${ApiUrl}/productSelling/getById?id=${id}`).then((res) => {
			if (res?.data) {
				setSingleProduct(res?.data);
			} else {
				axios.get(`${ApiUrl}/recycling/getById?id=${id}`).then((res) => {
					setSingleProduct(res?.data);
				});
			}
		});
		axios.get(`${ApiUrl}/category/getAll`).then((res) => {
			setCategories(res.data);
		});
	}, []);
	useEffect(() => {
		let cat;
		cat = categories?.filter((cat) => cat?._id === singleProduct?.categoryID);
		setCatName(cat?.[0]?.title);
		let con;
		con = countries.filter(
			(country) => country?.iso2 === singleProduct?.country
		);
		setCountryName(con?.[0]?.name);
	}, [singleProduct, categories, countries]);

	return (
		<div className='productInfo'>
			<ul
				className='nav nav-pills mt-3 mb-5 d-flex justify-content-start'
				id='pills-tab'
				role='tablist'
			>
				<li
					className='nav-item me-2'
					role='presentation'
				>
					<button
						className='nav-link active'
						id='pills-home-tab'
						data-bs-toggle='pill'
						data-bs-target='#basicDetails'
						type='button'
						role='tab'
						aria-controls='pills-home'
						aria-selected='true'
					>
						Basic Details
					</button>
				</li>
				<li
					className='nav-item me-2'
					role='presentation'
				>
					<button
						className='nav-link'
						id='pills-profile-tab'
						data-bs-toggle='pill'
						data-bs-target='#orderingDetails'
						type='button'
						role='tab'
						aria-controls='pills-profile'
						aria-selected='false'
					>
						Ordering Details
					</button>
				</li>
				<li
					className='nav-item me-2'
					role='presentation'
				>
					<button
						className='nav-link'
						id='pills-profile-tab2'
						data-bs-toggle='pill'
						data-bs-target='#stockDetails'
						type='button'
						role='tab'
						aria-controls='pills-profile'
						aria-selected='false'
					>
						Stock Details
					</button>
				</li>
			</ul>

			<div
				className='tab-content'
				id='pills-tabContent'
			>
				<div
					className='tab-pane fade show active'
					id='basicDetails'
					role='tabpanel'
					aria-labelledby='pills-home-tab'
					tabIndex={0}
				>
					<BasicDetails
						singleProduct={singleProduct}
						catName={catName}
						countryName={countryName}
					/>
				</div>

				<div
					className='tab-pane fade'
					id='orderingDetails'
					role='tabpanel'
					aria-labelledby='pills-profile-tab'
					tabIndex={0}
				>
					Coming Soon
				</div>

				<div
					className='tab-pane fade'
					id='stockDetails'
					role='tabpanel'
					aria-labelledby='pills-profile-tab2'
					tabIndex={0}
				>
					<StockDetails />
				</div>
			</div>
		</div>
	);
};

export default withAuth(ProductInfo);
