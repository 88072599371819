import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import AllCanvas from './AllCanvas';

function ProductList() {
	const [newItems, setNewItems] = useState();
	const [allData, setAllData] = useState([]);
	const [show, setShow] = useState(false);
	const [orderId, setOrderId] = useState('');
	const [updateStatus, setUpdateStatus] = useState('');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const fn_updateStatus = () => {};

	const fnFunction = (item) => {
		setNewItems(item);
	};
	return (
		<>
			<div>
				<ToastContainer />
				<div
					class='offcanvas offcanvas-end'
					tabindex='-1'
					id='offcanvasRight'
					aria-labelledby='offcanvasRightLabel'
				>
					<div class='offcanvas-body'>
						<AllCanvas orderDetails={newItems} />
					</div>
				</div>

				<Modal
					show={show}
					onHide={handleClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<select
							className='form-select'
							onChange={(e) => setUpdateStatus(e?.target?.value)}
						>
							<option
								selected
								value={''}
							>
								---Select Status---
							</option>
							<option value={'pending'}>Pending</option>
							<option value={'confirmed'}>Confirmed</option>
							<option value={'out for delivery'}>Out for Delivery</option>
							<option value={'cancelled'}>Cancelled</option>
							<option value={'returned'}>Returned</option>
							<option value={'failed to deliver'}>Failed to Deliver</option>
						</select>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={handleClose}
						>
							Close
						</Button>
						<Button
							variant='success'
							onClick={fn_updateStatus}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>

				<header
					class='tab-pane fade show active'
					id='pills-PendingSelling'
					role='tabpanel'
					aria-labelledby='pills-PendingSelling-tab'
				>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage All Orders</h5>
								{/* //////////////////////////////////////////////////////////////// */}
								{/* -------------------- RACKS MODAL start--------------------------- */}
								{/* //////////////////////////////////////////////////////////////// */}

								<div className='min-box my-4'>
									<div className='Racks-table'>
										<table
											className='Ordertable_data'
											style={{ borderColor: '#4f4f51' }}
										>
											<thead className='bg-light'>
												<tr className='Ordertable_dataRow Ordertable_dataRowHead'>
													<th>Sr #</th>
													<th>Order Date</th>
													<th>Order</th>
													<th>Customer Info</th>
													<th>Store</th>
													<th>Order Status</th>
													<th>Update Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{allData &&
													allData?.map((item, index) => {
														return (
															<tr>
																<td>{index + 1}</td>
																<td>{item?.createdAt}</td>
																<td>
																	{item?.ProductsOrder?.map((i) => {
																		return (
																			<>
																				{i?.name}
																				<br />
																			</>
																		);
																	})}
																</td>
																<td>{item?.userName}</td>
																<td>Store</td>
																<td>
																	<span
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																			textTransform: 'capitalize',
																		}}
																	>
																		{item?.state}
																	</span>
																</td>
																<td>
																	<MdOutlineSystemUpdateAlt
																		onClick={() => {
																			handleShow();
																			setOrderId(item?._id);
																		}}
																		style={{ cursor: 'pointer' }}
																	/>
																</td>
																<td>
																	<button
																		className='btn btn-success'
																		style={{ fontSize: 18 }}
																		data-bs-toggle='offcanvas'
																		data-bs-target='#offcanvasRight'
																		aria-controls='offcanvasRight'
																		onClick={() => fnFunction(item)}
																	>
																		<BsFillEyeFill />
																	</button>
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
										<div className='data-pagination px-4 d-flex'>
											<p>SHOWING 41-60 OF 308</p>

											<nav aria-label='Page navigation example'>
												<ul className='pagination'>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Previous'
														>
															<span aria-hidden='true'>«</span>
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															1
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															2
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															3
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															4
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															5
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															6
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Next'
														>
															<span aria-hidden='true'>»</span>
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</div>
								</div>

								{/* //////////////////////////////////////////////////////////////// */}
								{/* -------------------- RACKS MODAL End--------------------------- */}
								{/* //////////////////////////////////////////////////////////////// */}
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default ProductList;
