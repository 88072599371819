import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import { useParams } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';

const ViewRackProduct = () => {
	const { id } = useParams();
	const [rack, setRack] = useState();
	const [storeId, setStoreId] = useState('');
	const [storeAllRacks, setStoreAllRacks] = useState();
	const [singleRack, setSingleRack] = useState();
	const [categories, setCategories] = useState();

	return (
		<>
			<div>
				<div className='container-fluid py-5'>
					<div className='row pt-5'>
						<div className='col-md-10 mx-auto'>
							{categories ? (
								<article className='postcard dark blue'>
									<a className='postcard__img_link'>
										<img
											className='postcard__img'
											src={`${ApiUrl}/images/${categories?.image}`}
											alt='Image Title'
										/>
									</a>
									<div className='postcard__text'>
										<h2 className=''>{categories?.name}</h2>
										<div className='postcard__bar' />
										<div className=''>
											Available Quantity :{' '}
											<b className='text-info h5'>
												{categories?.quantity} {categories?.unit}
											</b>
										</div>
										<div className=''>
											Product Price :{' '}
											<b className='text-info h5'>
												{categories?.productPrice} Rs
											</b>
										</div>
										<div className=''>
											Admin Comission :{' '}
											<b className='text-info h5'>
												{categories?.companyComission} %
											</b>
										</div>
										<div className=''>
											Ambassador Comission :{' '}
											<b className='text-info h5'>
												{categories?.ambassadorComission} %
											</b>
										</div>
										<div className=''>
											Referral Comission :{' '}
											<b className='text-info h5'>
												{categories?.referralComission} %
											</b>
										</div>
										<div className=''>
											Product Retail Price :{' '}
											<b className='text-info h5'>
												{categories?.retailPrice} Rs
											</b>
										</div>
										<hr />
										<div className=''>
											Product Code :{' '}
											<b className='text-info h5'>{categories?.code}</b>
										</div>
										<div className=''>
											Product Quality :{' '}
											<b className='text-info h5'>{categories?.quality}</b>
										</div>
										<div className=''>
											<b className='text-info h5'>
												Made in {categories?.country}
											</b>
										</div>
									</div>
									<hr />
								</article>
							) : (
								<h4 className='text-danger text-center mb-4'>
									No Product Available in this Rack
								</h4>
							)}
							<div className='viewRackDetails'>
								<h2 className='text-center text-underline'>Rack Details</h2>
								<p className='rackText'>
									Rack Name : <b>{singleRack?.[0].name}</b>
								</p>
								<p className='rackText'>
									State : <b>{singleRack?.[0].state}</b>
								</p>
								<p className='rackText'>
									Rack Type : <b>{singleRack?.[0].type}</b>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withAuth(ViewRackProduct);
