import React from "react";

const ErrorPage = () => {
    return(<>
    <div className="m-5 text-center">
        <h1>Oops!</h1>
        <h1><i class="fa-solid fa-circle-exclamation"></i> 404 Page not Found</h1>
    </div>
    </>)
}

export default ErrorPage