import axios from "axios"
import { useEffect, useState } from "react"
import ApiUrl from "../BaseUrl"
import { useParams } from "react-router-dom"

const StockDetails = () => {
    const { id } = useParams()
    const [mainStockName, setMainStockName] = useState()
    const [mainProRacks, setMainProRacks] = useState([])
    const [allStoreRacks, setAllStoreRacks] = useState([])
    const [stores, setStores] = useState([])
    useEffect(() => {
        axios.get(`${ApiUrl}/mainStockRack/getBYSellerID?id=${localStorage.getItem("sellerId")}`)
            .then((res) => {
                setMainProRacks(res?.data?.filter((proRack) => proRack?.product === id))
            })
        axios.get(`${ApiUrl}/mainStock/getBYSellerID?id=${localStorage.getItem("sellerId")}`)
            .then((res) => {
                setMainStockName(res?.data[0]?.warehouseName)
            })
        // axios.get(`${ApiUrl}/store/getBySellerID?id=${localStorage.getItem("sellerId")}`)
        //     .then((res) => {
        //         console.log("0000000000000", res?.data)

        //         res?.data.map((i) => {
        //             i.product.map(j => {
        //                 axios.get(`${ApiUrl}/e-rack/getByStoreId?id=${storeID}`).then((res) => {
        //                     console.log(res?.data)
        //                     // allStoreRacks.push(res?.data)
        //                 })
        //             })

        //         })
        //     })
    }, [])
    console.log(allStoreRacks)
    return (
        <div className="productDetailStockMain">
            <div className="productDetailStock">
                <h3>Main Stock Details:</h3>
                <h4 className="text-success mb-4">{mainStockName}</h4>
                <table className="mainStockProductTable">
                    <tr>
                        <td>Available Racks of this Product</td>
                        <td>{mainProRacks?.length > 0 ? mainProRacks?.length : 0}</td>
                    </tr>
                    <tr>
                        <td>Total Product in Main Stock</td>
                        <td>{mainProRacks?.reduce((acc, currValue) => {
                            return acc + parseInt(currValue?.productQty)
                        }, 0)}</td>
                    </tr>
                </table>
            </div>
            <br />
            {allStoreRacks && allStoreRacks.map((item, index) => (
                <div className="productDetailStock">
                    <h3>Store Details:</h3>
                    <h4 className="text-success mb-4">{index + 1}</h4>
                    <table className="mainStockProductTable">
                        <tr>
                            <td>Available Racks of this Product</td>
                            <td>{item?.length > 0 ? item?.length : 0}</td>
                        </tr>
                        <tr>
                            <td>Total Product in Main Stock</td>
                            <td>13</td>
                        </tr>
                    </table>
                </div>
            ))}
        </div>
    )
}

export default StockDetails