import { Radio } from "antd";
import "./productFilterStyle.css";
import { useState } from "react";

const ProductFilter = ({
  allMainStockRack,
  rackId,
  setRacksId,
  racksDetail,
  setRacksDetail,
}) => {
  const [filteredRacks, setFilteredRacks] = useState(null);

  let MainStockRacksArr = allMainStockRack.filter((item, i) => {
    if (item.hasDimensions === true) {
      item.name = "Ei" + (i + 1);
      return item;
    }
  });

  function fn_filterByUnit(value) {
    const tempRacks = MainStockRacksArr;
    if (value === "all") {
      setFilteredRacks(tempRacks);
    } else if (value === "feet") {
      const filteredRacks = tempRacks.filter((item) => item.unit === "ft");
      setFilteredRacks(filteredRacks);
    } else if (value === "inch") {
      const filteredRacks = tempRacks.filter((item) => item.unit === "inch");
      setFilteredRacks(filteredRacks);
    } else if (value === "meter") {
      const filteredRacks = tempRacks.filter((item) => item.unit === "meter");
      setFilteredRacks(filteredRacks);
    }
  }

  function fn_handleRackIds(event, rack) {
    if (event.target.checked) {
      setRacksId((prev) => [
        ...prev,
        { rackId: rack._id, name: rack.name, qty: 0 },
      ]);
    } else {
      const filteredId = rackId.filter(({ rackId }) => rackId !== rack._id);
      setRacksId(filteredId);
    }
  }
  function fn_handleRacksData(e, rack) {
    const qty = e.target.value;
    const tempRacks = rackId.slice();
    const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
    tempRacks[itemIndex].qty = qty;
    setRacksDetail(tempRacks);
  }

  return (
    <>
      <div className="col-12">
        <Radio.Group
          defaultValue="all"
          buttonStyle="solid"
          onChange={(e) => fn_filterByUnit(e.target.value)}
        >
          <Radio.Button name="all" value="all">
            All
          </Radio.Button>
          <Radio.Button name="feet" value="feet">
            Feet
          </Radio.Button>
          <Radio.Button name="inch" value="inch">
            Inch
          </Radio.Button>
          <Radio.Button name="meter" value="meter">
            Meter
          </Radio.Button>
        </Radio.Group>
      </div>

      {(filteredRacks ?? MainStockRacksArr)?.map((rack, index) => {
        if(rack?.type==="owned"){
          return(<div className=" mt-2 mb-2" key={index}>
          <div className="rack_detailBox">
            <input
              type="checkbox"
              disabled={rack?.product || rack.forSell}
              onChange={(e) => fn_handleRackIds(e, rack)}
            />

            <div>
              <span style={{ fontWeight: "bold" }}>{rack?.name}</span>
              <span>
                {" "}
                {"(f:" +
                  rack?.front +
                  ", " +
                  "b:" +
                  rack?.back +
                  ", " +
                  "h:" +
                  rack?.height +
                  ")"}{" "}
               [ <span style={{ color: "green" }}>{rack?.rackType}</span>]
              </span>
            </div>
          </div>
        </div>)
        }
       
    
})}
      {rackId?.map((rackData) => (
        <div className="col-6 mt-2 mb-3">
          <input
            type="number"
            placeholder={"Enter capacity for " + rackData?.name}
            className="racks_input"
            onChange={(e) => fn_handleRacksData(e, rackData)}
          />
        </div>
      ))}
    </>
  );
};

export default ProductFilter;
