import React from 'react';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { toast } from 'react-toastify';
function ScrollableSection({ left }) {
	const logoutHandler = async () => {
		try {
			localStorage.removeItem('seller-user');
			toast.success('Logout successfully');
			window.location.reload();
		} catch (error) {
			console.error(error);
			toast.error(error.message);
		}
	};
	return (
		<>
			<div
				id='sidebar_mobile'
				className='sidebar bg-white'
				style={{ left: left }}
			>
				<div className='p-5'>
					<img
						src={require('../assets/images/e market-01.png')}
						width='140px'
						height=''
						alt=''
					/>
				</div>
				<ul className='pb-5'>
					<li className='nav-item'>
						<Link
							to='/dashboard'
							className='fw-semi-bold nav-link active pt-0'
							aria-current='page'
						>
							{' '}
							<i class='fa-solid fa-table-cells-large'></i>Dashboard
						</Link>
					</li>

					<div
						class='accordion accordion-flush'
						id='accordionFlushExample2'
					>
						<div class='accordion-item '>
							<h2
								class='accordion-header'
								id='flush-headingTwo'
							>
								<button
									class='accordion-button collapsed p-0  pe-3 onfocus-none'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#flush-collapseTwo'
									aria-expanded='false'
									aria-controls='flush-collapseTwo'
								>
									<li className='nav-item'>
										<Link
											to='/product'
											className='fw-semi-bold nav-link active'
											aria-current='page'
										>
											<i class='fa-brands fa-product-hunt'></i>Products
										</Link>
									</li>
								</button>
							</h2>
							<div
								id='flush-collapseTwo'
								class='accordion-collapse collapse'
								aria-labelledby='flush-headingTwo'
								data-bs-parent='#accordionFlushExample2'
							>
								<div class='accordion-body'>
									<li className='nav-item'>
										<Link
											to='/product'
											className='fw-semi-bold nav-link active pt-0'
											aria-current='page'
										>
											Products
										</Link>
									</li>
									<li className='nav-item'>
										<Link
											to='/product_category'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Categories
										</Link>
									</li>
								</div>
							</div>
						</div>
					</div>

					<li className='nav-item'>
						<Link
							to='/stores'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							{' '}
							<i class='fa-solid fa-gear'></i>Stores
						</Link>
					</li>

					<li className='nav-item'>
						<Link
							to='/packages'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							<i class='fa-solid fa-box'></i>Package
						</Link>
					</li>

					<li className='nav-item'>
						<Link
							to='/yourorder'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							<i class='fa-solid fa-box'></i>Your Order
						</Link>
					</li>

					<div
						class='accordion accordion-flush'
						id='accordionFlushExample55'
					>
						<div class='accordion-item '>
							<h2
								class='accordion-header'
								id='flush-headingThree'
							>
								<button
									class='accordion-button collapsed p-0  pe-3 onfocus-none'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#flush-collapseThree'
									aria-expanded='false'
									aria-controls='flush-collapseThree'
								>
									<li className='nav-item'>
										<Link
											to='/orders'
											className='fw-semi-bold nav-link active'
											aria-current='page'
										>
											<i class='fa-solid fa-car'></i>Orders
										</Link>
									</li>
								</button>
							</h2>
							<div
								id='flush-collapseThree'
								class='accordion-collapse collapse'
								aria-labelledby='flush-headingThree'
								data-bs-parent='#accordionFlushExample55'
							>
								<div class='accordion-body'>
									<li className='nav-item'>
										<Link
											to='/allOrders'
											className='fw-semi-bold nav-link active pt-0'
											aria-current='page'
										>
											All
										</Link>
									</li>
									<li className='nav-item'>
										<Link
											to='/pending'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Pending
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/approved'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Confirmed
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Packaging
										</Link>
									</li>
									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Out for delivered
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Delivered
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Returned
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											Failed To Deliver
										</Link>
									</li>

									<li className='nav-item'>
										<Link
											to='/cancel'
											className='fw-semi-bold nav-link active pt-1'
											aria-current='page'
										>
											canceled
										</Link>
									</li>
								</div>
							</div>
						</div>
					</div>
					<li className='nav-item'>
						<Link
							to='/wallet'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							<i class='fa-solid fa-wallet'></i>Wallet
						</Link>
					</li>

					<li className='nav-item'>
						<Link
							to='/settings'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							{' '}
							<i class='fa-solid fa-gear'></i>Profile Info
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							to='/change_password'
							className='fw-semi-bold nav-link active'
							aria-current='page'
						>
							{' '}
							<i class='fa-solid fa-gear'></i>Change Password
						</Link>
					</li>
				</ul>

				<div style={{ position: 'fixed', bottom: '30px', left: '20px' }}>
					<button
						class='add-btn px-5 btn-md py-2'
						type='button'
						onClick={logoutHandler}
					>
						{' '}
						<i>
							<FiLogOut color='black' />
						</i>{' '}
						<span
							class=''
							style={{ color: 'black' }}
						>
							Log Out
						</span>
					</button>
				</div>
			</div>

			<div
				class='offcanvas offcanvas-start'
				tabindex='-1'
				id='offcanvasExample'
				aria-labelledby='offcanvasExampleLabel'
			>
				<div class='offcanvas-header'>
					<h5
						class='offcanvas-title'
						id='offcanvasExampleLabel'
					>
						Offcanvas
					</h5>
					<button
						type='button'
						class='btn-close text-reset'
						data-bs-dismiss='offcanvas'
						aria-label='Close'
					></button>
				</div>
				<div class='offcanvas-body'>
					<div className='sidebar2 bg-white'>
						<ul className='pb-5'>
							<li
								className='nav-item'
								data-bs-dismiss='offcanvas'
							>
								<Link
									to='/dashboard'
									className='fw-semi-bold nav-link active pt-0'
									aria-current='page'
								>
									{' '}
									<i class='fa-solid fa-table-cells-large'></i>Dashboard
								</Link>
							</li>

							<div
								class='accordion accordion-flush'
								id='accordionFlushExample2'
							>
								<div class='accordion-item '>
									<h2
										class='accordion-header'
										id='flush-headingTwo'
									>
										<button
											class='accordion-button collapsed p-0  pe-3 onfocus-none'
											type='button'
											data-bs-toggle='collapse'
											data-bs-target='#flush-collapseTwo'
											aria-expanded='false'
											aria-controls='flush-collapseTwo'
										>
											<li
												className='nav-item'
												data-bs-dismiss='offcanvas'
											>
												<Link
													to='/product'
													className='fw-semi-bold nav-link active'
													aria-current='page'
												>
													<i class='fa-brands fa-product-hunt'></i>Products
												</Link>
											</li>
										</button>
									</h2>
									<div
										id='flush-collapseTwo'
										class='accordion-collapse collapse'
										aria-labelledby='flush-headingTwo'
										data-bs-parent='#accordionFlushExample2'
									>
										<div class='accordion-body'>
											<li
												className='nav-item'
												data-bs-dismiss='offcanvas'
											>
												<Link
													to='/product'
													className='fw-semi-bold nav-link active pt-0'
													aria-current='page'
												>
													Products
												</Link>
											</li>
											<li
												className='nav-item'
												data-bs-dismiss='offcanvas'
											>
												<Link
													to='/product_category'
													className='fw-semi-bold nav-link active pt-1'
													aria-current='page'
												>
													Categories
												</Link>
											</li>
										</div>
									</div>
								</div>
							</div>

							<li
								className='nav-item'
								data-bs-dismiss='offcanvas'
							>
								<Link
									to='/stores'
									className='fw-semi-bold nav-link active'
									aria-current='page'
								>
									{' '}
									<i class='fa-solid fa-gear'></i>Stores
								</Link>
							</li>

							<li
								className='nav-item'
								data-bs-dismiss='offcanvas'
							>
								<Link
									to='/settings'
									className='fw-semi-bold nav-link active'
									aria-current='page'
								>
									{' '}
									<i class='fa-solid fa-gear'></i>Profile Info
								</Link>
							</li>
							<li
								className='nav-item'
								data-bs-dismiss='offcanvas'
							>
								<Link
									to='/change_password'
									className='fw-semi-bold nav-link active'
									aria-current='page'
								>
									{' '}
									<i class='fa-solid fa-gear'></i>Change Password
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default ScrollableSection;
