import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import ApiUrl from "../BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {Modal} from "antd";

function Order_Details() {

  const [checkValue, setCheckValue] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const className = "offcanvas offcanvas-end offcanvas-backdrop.show";
  const changeClassName = "offcanvas offcanvas-end d-none  offcanvas-backdrop.show2"

  useEffect(()=>{
    
    axios.get(`${ApiUrl}/packages/getById?id=`+localStorage.getItem("packageID") ).then((res) => {
      setPackageData(res?.data);
    });

  },[])

  const [packages, setPackages] = useState([]);

  const [packagesSingle, setPackagesSingle] = useState({});

  const [sellerid,setSellerID] = useState([]);

  useEffect(() => {
    axios.get(`${ApiUrl}/packages/getAll`).then((res) => {
      console.log(res.data);
      setPackages(res.data);
      console.log(packages);
    });
  }, []);

  const sendData = (values) => {

    if (values?.title?.value?.length === 0) {
      toast("please Enter your title");
    } else if (values?.minimumStore?.value?.length === 0) {
      toast("please Enter your Minimum Store");
    } else if (values?.packageName?.value?.length === 0) {
      toast("please Enter your Package Name");
    } else if (values?.type?.value?.length === 0) {
      toast("please Enter your Type");
    } else if (values?.status?.value?.length === 0) {
      toast("please Enter your status");
    } else {
      const params = {
        title: values.title.value,
        minimumStore:values.minimumStore.value,
        packageName: values.packageName.value,
        status: values.status.value,
        type: values.type.value,
      };
      axios
        .post(`${ApiUrl}/packages/create`, params)

        .then((res) => {
          console.log(res.data);

          if (res.data.status === "success") {

            
            document
            .querySelector(".offcanvas-backdrop")
            .classList.remove("show");
            setCheckValue(true)
            setTimeout(()=>{
              setCheckValue(false)
            },1500)

            
            toast("Package created successfully!");
 


          }
        });
    }
  };


  const updateData = (values) => {
    
      const params = {
        'id':sellerid,
        'title': values.title.value,
        'minimumStore': values.minimumStore.value,
        'packageName': values.packageName.value,
        'status': values.status.value,
        'type': values.type.value,
      };
      axios
        .post(`${ApiUrl}/packages/updateByID`, params)

        .then((res) => {
          console.log(res.data);

          if (res.data.status === "success") {

            document
            .querySelector(".offcanvas-backdrop")
            .classList.remove("show");

            setCheckValue(true)
            setTimeout(()=>{
              setCheckValue(false)
            },1500)
            
          }
        });
    
  };

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      onOk: () => {
        axios.delete(`${ApiUrl}/packages/deleteByID?id=` + id).then((res) => {
          console.log(res.data);

          toast("e-stock deleted successfully!");
        });
      },
    });
  };


    


  return (
    <>
      <div>
        <header>
          <div className="bg-theme">
            <div className="main-wrapper">
              <ToastContainer/>
              <div className="container-fluid py-4">
             
                <div className="package_card">
                    <div className="card">
                      <div className="package_cardData">
                        <div className="fw-bold">Package Title:</div>
                        <div>{packageData?.title}</div>
                      </div>

                      <div className="package_cardData">
                        <div className="fw-bold">Minimum Store:</div>
                        <div>{packageData?.minimumStore}</div>
                      </div>

                      <div className="package_cardData">
                        <div className="fw-bold">Per Store Price:</div>
                        <div>{packageData?.storePrice}</div>
                      </div>

                      <div className="package_cardData">
                        <div className="fw-bold">Package Type:</div>
                        <div>{packageData?.type}</div>
                      </div>

                    </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default Order_Details;
