import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { BsFillEyeFill } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import withAuth from '../../../hooks/withAuth';

function Stores() {
	const navigate = useNavigate();
	const id = localStorage.getItem('sellerId');
	const [sellerid, setSellerID] = useState(null);
	const [categories, setCategories] = useState([]);
	const [stores, setStores] = useState({});
	const [mainStock, setMainStock] = useState([]);
	const [allCategory, setAllCategory] = useState([]);
	const [offcanvas, setOffcanvas] = useState(null);
	const [allProduct, setAllProduct] = useState([]);
	const [mainStockProduct, setMainStockProduct] = useState(null);
	const [buyRacks, setBuyRacks] = useState(null);
	const [rentRackIds, setRentRackIds] = useState('');
	const uniqueObjectsById = {};
	const [racksDetail, setRacksDetail] = useState([]);

	const [location, setLocation] = useState(null);
	const [weather, setWeather] = useState(null);

	function success(position) {}

	const sendData = (values) => {};

	const updateData = (values) => {};

	const onDeleteStudent = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				axios.delete(`${ApiUrl}/store/deleteByID?id=` + id).then((res) => {
					console.log(res.data);
					toast.success('Store deleted successfully!');
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				});
			},
		});
	};

	const stockData = (values) => {};

	const [statequantity, setQuantity] = useState('');
	const [productId, setProductID] = useState('');
	const [statestoreId, setStoreId] = useState('');
	const [storeData, setStoreData] = useState();
	const [transferToValue, setTransferToValue] = useState('store');

	const handleTransferTo = (e) => {};

	const [racksArray, setRackArray] = useState([]);

	const handleRentRack = (e) => {};

	const [selectStoreRack, setSelectStoreRack] = useState([]);
	const [stockLocation, setStockLocation] = useState('');

	const [rackId, setRacksId] = useState([]);
	const handleSelectRack = (e, rack) => {};
	function fn_handleRacksData(e, rack) {}

	const [productTotalQuantity, setProductTotalQuantity] = useState();

	const addTransferIn = () => {};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage Stores</h5>
								<ul
									class='nav nav-pills mb-3 d-flex justify-content-start'
									style={{ gap: '12px' }}
									id='pills-tab'
									role='tablist'
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link active'
											id='pills-home-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-home'
											type='button'
											role='tab'
											aria-controls='pills-home'
											aria-selected='true'
											style={{ color: 'black' }}
										>
											Main Stock
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-profile-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-profile'
											type='button'
											role='tab'
											aria-controls='pills-profile'
											aria-selected='false'
											style={{ color: 'black' }}
										>
											Stores
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-rent-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-rent'
											type='button'
											role='tab'
											aria-controls='pills-rent'
											aria-selected='false'
											style={{ color: 'black' }}
										>
											On Rent Racks
										</button>
									</li>
								</ul>
								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-home'
										role='tabpanel'
										aria-labelledby='pills-home-tab'
									>
										<div
											className='offcanvas offcanvas-end'
											tabIndex={-1}
											id='offcanvasRightstock'
											aria-labelledby='offcanvasRightLabel'
										>
											<div className='offcanvas-header px-4'>
												<div className='content-header'>
													<h4>Manage Seller Create / Update</h4>
													<p>Update products info, combinations and extras.</p>
												</div>
												<button
													type='button'
													className='btn-close bg-white shadow rounded-5 text-reset'
													data-bs-dismiss='offcanvas'
													aria-label='Close'
													style={{
														width: '5px',
														height: '30px',
														opacity: '0.8',
														fontSize: '12px',
													}}
												/>
											</div>
										</div>

										<div>
											<div className=' text-end'>
												<button
													type='button'
													class='btn '
													data-bs-toggle='modal'
													data-bs-target='#exampleModal3'
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
													}}
												>
													Product Transfer
												</button>
											</div>
											<div className='min-box my-4'>
												<div></div>

												{/* <!-- Modal --> */}
												<div
													class='modal fade'
													id='exampleModal3'
													tabindex='-1'
													aria-labelledby='exampleModalLabel'
													aria-hidden='true'
												>
													<div class='modal-dialog'>
														<div class='modal-content'>
															<div class='modal-header'>
																<h5
																	class='modal-title'
																	id='exampleModalLabel'
																>
																	Product Transfer
																</h5>
																<button
																	type='button'
																	class='btn-close'
																	data-bs-dismiss='modal'
																	aria-label='Close'
																></button>
															</div>
															<div class='modal-body'>
																<from>
																	<div className='col-md-12 text-start mb-2'>
																		<label>Product</label>
																		<select
																			onChange={(e) =>
																				setProductID(e.target.value)
																			}
																			name='productID'
																			id='productId'
																			class='form-control-input-2'
																		>
																			<option
																				selected
																				value={''}
																			>
																				--select product---
																			</option>
																			{mainStockProduct?.map((item) => {
																				return (
																					<option
																						value={item?._id}
																						id={item?._id}
																					>
																						{item?.name}
																					</option>
																				);
																			})}
																		</select>
																		{productTotalQuantity > 0 && (
																			<p>
																				<b>{productTotalQuantity}</b> Available
																				Quantity in Main Stock
																			</p>
																		)}
																	</div>
																	<div className='col-md-12 text-start mb-2'>
																		<label className='fw-bold'>
																			Transfer to :
																		</label>
																		<br />
																		<input
																			type='radio'
																			name='transferTo'
																			defaultChecked
																			onChange={handleTransferTo}
																			value={'store'}
																			className='mx-1'
																		/>
																		Store Racks
																		<input
																			type='radio'
																			name='transferTo'
																			onChange={handleTransferTo}
																			value={'Rental Racks'}
																			className='ms-5 me-1'
																		/>
																		Rental Racks
																	</div>
																	{transferToValue === 'store' ? (
																		<div className='col-md-12 text-start'>
																			<label>Store</label>
																			<select
																				name='storeID'
																				onChange={(e) =>
																					setStoreId(e.target.value)
																				}
																				class='form-control-input-2'
																			>
																				<option
																					selected
																					value={''}
																				>
																					--select store name---
																				</option>
																				{categories?.map((item) => {
																					return (
																						<option value={item?._id}>
																							{item?.name}
																						</option>
																					);
																				})}
																			</select>
																		</div>
																	) : (
																		<div>
																			{buyRacks.length > 0 ? (
																				buyRacks?.map((rackNumber) => (
																					<>
																						<input
																							type='checkbox'
																							value={rackNumber?._id}
																							onChange={handleRentRack}
																						/>
																						<label className='me-5'>
																							{rackNumber?.name}
																						</label>
																					</>
																				))
																			) : (
																				<>No Stock Available</>
																			)}
																		</div>
																	)}
																	{selectStoreRack.length > 0 &&
																		stockLocation && (
																			<>
																				<p
																					style={{
																						fontSize: '1.05rem',
																						textAlign: 'right',
																						margin: '0.5rem 0',
																					}}
																				>
																					<MdLocationOn />
																					&nbsp;
																					{stockLocation}
																				</p>
																			</>
																		)}
																	<div
																		className='mt-3'
																		style={{
																			display: 'grid',
																			gridTemplateColumns: '1fr 1fr',
																		}}
																	>
																		{selectStoreRack.length > 0 ? (
																			selectStoreRack?.map((rack) => {
																				if (rack?.state === 'occupied') {
																					return (
																						<div className='text-start mb-2'>
																							<input
																								type='checkbox'
																								disabled
																							/>
																							<label>
																								<b style={{ color: 'grey' }}>
																									{rack?.name}
																								</b>{' '}
																								<span
																									style={{ fontSize: '12px' }}
																								>
																									(F : {rack?.front}, B :{' '}
																									{rack?.back}, H :{' '}
																									{rack?.height})
																								</span>
																							</label>
																						</div>
																					);
																				} else {
																					return (
																						<div className='text-start mb-2'>
																							<input
																								type='checkbox'
																								value={rack?._id}
																								onChange={(e) =>
																									handleSelectRack(e, rack)
																								}
																							/>
																							<label>
																								<b>{rack?.name}</b>{' '}
																								<span
																									style={{ fontSize: '12px' }}
																								>
																									(F : {rack?.front}, B :{' '}
																									{rack?.back}, H :{' '}
																									{rack?.height})
																								</span>
																							</label>
																						</div>
																					);
																				}
																			})
																		) : (
																			<>No Stock Available</>
																		)}
																		{rackId?.map((rackData) => (
																			<div className='col-6 mt-2 mb-3'>
																				<input
																					type='number'
																					style={{ width: '200px' }}
																					placeholder={
																						'Enter capacity for ' +
																						rackData?.name
																					}
																					className='racks_input'
																					onChange={(e) =>
																						fn_handleRacksData(e, rackData)
																					}
																				/>
																			</div>
																		))}
																	</div>
																	<div class='modal-footer'>
																		<button
																			type='button'
																			class='btn btn-secondary'
																			data-bs-dismiss='modal'
																		>
																			Close
																		</button>
																		<button
																			type='submit'
																			onClick={(e) => {
																				e.preventDefault();
																				addTransferIn(e.target);
																			}}
																			class='btn btn-success'
																			data-bs-dismiss='modal'
																			style={{
																				backgroundColor: '#ffd700',
																				color: 'black',
																			}}
																		>
																			Save changes
																		</button>
																	</div>
																</from>
															</div>
														</div>
													</div>
												</div>

												<div className='products-table'>
													<table
														className='table border rounded align-middle'
														style={{ borderColor: '#4f4f51' }}
													>
														<thead className='bg-light'>
															<tr>
																<th>#Id</th>
																<th>Warehouse Name</th>
																<th>Warehouse Size (ft)</th>
																<th>Location</th>
																<th>ACTIONS</th>
															</tr>
														</thead>
														<tbody>
															{mainStock?.map((item, index) => {
																return (
																	<>
																		<tr>
																			<td>{index + 1}</td>
																			<td>{item?.warehouseName}</td>
																			<td>
																				{item?.width + ' x ' + item?.height}
																			</td>
																			<td>{item?.location}</td>
																			<td>
																				<div className='actions d-flex'>
																					<span className='px-3'>
																						<Link
																							to={`/mainStockracks/${item?.sellerID}`}
																							style={{ fontSize: 21 }}
																						>
																							<BsFillEyeFill color='#000' />
																						</Link>
																					</span>

																					{/* <span
                                            onClick={() => {
                                              setSellerID(item?._id);

                                              axios
                                                .get(
                                                  `${ApiUrl}/store/getById?id=` +
                                                    item?._id
                                                )
                                                .then((res) => {
                                                  console.log(
                                                    res.data,
                                                    "store"
                                                  );

                                                  setStores(res.data);
                                                });
                                            }}
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRightstock"
                                            aria-controls="offcanvasRightstock"
                                            style={{ fontSize: 21 }}
                                          >
                                            <FiEdit />
                                          </span>
                                          <span
                                            onClick={() => {
                                              onDeleteStudent(item?._id);
                                            }}
                                            style={{ fontSize: 21 }}
                                            className="px-3"
                                          >
                                            <RiDeleteBinLine />
                                          </span> */}
																				</div>
																			</td>
																		</tr>
																	</>
																);
															})}
														</tbody>
													</table>
													<div className='data-pagination px-4 d-flex'>
														<p>SHOWING 41-60 OF 308</p>

														<nav aria-label='Page navigation example'>
															<ul className='pagination'>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																		aria-label='Previous'
																	>
																		<span aria-hidden='true'>«</span>
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		1
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		2
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		3
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		4
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		5
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																	>
																		6
																	</a>
																</li>
																<li className='page-item'>
																	<a
																		className='page-link'
																		href='#'
																		aria-label='Next'
																	>
																		<span aria-hidden='true'>»</span>
																	</a>
																</li>
															</ul>
														</nav>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class='tab-pane fade'
										id='pills-profile'
										role='tabpanel'
										aria-labelledby='pills-profile-tab'
									>
										<div className='min-box  border'>
											<div className='products-actions d-flex p-4'>
												<div className='imort-product '>
													<div className='btn-product d-flex'></div>
												</div>
												<div className='action-btn'>
													<div className='actions d-flex'>
														<div className='Bulk-btn'></div>

														{/* Modal-Del */}
														{/* <button
                              className="add-btn"
                              type="button"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              <AiOutlinePlus />
                              <span className="px-1"> Add Store</span>
                            </button> */}

														<div
															className='offcanvas offcanvas-end'
															tabIndex={-1}
															id='offcanvasRightupdate'
															aria-labelledby='offcanvasRightLabel'
														>
															<div className='offcanvas-header px-4'>
																<div className='content-header'>
																	<h4>Manage Store </h4>
																	<p>
																		Update Store info, combinations and extras.
																	</p>
																</div>

																<button
																	type='button'
																	className='btn-close bg-white shadow rounded-5 text-reset'
																	data-bs-dismiss='offcanvas'
																	aria-label='Close'
																	style={{
																		width: '5px',
																		height: '30px',
																		opacity: '0.8',
																		fontSize: '12px',
																	}}
																/>
															</div>
															{/* Canvas */}

															<div className='offcanvas-body p-0'>
																<div className='form-data'>
																	<div className='wrap-data p-5'>
																		<form
																			onSubmit={(e) => {
																				e.preventDefault();
																				updateData(e.target);
																			}}
																		>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Your Name
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						id='name'
																						name='name'
																						className='form-control-input'
																						placeholder='Enter Your Name'
																						defaultValue={stores?.name}
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Your Email
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						id='email'
																						name='email'
																						className='form-control-input'
																						placeholder='Enter Your Email'
																						defaultValue={stores?.email}
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Contact Number
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						name='phoneNo'
																						className='form-control-input'
																						placeholder='Enter Your Number'
																						defaultValue={stores?.phoneNo}
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Brand Name
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						name='brandName'
																						className='form-control-input'
																						placeholder='Enter Your Brand Name'
																						defaultValue={stores?.brandName}
																					/>
																				</div>
																			</div>
																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Product Categories
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<select
																						name='productCategories'
																						id='productCategories'
																						class='form-control-input-2'
																						defaultValue={
																							stores?.productCategories
																						}
																					>
																						<option selected>
																							All Categories
																						</option>
																						<option value='1'>
																							Men Fashion
																						</option>
																					</select>
																				</div>
																			</div>

																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Brand Logo
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<div className='col-span-8 sm:col-span-4'>
																						<div className='w-full text-center'>
																							<div
																								className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																								role='button'
																								tabIndex={0}
																								style={{
																									borderStyle: 'dotted',
																									borderColor: '#ffd700',
																								}}
																							>
																								<input
																									accept='image/*'
																									multiple=''
																									type='file'
																									name='brandLogo'
																									defaultValue={
																										stores?.brandLogo
																									}
																								/>
																								<span className='mx-auto flex justify-center'>
																									<svg
																										stroke='currentColor'
																										fill='none'
																										strokeWidth={2}
																										viewBox='0 0 24 24'
																										strokeLinecap='round'
																										strokeLinejoin='round'
																										className='text-3xl text-green-500'
																										height='1em'
																										width='1em'
																										xmlns='http://www.w3.org/2000/svg'
																									>
																										<polyline points='16 16 12 12 8 16' />
																										<line
																											x1={12}
																											y1={12}
																											x2={12}
																											y2={21}
																										/>
																										<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																										<polyline points='16 16 12 12 8 16' />
																									</svg>
																								</span>
																								<p className='text-sm mt-2'>
																									Drag your images here
																								</p>
																								<em className='text-xs text-gray-400'>
																									(Only *.jpeg, *.webp and *.png
																									images will be accepted)
																								</em>
																							</div>
																							<div className='text-green-500' />
																							<aside className='flex flex-row flex-wrap mt-4' />
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className='offcanvas-footer px-5 py-3'>
																				<div className='content-footer'>
																					<div className='row'>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='button'
																								class='cancel-btn btn-lg'
																							>
																								Cancel
																							</button>
																						</div>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Save
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															</div>
															{/* Canvas */}
														</div>

														<div
															className='offcanvas offcanvas-end'
															tabIndex={-1}
															id='offcanvasRight'
															aria-labelledby='offcanvasRightLabel'
														>
															<div className='offcanvas-header px-4'>
																<div className='content-header'>
																	<h4>Manage Store</h4>
																	<p>
																		Update Store info, combinations and extras.
																	</p>
																</div>

																<button
																	type='button'
																	className='btn-close bg-white shadow rounded-5 text-reset'
																	data-bs-dismiss='offcanvas'
																	aria-label='Close'
																	style={{
																		width: '5px',
																		height: '30px',
																		opacity: '0.8',
																		fontSize: '12px',
																	}}
																/>
															</div>
															{/* Canvas */}

															<div className='offcanvas-body p-0'>
																<div className='form-data'>
																	<div className='wrap-data p-5'>
																		<form
																			onSubmit={(e) => {
																				e.preventDefault();
																				sendData(e.target);
																			}}
																		>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Your Name
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						id='name'
																						name='name'
																						className='form-control-input'
																						placeholder='Enter Your Name'
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Your Email
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						id='email'
																						name='email'
																						className='form-control-input'
																						placeholder='Enter Your Email'
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Your Password
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='password'
																						id='password'
																						name='password'
																						className='form-control-input'
																						placeholder='Enter Your Store Password'
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Contact Number
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						name='phoneNo'
																						className='form-control-input'
																						placeholder='Enter Your Number'
																					/>
																				</div>
																			</div>
																			<div className='row pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Brand Name
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<input
																						type='text'
																						name='brandName'
																						className='form-control-input'
																						placeholder='Enter Your Brand Name'
																					/>
																				</div>
																			</div>
																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Product Categories
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<select
																						name='productCategories'
																						id='productCategories'
																						class='form-control-input-2'
																					>
																						<option selected>
																							---Select Category---
																						</option>
																						{allCategory?.map((item, index) => {
																							return (
																								<>
																									<option value={item?._id}>
																										{item?.title}
																									</option>
																								</>
																							);
																						})}
																					</select>
																				</div>
																			</div>

																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-4'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Brand Logo
																					</label>
																				</div>
																				<div className='col-md-8'>
																					<div className='col-span-8 sm:col-span-4'>
																						<div className='w-full text-center'>
																							<div
																								className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																								role='button'
																								tabIndex={0}
																								style={{
																									borderStyle: 'dotted',
																									borderColor: '#ffd700',
																								}}
																							>
																								<input
																									accept='image/*'
																									multiple=''
																									type='file'
																									name='brandLogo'
																								/>
																								<span className='mx-auto flex justify-center'>
																									<svg
																										stroke='currentColor'
																										fill='none'
																										strokeWidth={2}
																										viewBox='0 0 24 24'
																										strokeLinecap='round'
																										strokeLinejoin='round'
																										className='text-3xl text-green-500'
																										height='1em'
																										width='1em'
																										xmlns='http://www.w3.org/2000/svg'
																									>
																										<polyline points='16 16 12 12 8 16' />
																										<line
																											x1={12}
																											y1={12}
																											x2={12}
																											y2={21}
																										/>
																										<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																										<polyline points='16 16 12 12 8 16' />
																									</svg>
																								</span>
																								<p className='text-sm mt-2'>
																									Drag your images here
																								</p>
																								<em className='text-xs text-gray-400'>
																									(Only *.jpeg, *.webp and *.png
																									images will be accepted)
																								</em>
																							</div>
																							<div className='text-green-500' />
																							<aside className='flex flex-row flex-wrap mt-4' />
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className='offcanvas-footer px-5 py-3'>
																				<div className='content-footer'>
																					<div className='row'>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='button'
																								class='btn cancel-btn btn-lg text-light'
																								data-bs-dismiss={'offcanvas'}
																								aria-label='Close'
																							>
																								Cancel
																							</button>
																						</div>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='submit'
																								class='btn add-last-btn btn-lg text-light btn-success'
																								data-bs-dismiss={offcanvas}
																								aria-label='Close'
																							>
																								Save
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															</div>
															{/* Canvas */}
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* Modal-Del */}
										<div
											class='modal fade'
											id='exampleModal'
											tabindex='-1'
											aria-labelledby='exampleModalLabel'
											aria-hidden='true'
										>
											<div class='modal-dialog'>
												<div class='modal-content py-4'>
													<div class='modal-body'>
														<div className='modal-icon'>
															<span
																style={{ fontSize: 35, color: '#f05252' }}
																className='px-3'
															>
																<RiDeleteBinLine />
															</span>
														</div>
														<h1 class='title py-3'>
															Are You Sure! Want to Delete{' '}
															<span style={{ color: '#f05252' }}>
																Selected Products?
															</span>
														</h1>
														<p className='para'>
															Do you really want to delete these records? You
															can't view this in <br /> your list anymore if you
															delete!
														</p>
														...
														<div className='last-btn'>
															<button
																type='button'
																class='btn  btn-light mx-4 py-2 px-4'
																data-bs-dismiss='modal'
															>
																No,Keep It
															</button>
															<button
																onClick={() => {
																	const id = localStorage.getItem('myUserId');

																	const params = {
																		id: id,
																	};

																	axios
																		.post(
																			'http://157.245.104.106:3003/embassador/delete',
																			params
																		)
																		.then((res) => {
																			console.log(res.data);

																			toast('Storesador updated successfully');
																		});
																}}
																type='button'
																class='btn btn-success py-2 px-4'
															>
																Yes, Delete It
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div>
											{/* <div className="row pb-4" id="allTabs">
                        <div className="col-md-12">
                          <Link
                            to={`/e_racks/${id}`}
                            className="counterbox text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            E-Rackssss
                          </Link>
                          <Link
                            to={`/e_stock/${id}`}
                            className="counterbox text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            E-Stocks
                          </Link>
                          <Link
                            to={`/services/${id}`}
                            className="counterbox text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            E-Services
                          </Link>
                          <div
                            className="counterbox text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            E-Display
                          </div>
                        </div>
                      </div> */}
											<div className='min-box my-4'>
												<div className='products-table'>
													<table
														className='table border rounded align-middle'
														style={{ borderColor: '#4f4f51' }}
													>
														<thead className='bg-light'>
															<tr>
																<th>
																	<input
																		className='form-check-input'
																		type='checkbox'
																		value=''
																		id='flexCheckDefault'
																	/>
																</th>
																<th>#Id</th>
																<th>Image</th>
																<th>Name</th>
																<th>Email</th>
																<th>Number</th>
																<th>ACTIONS</th>
															</tr>
														</thead>
														<tbody>
															{categories?.map((item, index) => {
																if (item?.status === 'approved') {
																	return (
																		<>
																			<tr>
																				<td>
																					<input
																						className='form-check-input'
																						type='checkbox'
																						value=''
																						id='flexCheckDefault'
																					/>
																				</td>
																				<td>{index + 1}</td>
																				<td>
																					<div className='prod-img'>
																						<img
																							src={`${ApiUrl}/images/${item?.brandLogo}`}
																							alt=''
																						/>
																					</div>
																				</td>
																				<td>{item?.name}</td>
																				<td>{item?.email}</td>
																				<td>{item?.phoneNo}</td>
																				<td>
																					<div className='actions d-flex'>
																						<span className='px-3'>
																							<Link
																								to={`/seller_store/${item._id}`}
																								style={{ fontSize: 21 }}
																							>
																								<BsFillEyeFill color='#000' />
																							</Link>
																						</span>

																						<span
																							onClick={() => {
																								setSellerID(item?._id);

																								axios
																									.get(
																										`${ApiUrl}/store/getById?id=` +
																											item?._id
																									)
																									.then((res) => {
																										console.log(
																											res.data,
																											'store'
																										);

																										setStores(res.data);
																									});
																							}}
																							data-bs-toggle='offcanvas'
																							data-bs-target='#offcanvasRightupdate'
																							aria-controls='offcanvasRightupdate'
																							style={{ fontSize: 21 }}
																						>
																							<FiEdit />
																						</span>
																						<span
																							onClick={() => {
																								onDeleteStudent(item?._id);
																							}}
																							style={{ fontSize: 21 }}
																							className='px-3'
																						>
																							<RiDeleteBinLine />
																						</span>
																					</div>
																				</td>
																			</tr>
																		</>
																	);
																}
															})}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									{/* On Rent Racks Tab Start */}
									<div
										class='tab-pane fade'
										id='pills-rent'
										role='tabpanel'
										aria-labelledby='pills-rent-tab'
									>
										<div className='row'>
											{buyRacks && buyRacks.length > 0 ? (
												buyRacks?.map((rack) => {
													return (
														<div className='col-md-3'>
															<div
																className='p-2'
																style={{
																	backgroundColor: '#fff',
																	border: ' 1px solid rgb(243, 239, 239)',
																	borderRadius: '10px',
																}}
															>
																<div
																	className='order'
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																		justifyContent: 'space-between',
																	}}
																>
																	<div
																		style={{ cursor: 'pointer' }}
																		data-bs-toggle='modal'
																		data-bs-target='#ownedModal1'
																	>
																		<h4>{rack?.name}</h4>
																	</div>
																	<div style={{ zIndex: '999' }}>
																		{rack?.hasDimensions && (
																			<span
																				style={{
																					fontSize: '0.95rem',
																					backgroundColor: 'rgb(220 231 244)',
																					padding: '0.2rem 0.08rem',
																					borderRadius: '0.1rem',
																					textAlign: 'center',
																				}}
																			>
																				front:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{rack?.front}
																				</span>
																				, back:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{rack?.back}
																				</span>
																				, height:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{rack?.height}
																				</span>
																			</span>
																		)}
																	</div>
																</div>

																<div className='d-flex justify-content-between pt-3'>
																	<p>
																		{rack?.state === 'empty' ? (
																			<span
																				style={{
																					textTransform: 'capitalize',
																				}}
																			>
																				{rack?.state}
																			</span>
																		) : (
																			<>
																				Product : <b>{rack?.productName}</b>
																				<br />
																				Qty :{' '}
																				<b>
																					{rack?.productQty} {rack?.productUnit}
																				</b>
																			</>
																		)}
																	</p>
																	{rack?.state === 'empty' ? (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<AiFillCloseCircle
																				size={20}
																				color='red'
																			/>
																		</div>
																	) : (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<i
																				class='fa-solid fa-check'
																				style={{
																					fontSize: '18px',

																					color: ' #ffd700',
																				}}
																			></i>
																		</div>
																	)}
																</div>
																<hr />
																{rack?.hasDimensions && (
																	<div className='mb-2'>
																		<span>
																			Type : <b>{rack?.rackType}</b>
																		</span>
																	</div>
																)}
																<div>
																	<button
																		onClick={() => {
																			navigate(`/viewProduct/${rack?._id}`);
																			localStorage.setItem(
																				'RackName',
																				rack?.name
																			);
																		}}
																		class='btn  w-100 text-right btn-success'
																	>
																		View Rack
																	</button>
																</div>
															</div>
														</div>
													);
												})
											) : (
												<h3 className='text-center mt-3'>
													No Racks are taken on Rent
												</h3>
											)}
										</div>
									</div>
									{/* On Rent Racks Tab End */}
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Stores);
