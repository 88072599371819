import React, { useState } from 'react';
import ProductList from './ProductList';
import RackList from './RackList';
import DisplayList from './DisplayList';
import ServiceList from './ServiceList';
import './Order.css';
import withAuth from '../../../hooks/withAuth';
function All() {
	const [dataShow, setDataShow] = useState('products');
	return (
		<>
			<div style={{ display: 'flex', gap: '1rem', margin: '1rem' }}>
				<button
					className='btn btn-sm btn-success'
					style={{
						backgroundColor: '#ffd700',
						color: 'black',
					}}
					onClick={() => setDataShow('products')}
				>
					Product Orders
				</button>
				<button
					className='btn btn-sm btn-success'
					style={{
						backgroundColor: '#ffd700',
						color: 'black',
					}}
					onClick={() => setDataShow('racks')}
				>
					Racks Orders
				</button>
				<button
					className='btn btn-sm btn-success'
					style={{
						backgroundColor: '#ffd700',
						color: 'black',
					}}
					onClick={() => setDataShow('displays')}
				>
					Displays Orders
				</button>
				<button
					className='btn btn-sm btn-success'
					style={{
						backgroundColor: '#ffd700',
						color: 'black',
					}}
					onClick={() => setDataShow('services')}
				>
					Services Orders
				</button>
			</div>
			<hr />
			{dataShow === 'products' && <ProductList />}
			{dataShow === 'racks' && <RackList />}
			{dataShow === 'displays' && <DisplayList />}
			{dataShow === 'services' && <ServiceList />}
		</>
	);
}

export default withAuth(All);
