import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiOutlineFastBackward } from 'react-icons/ai';
import { IoMdArrowRoundBack } from 'react-icons/io';
import ApiUrl from '../BaseUrl';
import { Oval } from 'react-loader-spinner';
import Order_Details from './Order_Details';
import { toast } from 'react-toastify';

const RackList = () => {
	const [orderList, setOrderList] = useState();
	const [adminData, setAdminData] = useState();
	const [loader, setLoader] = useState(true);
	const [showTable, setShowTable] = useState(false);
	const [singleOrderData, setSingleOrderData] = useState('');
	const [updateStatus, setUpdateStatus] = useState('');

	const fn_OrderDetails = (value) => {
		setSingleOrderData((prevActive) => (prevActive === value ? '' : value));
	};

	const fn_updateStatus = () => {};

	return (
		<div>
			<h2 className='ms-2 mb-2'>Manage Racks Orders</h2>
			<Oval
				height={60}
				width={60}
				color='#4fa94d'
				wrapperStyle={{}}
				wrapperClass=''
				visible={loader}
				ariaLabel='oval-loading'
				secondaryColor='#4fa94d'
				strokeWidth={2}
				strokeWidthSecondary={2}
			/>
			{showTable ? (
				!singleOrderData ? (
					<table className='Ordertable_data'>
						<tr className='Ordertable_dataRow Ordertable_dataRowHead'>
							<th>Order ID</th>
							<th>Order Date</th>
							<th>Buyer Info</th>
							<th>Total Racks</th>
							<th>Total Amount</th>
							<th>Order Status</th>
							<th>View</th>
						</tr>
						{orderList &&
							orderList?.map((item) => (
								<tr className='Ordertable_dataRow Ordertable_dataRowBody'>
									<td>{item?._id}</td>
									<td>{new Date(item?.createdAt).toDateString()}</td>
									<td>{item?.buyerDetail?.name}</td>
									<td>{item?.rackID?.length}</td>
									<td>
										$
										{item?.rackID?.reduce((acc, i) => {
											return (
												acc +
												(parseInt(i?.price) +
													adminData?.[0]?.rentalRacksComission)
											);
										}, 0)}
									</td>
									<td style={{ textTransform: 'capitalize' }}>
										{item?.orderStatus}
									</td>
									<td
										style={{ width: '110px', cursor: 'pointer' }}
										onClick={() => fn_OrderDetails(item)}
									>
										<AiFillEye />
									</td>
								</tr>
							))}
					</table>
				) : null
			) : null}
			{singleOrderData !== '' && (
				<>
					<div>
						<div
							className=' container updateOrder22'
							style={{ justifyContent: 'space-between' }}
						>
							<form
								onSubmit={fn_updateStatus}
								className='d-flex mb-4'
							>
								<select
									className='form-select'
									onChange={(e) => setUpdateStatus(e?.target?.value)}
								>
									<option
										selected
										value={''}
									>
										---Select Status---
									</option>
									<option value={'pending'}>Pending</option>
									<option value={'confirmed'}>Confirmed</option>
									<option value={'out for delivery'}>Out for Delivery</option>
									<option value={'delivered'}>Delivered</option>
									<option value={'cancelled'}>Cancelled</option>
									<option value={'returned'}>Returned</option>
									<option value={'failed to deliver'}>Failed to Deliver</option>
								</select>
								<button
									variant='success'
									type='submit'
									style={{
										border: 'none',
										color: 'white',
										backgroundColor: '#0b9444',
										borderTopRightRadius: '5px',
										borderBottomRightRadius: '5px',
									}}
								>
									Update
								</button>
							</form>
							<IoMdArrowRoundBack
								className='backBtnOrders'
								onClick={() => setSingleOrderData('')}
							/>
						</div>
					</div>

					<Order_Details data={singleOrderData} />
				</>
			)}
		</div>
	);
};

export default RackList;
