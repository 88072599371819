import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import ApiUrl from '../BaseUrl';
import { useNavigate } from 'react-router-dom';

function EStock() {
	const [categories, setCategories] = useState([]);

	const [stocks, setStocks] = useState([]);
	const [racks, setRacks] = useState([]);
	const [sellerid, setSellerID] = useState(null);
	const [singleStock, setSingleStock] = useState();
	const [displayRacks, setDisplayRacks] = useState([]);
	const [rId, setRId] = useState([]);
	const [racksDetail, setRacksDetail] = useState([]);

	const { id } = useParams();
	const [allStore, setAllStore] = useState();

	useEffect(() => {
		axios.get(`${ApiUrl}/e-stock/getByStoreID?id=` + id).then((res) => {
			setStocks(res.data);
		});

		axios.get(`${ApiUrl}/e-rack/getByStoreID?id=` + id).then((res) => {
			setRacks(res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) })));
		});

		axios
			.get(
				`${ApiUrl}/store/getBySellerID?id=` + localStorage.getItem('sellerId')
			)
			.then((res) => {
				setAllStore(res?.data);
			});

		axios.get(`${ApiUrl}/e-displayRack/getByStoreID?id=` + id).then((res) => {
			setDisplayRacks(
				res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) }))
			);
		});
	}, []);

	let uniqueProductIDs = [];

	racks.forEach((rack) => {
		const found = uniqueProductIDs.find((id) => id === rack.product);
		if (!found) {
			uniqueProductIDs.push({ id: rack.product, name: rack.productName });
		}
	});

	const [displayRackIDs, setDisplayRackIDs] = useState([]);

	const handleDisplayCheck = (e) => {
		if (e?.target?.checked) {
			displayRackIDs.push(e.target?.value);
		} else if (e?.target?.checked === false) {
			setDisplayRackIDs(
				displayRackIDs.filter((rack) => rack !== e?.target?.value)
			);
		}
	};

	const [location, setLocation] = useState(null);
	const [weather, setWeather] = useState(null);

	function success(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setLocation({ latitude, longitude });
		// console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=db0e108a510e5ec03389a47fdbeefddf&units=metric`
		)
			.then((response) => response.json())
			.then((data) => {
				setWeather(data);
				// console.log(data);
			})
			.catch((error) => console.log(error));
	}
	function error() {
		console.log('Unable to retrieve your location');
	}

	const sendData = (values) => {
		if (values?.warehouseName?.value?.length === 0) {
			toast.error('please Enter your your warehouse Name');
		} else if (values?.width?.value?.length === 0) {
			toast.error('please Enter your warehouse width');
		} else if (values?.height?.value?.length === 0) {
			toast.error('please Enter your height');
		} else if (values?.address?.value?.length === 0) {
			toast.error('please Enter your address');
		} else if (values?.status?.value?.length === 0) {
			toast.error('please Enter your status');
		} else {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(success, error);
			} else {
				console.log('Geolocation not supported');
			}
			const params = {
				storeID: id,
				warehouseName: values.warehouseName.value,
				width: values.width.value,
				height: values.height.value,
				address: values.address.value,
				location: weather?.name,
				status: values.status.value,
			};
			if (weather?.name === undefined) {
				return toast.error('Can not access your Location');
			}
			axios
				.post(`${ApiUrl}/e-stock/create`, params)

				.then((res) => {
					console.log(res.data);

					if (res.data.status === 'success') {
						toast.success('Store Stock Created Successfully');
						// const param = {
						//   storeID: res.data.data.storeID,
						//   rackcouts: res.data.data.stockCapacity,
						// };
						// console.log(param);
						// axios
						//   .post(`${ApiUrl}/e-rack/create`, param)

						//   .then((res) => {
						//     console.log(res.data);

						//     if (res.data.status === "success") {
						//       axios
						//         .get(`${ApiUrl}/e-stock/getByStoreID?id=` + id)
						//         .then((res) => {
						//           console.log(res.data);
						//           setStocks([...res.data]);
						//           console.log(stocks);
						//         });
						//     }
						//   });
					}
				});
		}
	};

	const updateData = (values) => {
		const params = {
			id: sellerid,
			warehouseName: values.warehouseName.value,
			width: values.width.value,
			height: values.height.value,
			stockCapacity: values.stockCapacity.value,
			address: values.address.value,
			status: values.status.value,
		};
		axios
			.put(`${ApiUrl}/e-stock/updateByID`, params)

			.then((res) => {
				console.log(res.data);

				if (res.data.status === 'success') {
					const param = {
						storeID: res.data.data.storeID,
						rackcouts: res.data.data.stockCapacity,
					};
					console.log(param);
					axios
						.put(`${ApiUrl}/e-rack/updateById`, param)

						.then((res) => {
							console.log(res.data);
						});
				}

				toast('E stock update successfully!');
			});
	};

	const onDeleteStudent = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				axios.delete(`${ApiUrl}/e-stock/deleteByID?id=` + id).then((res) => {
					console.log(res.data);

					toast('e-stock deleted successfully!');
				});
			},
		});
	};

	const [productId, setProdcutID] = useState('');
	const [productName, setProductName] = useState('');

	function fn_handleRacksData(e, rack) {
		const qty = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].qty = qty;
		setRacksDetail(tempRacks);
	}
	const handleSelectRack = (e, rack) => {
		if (e?.target?.checked) {
			setRId((prev) => [
				...prev,
				{ rackId: rack._id, name: rack.name, qty: 0 },
			]);
		} else if (e?.target?.checked === false) {
			setRId(rId.filter((rack) => rack !== e?.target?.value));
		}
	};

	const addTransferIn = () => {
		if (productId.length === 0) {
			toast.error('Select Product to Stock In to E-Display');
		} else {
			const params = {
				productID: productId,
				productName: productName,
				storeID: id,
				racksDetail: racksDetail,
				remaining: racksDetail.reduce((acc, i) => {
					return acc + parseInt(i?.qty);
				}, 0),
			};
			console.log(params);
			axios
				.post(`${ApiUrl}/e-displayRack/transferToDisplayRack`, params)
				.then((res) => {
					if (res.data.status) {
						toast.success('Product add to Display Successfully!');
						setTimeout(() => {
							window?.location?.reload();
						}, 1300);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between'>
									<h5 className='kk ps-3'> Manage E-Stock</h5>
								</div>

								{/* <!-- Product Transfer Modal --> */}
								<div
									class='modal fade'
									id='exampleModal3'
									tabindex='-1'
									aria-labelledby='exampleModalLabel'
									aria-hidden='true'
								>
									<div class='modal-dialog'>
										<div class='modal-content'>
											<div class='modal-header'>
												<h5
													class='modal-title'
													id='exampleModalLabel'
												>
													Transfer Product to E-Display
												</h5>
												<button
													type='button'
													class='btn-close'
													data-bs-dismiss='modal'
													aria-label='Close'
												></button>
											</div>
											<div class='modal-body'>
												<from>
													<div className='col-md-12 text-start mb-2'>
														<label>Store</label>
														<input
															name='storeID'
															type='text'
															className='form-control-input'
															value={stocks?.[0]?.warehouseName}
															disabled
														/>
													</div>
													<div className='col-md-12 text-start'>
														<label>Product</label>
														<select
															onChange={(e) => {
																const selectedIndex = e.target.selectedIndex;
																const selectedProduct =
																	uniqueProductIDs[selectedIndex - 1];
																if (uniqueProductIDs) {
																	setProductName(selectedProduct?.name);
																	setProdcutID(selectedProduct?.id);
																}
															}}
															name='productID'
															class='form-control-input-2'
														>
															<option
																selected
																value={''}
															>
																---select product---
															</option>
															{uniqueProductIDs &&
																uniqueProductIDs?.map((item) => {
																	if (item.name !== '') {
																		return (
																			<option value={item.id}>
																				{item.name}
																			</option>
																		);
																	}
																})}
														</select>
													</div>
													<div className='col-md-12 pt-3 text-start'>
														<label>Check Display Racks to Fill</label>
														<div
															className='my-3'
															style={{
																display: 'grid',
																gridTemplateColumns: '1fr 1fr',
															}}
														>
															{displayRacks &&
																displayRacks?.map((rack) => {
																	if (rack?.state === 'empty') {
																		return (
																			<div>
																				<input
																					type='checkbox'
																					className='me-1'
																					value={rack?._id}
																					onChange={(e) =>
																						handleSelectRack(e, rack)
																					}
																				/>
																				<label>
																					<b>{rack?.name}</b>{' '}
																					<span style={{ fontSize: '12px' }}>
																						({rack?.front}, {rack?.back},{' '}
																						{rack?.height}){' '}
																						<span style={{ fontSize: '14px' }}>
																							[{rack?.displayType}
																						</span>
																						]
																					</span>
																				</label>
																			</div>
																		);
																	} else {
																		return (
																			<div>
																				<input
																					type='checkbox'
																					className='me-1'
																					value={rack?._id}
																					disabled
																				/>
																				<label>{rack?.name}</label>
																			</div>
																		);
																	}
																})}
														</div>
													</div>
													<div className='row'>
														{rId?.map((rackData) => (
															<div className='col-6 mt-2 mb-3'>
																<input
																	type='number'
																	style={{ width: '200px' }}
																	placeholder={
																		'Enter Quantity for ' + rackData?.name
																	}
																	className='racks_input'
																	onChange={(e) =>
																		fn_handleRacksData(e, rackData)
																	}
																/>
															</div>
														))}
													</div>
													<div class='modal-footer'>
														<button
															type='button'
															class='btn btn-secondary'
															data-bs-dismiss='modal'
														>
															Close
														</button>
														<button
															type='submit'
															onClick={(e) => {
																e.preventDefault();
																addTransferIn(e.target);
															}}
															class='btn btn-success'
															data-bs-dismiss='modal'
															style={{
																backgroundColor: '#ffd700',
																color: 'black',
																border: 'none',
															}}
														>
															Stock In
														</button>
													</div>
												</from>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box  border'>
									<div className='products-actions d-flex p-4'>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRight2'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage E-Stock</h4>
																<p>
																	Update E-Stock info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}

														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			sendData(e.target);
																		}}
																	>
																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='warehouseName'
																					className='form-control-input'
																					placeholder='WareHouse Name'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Width (ft)
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='width'
																					className='form-control-input'
																					placeholder='Warehouse width'
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Length (ft)
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='height'
																					className='form-control-input'
																					placeholder='Warehouse Height'
																				/>
																			</div>
																		</div>
																		{/* <div className="row pt-4  align-items-center">
                                      <div className="col-md-4">
                                        <label
                                          for="inputPassword6"
                                          className="form-label"
                                        >
                                          Stock Capacity
                                        </label>
                                      </div>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          name="stockCapacity"
                                          className="form-control-input"
                                          placeholder="Stock Capacity"
                                        />
                                      </div>
                                    </div> */}
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Address
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='address'
																					className='form-control-input'
																					placeholder='Address'
																				/>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					name='status'
																				>
																					<option value='Active'>Active</option>
																					<option value='Inactive'>
																						Inactive
																					</option>
																				</select>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
														{/* Canvas */}
													</div>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRightupdate12'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage E-Stock </h4>
																<p>
																	Update E-Stock info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}

														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			updateData(e.target);
																		}}
																	>
																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Warehouse Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='warehouseName'
																					className='form-control-input'
																					placeholder='WareHouse Name'
																					defaultValue={
																						singleStock?.warehouseName
																					}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					WareHouse Weight
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='width'
																					className='form-control-input'
																					placeholder='WareHouse Weight'
																					defaultValue={singleStock?.width}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					WareHouse Height
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='height'
																					className='form-control-input'
																					placeholder='WareHouse Height'
																					defaultValue={singleStock?.height}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Stock Capacity
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='stockCapacity'
																					className='form-control-input'
																					placeholder='Stock Capacity'
																					defaultValue={
																						singleStock?.stockCapacity
																					}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Address
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='address'
																					className='form-control-input'
																					placeholder='Address'
																					defaultValue={singleStock?.address}
																				/>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					name='status'
																				>
																					<option value='Active'>Active</option>
																					<option value='Inactive'>
																						Inactive
																					</option>
																				</select>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																							style={{
																								backgroundColor: '#ffd700',
																								color: 'black',
																								border: 'none',
																							}}
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
														{/* Canvas */}
													</div>
												</div>

												{/* Modal-Del */}
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight2'
													aria-controls='offcanvasRight2'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Stock</span>
												</button>

												<div
													className='offcanvas offcanvas-end w-75'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4> Add Stock</h4>
															<p>Add Stock info, combinations and extras.</p>
														</div>

														<button
															type='button'
															className='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														/>
													</div>
													{/* Canvas */}

													{/* Canvas */}
												</div>
												<button
													type='button'
													class='btn btn-success ms-3 '
													data-bs-toggle='modal'
													data-bs-target='#exampleModal3'
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
														border: 'none',
													}}
												>
													Product Transfer to E-Display
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle'
											style={{ borderColor: '#4f4f51' }}
										>
											<thead className='bg-light'>
												<tr>
													<th>#Id</th>
													<th>WareHouse Name</th>
													<th>WareHouse size(ft)</th>
													<th>Location</th>
													<th>Status</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{stocks?.map((item, index) => {
													return (
														<>
															<tr>
																<td>{index + 1}</td>
																<td>{item?.warehouseName}</td>
																<td>{`${item?.height}  x ${item?.width}`}</td>
																<td>{item?.location}</td>
																<td>
																	<span
																		className='text-ligh  '
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																		}}
																	>
																		active
																	</span>
																</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				// navigate(`/seller_details/${id}/${item?._id}`);
																				setSellerID(item?._id);

																				axios
																					.get(
																						`${ApiUrl}/e-stock/getById?id=` +
																							item?._id
																					)
																					.then((res) => {
																						console.log(
																							res.data,
																							'singleStock'
																						);

																						setSingleStock(res.data);
																					});
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate12'
																			aria-controls='offcanvasRightupdate12'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>

																		<span
																			onClick={() => {
																				onDeleteStudent(item?._id);
																			}}
																			style={{ fontSize: 21 }}
																			className='px-3'
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
										<div className='data-pagination px-4 d-flex'>
											<p>SHOWING 41-60 OF 308</p>

											<nav aria-label='Page navigation example'>
												<ul className='pagination'>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Previous'
														>
															<span aria-hidden='true'>«</span>
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															1
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															2
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															3
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															4
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															5
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
														>
															6
														</a>
													</li>
													<li className='page-item'>
														<a
															className='page-link'
															href='#'
															aria-label='Next'
														>
															<span aria-hidden='true'>»</span>
														</a>
													</li>
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default EStock;
