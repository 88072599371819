import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Radio } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Modal } from 'antd';
import { AiOutlineZoomIn } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { useNavigate, useParams } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';

function EDisplay() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [rackId, setRackId] = useState(null);
	const [fracks, setFRacks] = useState([]);
	const [fdisplays, setFDisplays] = useState([]);
	const [racks, setRacks] = useState([]);
	const [displays, setDisplays] = useState([]);
	const [mainStockRacks, setMainStockRacks] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [stocks, setStocks] = useState([]);
	const [rId, setRId] = useState([]);
	const [racksDetail, setRacksDetail] = useState([]);
	const [productState, setProductState] = useState('');
	const [productTotalQuantity, setProductTotalQuantity] = useState();
	const [productId, setProductID] = useState('');
	const [stores, setStores] = useState([]);
	const [storeId, setStoreId] = useState([]);
	const [stockLocation, setStockLocation] = useState('');
	const [selectStoreRack, setSelectStoreRack] = useState([]);
	const [ambName, setAmbName] = useState('');
	const [sellerName, setSellerName] = useState('');
	const [storeName, setStoreName] = useState('');
	const [selectedRadios, setSelectedRadios] = useState([]);

	useEffect(() => {
		axios.get(`${ApiUrl}/e-stock/getByStoreID?id=` + id).then((res) => {
			setStocks(res.data);
		});

		axios.get(`${ApiUrl}/e-displayRack/getByStoreID?id=` + id).then((res) => {
			setRacks(res.data);
			setDisplays(res.data);
		});

		axios
			.get(
				`${ApiUrl}/mainStockRack/getBySellerID?id=` +
					localStorage.getItem('sellerId')
			)
			.then((res) => {
				setMainStockRacks(
					res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) }))
				);
				setProductTotalQuantity(
					res?.data
						.filter((rack) => rack?.product === productId)
						.reduce((acc, i) => {
							return acc + parseInt(i?.productQty);
						}, 0)
				);
			});

		axios
			.get(
				`${ApiUrl}/store/getBySellerID?id=` + localStorage.getItem('sellerId')
			)
			.then((res) => {
				setStores(res.data);
			});

		axios
			.get(
				`${ApiUrl}/embassador/getById?id=` +
					localStorage.getItem('embassadorId')
			)
			.then((res) => {
				setAmbName(res?.data?.name);
			});

		axios
			.get(`${ApiUrl}/seller/getById?id=` + localStorage.getItem('sellerId'))
			.then((res) => {
				setSellerName(res?.data?.name);
			});

		axios.get(`${ApiUrl}/store/getById?id=` + id).then((res) => {
			setStoreName(res?.data?.name);
		});
	}, []);

	useEffect(() => {
		axios.get(`${ApiUrl}/e-stock/getByStoreID?id=${storeId}`).then((res) => {
			setStockLocation(res?.data[0]?.location);
		});
		axios.get(`${ApiUrl}/e-rack/getByStoreID?id=${storeId}`).then((res) => {
			const a = res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) }));
			console.log(
				'==========hello',
				res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) }))
			);
			setSelectStoreRack(a);
		});
	}, [storeId]);

	const [location, setLocation] = useState(null);
	const [weather, setWeather] = useState(null);

	function success(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setLocation({ latitude, longitude });
		// console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=db0e108a510e5ec03389a47fdbeefddf&units=metric`
		)
			.then((response) => response.json())
			.then((data) => {
				setWeather(data);
				// console.log(data);
			})
			.catch((error) => console.log(error));
	}
	function error() {
		console.log('Unable to retrieve your location');
	}

	const handleCreateRack = (values) => {
		if (values?.rackQuantity?.value?.length === 0) {
			toast.error('Enter Rack Quantity');
		} else if (values?.frontSize?.value?.length === 0) {
			toast.error('Enter Rack Front Size');
		} else if (values?.frontUnit?.value?.length === 0) {
			toast.error('Enter Rack Front Size Unit');
		} else if (values?.backSize?.value?.length === 0) {
			toast.error('Enter Rack Back Size');
		} else if (values?.backUnit?.value?.length === 0) {
			toast.error('Enter Rack Back Size Unit');
		} else if (values?.heightSize?.value?.length === 0) {
			toast.error('Enter Rack Height');
		} else if (values?.heightUnit?.value?.length === 0) {
			toast.error('Enter Rack Height Unit');
		} else {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(success, error);
			} else {
				console.log('Geolocation not supported');
			}
			const params = {
				storeID: id,
				rackcouts: values?.rackQuantity?.value,
				front: values?.frontSize?.value + values?.frontUnit?.value,
				back: values?.backSize?.value + values?.backUnit?.value,
				height: values?.heightSize?.value + values?.heightUnit?.value,
				rackType: values?.rackType?.value,
				unit: values?.frontUnit?.value,
				location: weather?.name,
				hasDimensions: true,
			};
			if (weather?.name === undefined) {
				return toast.error('Can not access your Location');
			}
			console.log(params);
			axios
				.post(`${ApiUrl}/e-rack/create`, params)
				.then((res) => {
					console.log(res?.data);
					if (res?.data?.status === 'success') {
						toast?.success('Racks Created Successfully');
						axios.get(`${ApiUrl}/e-rack/getByStoreID?id=` + id).then((res) => {
							setRacks(
								res?.data?.map((obj, i) => ({
									...obj,
									name: 'Ei' + (i + 1),
								}))
							);
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
	useEffect(() => {
		setFRacks(displays?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) })));
		setFDisplays(displays?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) })));
	}, [displays]);

	const ownedDisplays = displays?.filter((own) => own?.type === 'owned');
	const myRentalDisplays = displays?.filter((own) => own?.type === 'myrental');

	const filteredData = fdisplays?.filter((item) => {
		return item?.name?.includes(searchTerm);
	});

	const sendData = (values) => {
		if (values?.rackcounts?.value?.length === 0) {
			toast.error('Please Enter Display to Create');
		} else if (values?.displayType?.value?.length === 0) {
			toast.error('Please Select Display Type');
		} else if (
			values?.frontUnit?.value?.length === 0 ||
			values?.backUnit?.value?.length === 0 ||
			values?.heightUnit?.value?.length === 0
		) {
			toast.error('Please Select Unit');
		} else if (
			values?.front?.value?.length === 0 ||
			values?.back?.value?.length === 0 ||
			values?.height?.value?.length === 0
		) {
			toast.error('Please define Rack Size');
		} else {
			const params = {
				storeID: id,
				rackcounts: values.rackcounts.value,
				displayType: values.displayType.value,
				front: values.front.value + values.frontUnit.value,
				back: values.back.value + values.backUnit.value,
				height: values.height.value + values.heightUnit.value,
			};
			axios.post(`${ApiUrl}/e-displayRack/create`, params).then((res) => {
				if (res.data.status === 'success') {
					toast.success('E Display created successfully!');
					axios
						.get(`${ApiUrl}/e-displayRack/getByStoreID?id=` + id)
						.then((res) => {
							setDisplays(
								res?.data?.map((obj, i) => ({ ...obj, name: 'Ei' + (i + 1) }))
							);
						});
				}
			});
		}
	};

	const handleSelectRack = (e, rack) => {
		if (e?.target?.checked) {
			setRId((prev) => [
				...prev,
				{ rackId: rack._id, name: rack.name, qty: 0 },
			]);
		} else if (e?.target?.checked === false) {
			setRId(rId.filter((rack) => rack !== e?.target?.value));
		}
	};

	const handleChange = (e, option) => {
		if (e?.target.checked === true) {
			selectedRadios.push(e?.target?.value);
			setRId((prev) => [
				...prev,
				{
					rackId: option._id,
					name: option.name,
					price: 0,
					front: option?.front,
					back: option?.back,
					height: option?.height,
					rackType: option?.rackType,
				},
			]);
		} else if (e?.target?.checked === false) {
			setSelectedRadios(
				selectedRadios.filter((rack) => rack !== e?.target?.value)
			);
			const filteredId = rId.filter(({ Id }) => Id !== option._id);
			setRId(filteredId);
		}
	};

	function fn_handleRacksData(e, rack) {
		const qty = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].qty = qty;
		setRacksDetail(tempRacks);
	}
	function fn_handleRacksData2(e, rack) {
		const price = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.Id === rack.Id);
		tempRacks[itemIndex].price = price;
		setRacksDetail(tempRacks);
	}

	const addTransferIn = (values) => {
		const params = {
			productID: productId,
			sellerID: localStorage.getItem('sellerId'),
			storeID: id,
			racksDetail: racksDetail,
			remaining: racksDetail.reduce((acc, i) => {
				return acc + parseInt(i?.qty);
			}, 0),
		};
		console?.log(params);
		axios
			.patch(`${ApiUrl}/mainStockRack/transferFromStore`, params)
			.then((res) => {
				if (res?.data?.status) {
					toast.success('Product Transfer to Main Stock Successfully');
				}
			});
	};

	const handleStoreTransfer = (values) => {
		try {
			const params = {
				productID: productId,
				fromStoreId: id,
				toStoreId: values?.storeID?.value,
				racksDetail: racksDetail,
				remaining: racksDetail.reduce((acc, i) => {
					return acc + parseInt(i?.qty);
				}, 0),
			};
			console.log(params);
			axios
				.patch(`${ApiUrl}/e-stock/transferStoreToStore`, params)
				.then((res) => {
					if (res?.data?.status) {
						toast.success('Product Transfer to Store Sucessfully');
					}
				})
				.catch((err) => {
					toast.error(err?.response?.data?.error);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const handleRackRent = (values) => {
		try {
			if (rId?.length === 0 || values?.image?.files[0].length === 0) {
				toast.error('Fill all Fields');
			} else {
				const params = new FormData();
				params.append('ambassadorId', localStorage.getItem('embassadorId'));
				params.append('sellerId', localStorage.getItem('sellerId'));
				params.append('ambassadorName', ambName);
				params.append('sellerName', sellerName);
				params.append('storeName', storeName);
				params.append('forSell', true);
				params.append('racksId', JSON.stringify(rId));
				params.append('image', values?.image?.files[0]);
				params.append('modalName', 'displayRack');
				axios
					.post(`${ApiUrl}/sellRacks/sellDisplayRacks`, params)
					.then((res) => {
						if (res.data.status === true) {
							toast.success('Displays Sent for Sell');
							axios
								.get(`${ApiUrl}/e-displayRack/getByStoreID?id=` + id)
								.then((res) => {
									setDisplays(
										res?.data?.map((obj, i) => ({
											...obj,
											name: 'Ei' + (i + 1),
										}))
									);
								});
						}
					});
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						{/********************** Modal E-Display Create **********************/}
						<div
							className='offcanvas offcanvas-end'
							tabIndex={-1}
							id='offcanvasRightd'
							aria-labelledby='offcanvasRightLabel'
						>
							<div className='offcanvas-header px-4'>
								<div className='content-header'>
									<h4>Manage E-Display</h4>
									<p>Update E-Display info, combinations and extras.</p>
								</div>

								<button
									type='button'
									className='btn-close bg-white shadow rounded-5 text-reset'
									data-bs-dismiss='offcanvas'
									aria-label='Close'
									style={{
										width: '5px',
										height: '30px',
										opacity: '0.8',
										fontSize: '12px',
									}}
								/>
							</div>

							<div className='offcanvas-body p-0'>
								<div className='form-data'>
									<div className='wrap-data p-5'>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												sendData(e.target);
											}}
										>
											<div className='row  pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Store Name
													</label>
												</div>
												<div className='col-md-8'>
													<input
														type='text'
														id='inputPassword6'
														className='form-control-input'
														placeholder='Store Name'
														value={stocks[0]?.warehouseName}
														disabled
													/>
												</div>
											</div>
											<div className='row pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Select Number of Display
													</label>
												</div>
												<div className='col-md-8'>
													<input
														type='number'
														name='rackcounts'
														class='form-control-input'
													/>
												</div>
											</div>

											<div className='row pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Display Type
													</label>
												</div>
												<div className='col-md-8'>
													<Radio.Group
														defaultValue={''}
														buttonStyle='solid'
														className='mx-1 mt-1 mb-2'
														name='displayType'
													>
														<Radio.Button
															value='Floor Display'
															style={{
																height: '2.75rem',
																paddingTop: '0.4rem',
															}}
														>
															Floor Display
														</Radio.Button>
														<Radio.Button
															value='Hanger Display'
															style={{
																height: '2.75rem',
																paddingTop: '0.4rem',
															}}
														>
															Hanger Display
														</Radio.Button>
														<Radio.Button
															value='Shelf Display'
															style={{
																height: '2.75rem',
																paddingTop: '0.4rem',
															}}
														>
															Shelf Display
														</Radio.Button>
													</Radio.Group>
												</div>
											</div>

											<div className='row  pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Front
													</label>
												</div>
												<div className='col-md-8 d-flex'>
													<div>
														<Radio.Group
															defaultValue={''}
															buttonStyle='solid'
															className='mx-1 mt-1 mb-2'
															name='frontUnit'
														>
															<Radio.Button
																value='ft'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Feet
															</Radio.Button>
															<Radio.Button
																value='inch'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Inch
															</Radio.Button>
															<Radio.Button
																value='meter'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Meter
															</Radio.Button>
														</Radio.Group>
													</div>
													<div>
														<input
															type='number'
															name='front'
															class='form-control-input'
															style={{
																height: '2.8rem',
																marginTop: '0.2rem',
															}}
														/>
													</div>
												</div>
											</div>

											<div className='row  pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Back
													</label>
												</div>
												<div className='col-md-8 d-flex'>
													<div>
														<Radio.Group
															defaultValue={''}
															buttonStyle='solid'
															className='mx-1 mt-1 mb-2'
															name='backUnit'
														>
															<Radio.Button
																value='ft'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Feet
															</Radio.Button>
															<Radio.Button
																value='inch'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Inch
															</Radio.Button>
															<Radio.Button
																value='meter'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Meter
															</Radio.Button>
														</Radio.Group>
													</div>
													<div>
														<input
															type='number'
															name='back'
															class='form-control-input'
															style={{
																height: '2.8rem',
																marginTop: '0.2rem',
															}}
														/>
													</div>
												</div>
											</div>

											<div className='row  pt-4  align-items-center'>
												<div className='col-md-4'>
													<label
														for='inputPassword6'
														className='form-label'
													>
														Height
													</label>
												</div>
												<div className='col-md-8 d-flex'>
													<div>
														<Radio.Group
															defaultValue={''}
															buttonStyle='solid'
															className='mx-1 mt-1 mb-2'
															name='heightUnit'
														>
															<Radio.Button
																value='ft'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Feet
															</Radio.Button>
															<Radio.Button
																value='inch'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Inch
															</Radio.Button>
															<Radio.Button
																value='meter'
																style={{
																	height: '2.75rem',
																	paddingTop: '0.4rem',
																}}
															>
																Meter
															</Radio.Button>
														</Radio.Group>
													</div>
													<div>
														<input
															type='number'
															name='height'
															class='form-control-input'
															style={{
																height: '2.8rem',
																marginTop: '0.2rem',
															}}
														/>
													</div>
												</div>
											</div>

											<div className='offcanvas-footer px-5 py-3'>
												<div className='content-footer'>
													<div className='row'>
														<div className='col-lg-6 col-md-12 py-3'>
															<button
																type='button'
																className='cancel-btn btn-lg'
															>
																Cancel
															</button>
														</div>
														<div className='col-lg-6 col-md-12 py-3'>
															<button
																type='submit'
																className='add-last-btn btn-lg'
															>
																Create Display
															</button>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/****************** Transfer for Rent Start ******************/}
						<div
							class='modal fade'
							id='transferForRentDisplay'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Racks Transfer for Rent
										</h5>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												handleRackRent(e?.target);
											}}
										>
											<div className='col-md-12 text-start mb-2'>
												<div>
													<label
														className='mb-2'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Select Rack for Rent
													</label>
													<div>
														{fdisplays &&
															fdisplays?.map((item) => {
																if (item?.type === 'owned') {
																	return (
																		<>
																			<input
																				type='checkbox'
																				disabled={
																					item?.state === 'occupied' && true
																				}
																				onChange={(e) => handleChange(e, item)}
																			/>
																			<b className='mx-1'>{item?.name}</b>
																			<span style={{ fontSize: '12px' }}>
																				(F : {item?.front}, B : {item?.back}, H
																				: {item?.height})
																			</span>
																			<span
																				style={{ fontSize: '14px' }}
																				className='mx-1'
																			>
																				[{item?.displayType}]
																			</span>
																			<br />
																		</>
																	);
																}
															})}
													</div>
												</div>
												<div className='row'>
													{rId.length > 0 && (
														<label
															className='mt-2 col-12'
															style={{ fontSize: '1.1rem', fontWeight: '500' }}
														>
															Enter Display Rent price per Month
														</label>
													)}
													{rId?.map((rackData) => (
														<div className='col-6 mt-2 mb-3'>
															<input
																type='number'
																style={{ width: '200px' }}
																placeholder={
																	'Enter Price for ' + rackData?.name
																}
																className='racks_input'
																onChange={(e) =>
																	fn_handleRacksData2(e, rackData)
																}
															/>
														</div>
													))}
												</div>
												<div>
													<label
														className='my-1'
														style={{ fontSize: '1.1rem', fontWeight: '500' }}
													>
														Display Image
													</label>
													<input
														type='file'
														id='image'
														name='image'
														className='form-control'
														placeholder='Paste Image'
													/>
												</div>
											</div>
											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
													style={{
														backgroundColor: '#ffd700',
														color: 'black',
														border: 'none',
													}}
												>
													Save Changes
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between'>
									<h5 className='kk ps-3'>Manage E-Display</h5>
									<div>
										<button
											className='btn btn-success'
											data-bs-toggle='modal'
											data-bs-target='#transferForRentDisplay'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Transfer for Rent
										</button>
										<button
											className='btn btn-success ms-2'
											type='button'
											data-bs-toggle='offcanvas'
											data-bs-target='#offcanvasRightd'
											aria-controls='offcanvasRightd'
											style={{
												backgroundColor: '#ffd700',
												color: 'black',
												border: 'none',
											}}
										>
											Create Display
										</button>
									</div>
								</div>

								<div className='all_categories'>
									<ul
										class='nav nav-pills mb-3'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='ownedDisplay-tab'
												data-bs-toggle='pill'
												data-bs-target='#ownedDisplay'
												type='button'
												role='tab'
												aria-controls='ownedDisplay'
												aria-selected='true'
											>
												Owned
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link '
												id='myRentalDisplay-tab'
												data-bs-toggle='pill'
												data-bs-target='#myRentalDisplay'
												type='button'
												role='tab'
												aria-controls='myRentalDisplay'
												aria-selected='false'
											>
												My Rental
											</button>
										</li>
									</ul>

									<div
										class='tab-content'
										id='pills-tabContent'
									>
										{/********************************** Owned Displat Data */}
										<div
											class='tab-pane fade show active'
											id='ownedDisplay'
											role='tabpanel'
											aria-labelledby='ownedDisplaytab'
										>
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {ownedDisplays?.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Display'
													/>
												</div>
											</div>
											<div className='row g-4'>
												{filteredData?.map((item) => {
													if (item?.type === 'owned') {
														if (!item?.forSell) {
															return (
																<div className='col-md-3'>
																	<div
																		className='p-2'
																		style={{
																			backgroundColor: '#fff',
																			border: ' 1px solid rgb(243, 239, 239)',
																			borderRadius: '10px',
																		}}
																	>
																		<div
																			className='order'
																			style={{
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'space-between',
																			}}
																		>
																			<div>
																				<h4>{item?.name}</h4>
																			</div>
																			<div style={{ zIndex: '999' }}>
																				{item?.hasDimensions && (
																					<span
																						style={{
																							fontSize: '0.95rem',
																							backgroundColor:
																								'rgb(220 231 244)',
																							padding: '0.2rem 0.08rem',
																							borderRadius: '0.1rem',
																							textAlign: 'center',
																						}}
																					>
																						front:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.front}
																						</span>
																						, back:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.back}
																						</span>
																						, height:{' '}
																						<span style={{ fontWeight: '600' }}>
																							{item?.height}
																						</span>
																					</span>
																				)}
																			</div>
																		</div>

																		<div className='d-flex justify-content-between pt-3'>
																			<p>
																				{item?.state === 'empty' ? (
																					<span
																						style={{
																							textTransform: 'capitalize',
																						}}
																					>
																						{item?.state}
																					</span>
																				) : (
																					<>
																						Product : <b>{item?.productName}</b>
																						<br />
																						Qty :{' '}
																						<b>
																							{item?.productQty}{' '}
																							{item?.productUnit}
																						</b>
																					</>
																				)}
																			</p>
																			{item?.state === 'empty' ? (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<AiFillCloseCircle
																						size={20}
																						color='red'
																					/>
																				</div>
																			) : (
																				<div
																					className=''
																					style={{
																						background: '#FEECDC',
																						width: '30px',
																						height: '30px',
																						lineHeight: '30px',
																						borderRadius: '50%',
																						justifyContent: 'center',
																						alignItems: 'center',
																						display: 'flex',
																					}}
																				>
																					<i
																						class='fa-solid fa-check'
																						style={{
																							fontSize: '18px',
																							color: ' #ffd700',
																						}}
																					></i>
																				</div>
																			)}
																		</div>
																		<hr />
																		{item?.hasDimensions && (
																			<div className='mb-2'>
																				<span>
																					Type : <b>{item?.displayType}</b>
																				</span>
																			</div>
																		)}
																		<div>
																			<button
																				onClick={() =>
																					navigate(
																						`/viewRackProduct/${item?._id}`
																					)
																				}
																				class='btn w-100 text-right btn-success'
																			>
																				View Display
																			</button>
																		</div>
																	</div>
																</div>
															);
														}
													}
												})}
											</div>
										</div>
										{/********************************** My Rental Displat Data */}
										<div
											class='tab-pane fade '
											id='myRentalDisplay'
											role='tabpanel'
											aria-labelledby='myRentalDisplay-tab'
										>
											<div className='d-flex justify-content-between'>
												<h3 className='py-4'>
													Total Number of Racks: {myRentalDisplays?.length}
												</h3>
												<div
													className='d-flex'
													style={{ height: '3rem' }}
												>
													<input
														type='text'
														className='form-control'
														value={searchTerm}
														onChange={(e) => setSearchTerm(e.target.value)}
														placeholder='Search Rack'
													/>
												</div>
											</div>
											<div className='row g-4'>
												{filteredData &&
													filteredData.map((item) => {
														if (item?.type === 'myrental') {
															if (item?.forSell) {
																return (
																	<div className='col-md-3'>
																		<div
																			className='p-2'
																			style={{
																				backgroundColor: '#fff',
																				border: ' 1px solid rgb(243, 239, 239)',
																				borderRadius: '10px',
																			}}
																		>
																			<div
																				className='order'
																				style={{
																					display: 'flex',
																					flexDirection: 'column',
																					justifyContent: 'space-between',
																				}}
																			>
																				<div>
																					<h4>{item?.name}</h4>
																				</div>
																				<div style={{ zIndex: '999' }}>
																					{item?.hasDimensions && (
																						<span
																							style={{
																								fontSize: '0.95rem',
																								backgroundColor:
																									'rgb(220 231 244)',
																								padding: '0.2rem 0.08rem',
																								borderRadius: '0.1rem',
																								textAlign: 'center',
																							}}
																						>
																							front:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.front}
																							</span>
																							, back:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.back}
																							</span>
																							, height:{' '}
																							<span
																								style={{ fontWeight: '600' }}
																							>
																								{item?.height}
																							</span>
																						</span>
																					)}
																				</div>
																			</div>

																			<div className='d-flex justify-content-between pt-3'>
																				<p>
																					{item?.state === 'empty' ? (
																						<span
																							style={{
																								textTransform: 'capitalize',
																							}}
																						>
																							{item?.state}
																						</span>
																					) : (
																						<>
																							Product :{' '}
																							<b>{item?.productName}</b>
																							<br />
																							Qty :{' '}
																							<b>
																								{item?.productQty}{' '}
																								{item?.productUnit}
																							</b>
																						</>
																					)}
																				</p>
																				{item?.state === 'empty' ? (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<AiFillCloseCircle
																							size={20}
																							color='red'
																						/>
																					</div>
																				) : (
																					<div
																						className=''
																						style={{
																							background: '#FEECDC',
																							width: '30px',
																							height: '30px',
																							lineHeight: '30px',
																							borderRadius: '50%',
																							justifyContent: 'center',
																							alignItems: 'center',
																							display: 'flex',
																						}}
																					>
																						<i
																							class='fa-solid fa-check'
																							style={{
																								fontSize: '18px',
																								color: ' #ffd700',
																							}}
																						></i>
																					</div>
																				)}
																			</div>
																			<hr />
																			{item?.hasDimensions && (
																				<div className='mb-2'>
																					<span>
																						Type : <b>{item?.displayType}</b>
																					</span>
																				</div>
																			)}
																			<div>
																				<button
																					onClick={() =>
																						navigate(
																							`/viewRackProduct/${item?._id}`
																						)
																					}
																					class='btn w-100 text-right btn-success'
																				>
																					View Display
																				</button>
																			</div>
																		</div>
																	</div>
																);
															}
														}
													})}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default EDisplay;
