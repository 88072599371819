import React, { useState, useEffect } from 'react';
import ProductInfo from './ProductInfo';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Countries, Cities } from 'countries-states-cities-service';
import { Radio } from 'antd';
import { Modal as Modals } from 'antd';
import DetailSlider from './DetailSlider';
import { AiFillEye } from 'react-icons/ai';
import ProductFilter from './ProductFilter';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';
import {
	getCategory,
	getRecCategory,
	getsubCategory,
	getRecsubCategory,
	addRecProduct,
	getRecProduct,
	delRecProduct,
	addProduct,
	getProduct,
	delProduct,
} from '../ApiEndpoint';

function Product() {
	const navigate = useNavigate();
	const [checkValue, setCheckValue] = useState(false);
	const countries = Countries?.getCountries();
	const [stockName, setStockName] = useState('');
	const [stockQuantity, setStockQuantity] = useState('');
	const [countryFilter, setCountryFilter] = useState();
	const [localInput, setLocalInput] = useState('off');
	const [nationalInput, setNationalInput] = useState('off');
	const [interNationalInput, setinterNationalInput] = useState('off');
	const [localCheckBox, setLocalCheckBox] = useState('');
	const [nationalCheckBox, setNationalCheckBox] = useState('');
	const [interNationalCheckBox, setInterNationalCheckBox] = useState('');
	const [localCharges, setLocalCharges] = useState('');
	const [nationalCharges, setNationalCharges] = useState('');
	const [interNationalCharges, setInterNationalCharges] = useState('');
	const [show, setShow] = useState(false);
	const [showRecycle, setShowRecycle] = useState(false);
	const [singleProduct, setSingleProduct] = useState();
	const [singleRecycleProduct, setSingleRecycleProduct] = useState();
	const [data, setData] = useState('');
	const [rackId, setRacksId] = useState([]);
	const [dileveryArray, setDileveryArray] = useState([]);
	const [dileveryArray2, setDileveryArray2] = useState([]);
	const [recyclingSingle, setRecyclingSingle] = useState({});
	const [stockProduct, setStockProduct] = useState('');
	const [categories, setCategories] = useState([]);
	const [getcategories, setGetCategories] = useState(null);
	const [subCategory, setSubCategory] = useState([]);
	const [allproduct, setAllProduct] = useState([]);
	const [allrecycling, setAllRecycling] = useState([]);
	const [allMainStockRack, setAllMainStockRack] = useState([]);
	const [allMainStockRackLength, setAllMainStockRackLength] = useState([]);
	const [mainStockEmptyRack, setmainStockEmptyRack] = useState([]);
	const [sellingProduct, setSellingProduct] = useState();
	const className = 'offcanvas offcanvas-end';
	const changeClassName = 'offcanvas offcanvas-end fade';
	const [sellingTypeUpdate, setSellingTypeUpdate] = useState('');
	const [sellingProductNameUpdate, setSellingProductNameUpdate] = useState('');
	const [sellingFoodType, setSellingFoodType] = useState('');
	const [sellingProductCategory, setSellingProductCategory] = useState('');
	const [sellingProductSubCategory, setSellingProductSubCategory] =
		useState('');
	const [sellingCountryUpdate, setSellingCountryUpdate] = useState('');
	const [sellingOwnerType, setSellingOwnerType] = useState('');
	const [sellingCode, setSellingCode] = useState('');
	const [sellingProductPrice, setSellingProductPrice] = useState('');
	const [adminCom, setAdminCom] = useState('');
	const [ambassadorCom, setAmbassadorCom] = useState('');
	const [subAmbassadorCom, setSubAmbassadorCom] = useState('');
	useEffect(() => {
		setSellingTypeUpdate(sellingProduct?.commerceType);
		setSellingProductNameUpdate(sellingProduct?.name);
		setSellingFoodType(sellingProduct?.foodCategory);
		setSellingProductCategory(sellingProduct?.categoryID);
		setSellingProductSubCategory(sellingProduct?.subCategoryID);
		setSellingCountryUpdate(sellingProduct?.country);
		setSellingOwnerType(sellingProduct?.ownerType);
		setSellingCode(sellingProduct?.code);
		setSellingProductPrice(sellingProduct?.productPrice);
		setAmbassadorCom(sellingProduct?.ambassadorComission);
	}, [sellingProduct]);
	// console.log(
	// 	sellingTypeUpdate,
	// 	sellingProductNameUpdate,
	// 	sellingFoodType,
	// 	sellingProductCategory,
	// 	sellingProductSubCategory,
	// 	sellingCountryUpdate,
	// 	sellingOwnerType,
	// 	sellingCode,
	// 	sellingProductPrice,
	// 	adminCom,
	// 	ambassadorCom
	// );

	// to show in the foarm

	const [recyclingCategoryType, setrecyclingCategoryType] = useState([]);
	const [recyclingSubCategoryType, setrecyclingSubCategoryType] = useState([]);
	const [sellingCategoryType, setsellingCategoryType] = useState([]);
	const [sellingSubCategoryType, setsellingSubCategoryType] = useState([]);
	const [recyclingProductData, setRecyclingProductData] = useState([]);
	const [sellingProductData, setSellingProductData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const result = await fetch(getCategory);
			const catdata = await result.json();
			console.log('Category  ', catdata);
			setsellingCategoryType(catdata);
		};

		const fetchRecData = async () => {
			const result = await fetch(getRecCategory);
			const catdata = await result.json();
			console.log('recycling Category ', catdata);
			setrecyclingCategoryType(catdata);
		};

		const fetchsubData = async () => {
			const result = await fetch(getsubCategory);
			const catdata = await result.json();
			console.log('Product Category for sub ', catdata);
			setsellingSubCategoryType(catdata);
		};

		const fetchRecsubData = async () => {
			const result = await fetch(getRecsubCategory);
			const catdata = await result.json();
			console.log('Product recCategory for sub ', catdata);
			setrecyclingSubCategoryType(catdata);
		};

		const fetchrecProductData = async () => {
			const result = await fetch(getRecProduct);
			const prodata = await result.json();
			console.log('rec Product Data ', prodata);
			setRecyclingProductData(prodata);
		};

		const fetchProductData = async () => {
			const result = await fetch(getProduct);
			const prodata = await result.json();
			console.log('Product Data ', prodata);
			setSellingProductData(prodata);
		};

		Promise.all([
			fetchData(),
			fetchRecData(),
			fetchsubData(),
			fetchRecsubData(),
			fetchProductData(),
			fetchrecProductData(),
		]);
	}, []);

	const countryIso = () => {
		let con;
		if (singleProduct) {
			con = countries.filter(
				(country) => country?.iso2 === singleProduct?.country
			);
		}
		return con?.[0].name;
	};
	const singleProductCategory = () => {
		let con;
		if (singleProduct) {
			con = categories.filter(
				(country) => country?._id === singleProduct?.categoryID
			);
		}
		return con?.[0].title;
	};
	const handleSingleProduct = async (id) => {
		try {
			axios.get(`${ApiUrl}/productSelling/getById?id=${id}`).then((res) => {
				setSingleProduct(res?.data);
				setData(res?.data?.multiImages);
			});
		} catch (error) {
			console.log(error);
		}
	};
	const handleSingleRecycleProduct = async (product) => {
		// try {
		// 	axios.get(`${ApiUrl}/recycling/getById?id=${id}`).then((res) => {
		// 		setSingleProduct(res?.data);
		// 		setData(res?.data?.multiImages);
		// 	});
		// } catch (error) {
		// 	console.log(error);
		// }
		console.log('recycle product', product);
		setSingleRecycleProduct(product);
	};
	const handleLocalCheckboxChange = (e) => {
		setLocalCheckBox(e.target.checked ? 'Local' : '');
	};
	const handleNationalCheckboxChange = (e) => {
		setNationalCheckBox(e.target.checked ? 'National' : '');
	};
	const handleInterNationalCheckboxChange = (e) => {
		setInterNationalCheckBox(e.target.checked ? 'International' : '');
	};
	const [saleDay, setSaleDay] = useState({
		y: 0,
		m: 0,
		w: 0,
	});
	const [replaceDay, setReplaceDay] = useState({
		y: 0,
		m: 0,
		w: 0,
	});
	const [productionTime, setProductionTime] = useState({
		d: '',
		w: '',
		m: '',
	});
	const saveData = (e) => {
		const { name, value } = e.target;
		setSaleDay({ ...saleDay, [name]: value });
	};
	const productionData = (e) => {
		const { name, value } = e.target;
		setProductionTime({ ...productionTime, [name]: value });
	};
	const reductionData = (e) => {
		const { name, value } = e.target;
		setReplaceDay({ ...replaceDay, [name]: value });
	};
	const cities = Cities?.getCities({
		filters: {
			country_code: countryFilter,
		},
	});

	const handleDeliveryCheck = (e) => {
		console.log(e.target.checked);
		if (e.target.checked) {
			setDileveryArray([...dileveryArray, { value: e?.target.value }]);
		} else {
			const store = dileveryArray.filter(
				(value) => value.value !== e.target.value
			);
			setDileveryArray(store);
		}
	};
	const handleDeliveryCheck2 = (e) => {
		console.log(e.target.checked);
		if (e.target.checked) {
			setDileveryArray2([...dileveryArray2, { value: e?.target.value }]);
		} else {
			const store = dileveryArray2.filter(
				(value) => value.value !== e.target.value
			);
			setDileveryArray2(store);
		}
	};
	const local = {
		check: localCheckBox,
		cod: localInput,
		charges: localCharges,
	};
	const national = {
		check: nationalCheckBox,
		cod: nationalInput,
		charges: nationalCharges,
	};
	const international = {
		check: interNationalCheckBox,
		cod: interNationalInput,
		charges: interNationalCharges,
	};
	const [seller, setSellers] = useState(null);
	const sellerID = localStorage.getItem('sellerId');
	const ambassadorID = localStorage.getItem('embassadorId');
	const [commissionFilter, setComissionFilter] = useState('');
	const [com, setCom] = useState('');
	const [referralCom, setReferralCom] = useState('');
	const [selectedCat, setSelectedCat] = useState('');
	const [racksDetail, setRacksDetail] = useState([]);
	const HandleCommissionFilterr = async (e) => {
		setComissionFilter(e?.target?.value);
	};
	useEffect(() => {
		const a = categories?.filter((cat) => cat._id === commissionFilter);
		setSelectedCat(a[0]?.title);
	}, [commissionFilter]);
	useEffect(() => {
		axios.get(`${ApiUrl}/category/getAll`).then((res) => {
			setCategories(res.data);
		});

		axios
			.get(`${ApiUrl}/productSelling/getBySellerID?id=` + sellerID)
			.then((res) => {
				setAllProduct(res.data);
			});

		axios
			.get(`${ApiUrl}/mainStockRack/getBySellerID?id=` + sellerID)
			.then((res) => {
				setAllMainStockRack(res.data);
				setAllMainStockRackLength(res.data.length);
			});

		axios
			.get(`${ApiUrl}/recycling/getBySellerID?id=` + sellerID)
			.then((res) => {
				setAllRecycling(res.data);
			});

		axios.get(`${ApiUrl}/seller/getById?id=` + sellerID).then((res) => {
			setSellers(res.data);
		});

		axios.get(`${ApiUrl}/subCategory/getAll`).then((res) => {
			setSubCategory(res.data);
		});
	}, []);
	useEffect(() => {
		setmainStockEmptyRack(
			allMainStockRack.filter((rack) => rack?.state === 'empty').length
		);
	}, [allMainStockRack]);
	useEffect(() => {
		setCom(categories.filter((c) => c._id === commissionFilter));
	}, [commissionFilter]);
	useEffect(() => {
		setAdminCom(com[0]?.adminCommission);
	}, [com]);
	useEffect(() => {
		if (categories.length > 0) {
			const AllCategories = allproduct.map((sub) => {
				const data =
					categories &&
					categories.find((userData) => userData._id === sub.categoryID);

				return data || null;
			});
			setGetCategories(AllCategories);
		}
	}, [categories]);
	const [selectCategory, setSelectCategory] = useState('');
	const [images, setImages] = useState([]);
	const [singleImages, setSingleImages] = useState([]);
	const imageHandler = (e) => {
		setImages([...images, e.target.files[0]]);
	};
	const handleRemoveItem = (id) => {
		setImages(images.filter((item) => item !== id));
	};
	const sendData = async (values) => {
		if (values?.title?.value?.length === 0) {
			toast('please Enter your title');
		} else if (values?.productPrice?.value?.length === 0) {
			toast('please Enter your product Price');
		} else if (values?.toDate?.value?.length === 0) {
			toast('please Enter your To Date');
		} else if (values?.fromDate?.value?.length === 0) {
			toast('please Enter your From Date');
		} else if (values?.rentperDay?.value?.length === 0) {
			toast('please Enter your rent per Day');
		} else if (values?.unit?.value?.length === 0) {
			toast('please Enter your unit');
		} else if (values?.country?.value?.length === 0) {
			toast('please Enter your  country');
		} else if (values?.city?.value?.length === 0) {
			toast('please Enter your  city');
		} else if (values?.productDetail?.value?.length === 0) {
			toast('please Enter your  product Detail');
		} else if (values?.image?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.multiImages?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.status?.value?.length === 0) {
			toast('please Enter your status');
		} else {
			console.log('=======================', sellerID);
			const parms = {
				sellerID: sellerID,
				commerceType: values.sellingType.value,
				name: values.name.value,
				foodCategory: values.foodCategory.value,
				categoryID: values.categoryID.value,
				subCategoryID: values.subCategoryID.value,
				country: values.country.value,
				ownerType: values.ownerType.value,
				code: values.code.value,
				productPrice: changePrice,
				adminComission: adminCom,
				ambassadorComission: values.ambassadorComission.value,
				subAmbassadorComission: values.subAmbassadorComission.value,
				referralComission: values.referralComission.value,
				retailPrice: values.retailPrice.value,
				quantity: values.quantity.value,
				unit: values.unit.value,
				quality: values.quality.value,
				delivery: JSON.stringify([local, national, international]),
				deliveryTime: values.deliveryTime.value,
				// image: singleImages[0],
				// images: images,
				productDetail: values.productDetails.value,
				mdf_date: values.mdfDate.value,
				exp_date: values.expDate.value,
				productProductionionTime: JSON.stringify(productionTime),
			};

			// console.log('following is selling---------------------------', parms);
			const data = await fetch(`${addProduct}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(parms),
			});

			const response = await data.json();

			if (data.ok) {
				console.log('response', response);
				toast.success('Product created successfully!');
			} else {
				toast.error('addition failed');
			}
		}
	};
	const UpdateData = async (values) => {
		if (values?.title?.value?.length === 0) {
			toast('please Enter your title');
		} else if (values?.productPrice?.value?.length === 0) {
			toast('please Enter your product Price');
		} else if (values?.unit?.value?.length === 0) {
			toast('please Enter your unit');
		} else if (values?.youtubeVideoLink?.value?.length === 0) {
			toast('please Enter your youtube Video Link');
		} else if (values?.country?.value?.length === 0) {
			toast('please Enter your  country');
		} else if (values?.city?.value?.length === 0) {
			toast('please Enter your  city');
		} else if (values?.productDetail?.value?.length === 0) {
			toast('please Enter your  product Detail');
		} else if (values?.image?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.multiImages?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (!selectCategory) {
			toast('please Enter your  state');
		} else if (values?.status?.value?.length === 0) {
			toast('please Enter your status');
		} else {
			console.log('=======================', sellerID);
			const params = new FormData();
			params.append('sellerID', sellerID);
			params.append('ambassadorID', ambassadorID);
			params.append('commerceType', values.selectOne.value);
			params.append('name', values.name.value);
			params.append('productPrice', changePrice);
			params.append('retailPrice', values.retailPrice.value);
			// params.append("unit", values.unit.value);
			params.append('deliveryOptions', values.delivery.value);
			params.append('youtubeVideoLink', values.youtubeVideoLink.value);
			params.append('country', values.country.value);
			params.append('city', values.city.value);
			params.append('quality', values.quality.value);
			params.append('productDetail', values.productDetails.value);
			params.append('image', values.image.files[0]);
			for (let i = 0; i < images.length; i++) {
				params.append('multiImages', images[i]);
			}
			params.append('state', selectCategory);
			params.append('categoryID', values.categoryID.value);
			params.append('subCategoryID', values.categoryID.value);
			params.append('ownerType', values.ownerType.value);
			params.append('mdf_date', values.mdfDate.value);
			params.append('exp_date', values.expDate.value);
			params.append('deliveryTime', values.deliveryTime.value);
			params.append('foodCategory', values.foodCategory.value);
			params.append('status', values.status.value);

			axios
				.post(`${ApiUrl}/productSelling/create`, params)

				.then((res) => {
					console.log(res.data);

					if (res.data.status === 'success') {
					}
				});
		}
	};
	const [options, setOptions] = useState('');
	const [changePrice, setChangePrice] = useState('');
	const [adminCommun, setAdminCommun] = useState('');
	const selectElement = document.getElementById('mySelect');
	if (selectElement !== null) {
		selectElement.addEventListener('change', () => {
			const selectedOption = selectElement.value;

			fetch(`${ApiUrl}/category/getById?id=` + selectedOption)
				.then((response) => response.json())
				.then((data) => {
					setOptions(data);
					setAdminCommun(data?.adminCommission);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}
	const RecyclingData = async (values) => {
		if (values?.title?.value?.length === 0) {
			toast('please Enter your title');
		} else if (values?.productPrice?.value?.length === 0) {
			toast('please Enter your product Price');
		} else if (values?.toDate?.value?.length === 0) {
			toast('please Enter your To Date');
		} else if (values?.fromDate?.value?.length === 0) {
			toast('please Enter your From Date');
		} else if (values?.rentperDay?.value?.length === 0) {
			toast('please Enter your rent per Day');
		} else if (values?.unit?.value?.length === 0) {
			toast('please Enter your unit');
		} else if (values?.country?.value?.length === 0) {
			toast('please Enter your  country');
		} else if (values?.city?.value?.length === 0) {
			toast('please Enter your  city');
		} else if (values?.productDetail?.value?.length === 0) {
			toast('please Enter your  product Detail');
		} else if (values?.image?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.multiImages?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.status?.value?.length === 0) {
			toast('please Enter your status');
		} else {
			console.log('=======================', sellerID);
			const parms = {
				sellerID: sellerID,
				subCategoryname: values.subCategoryID.value,
				categoriesname: values.categoryID.value,
				productType: values.foodCategory.value,
				name: values.name.value,
				daySelect: JSON.stringify(saleDay),
				priceReduction: JSON.stringify(replaceDay),
				productPrice: changePrice,
				adminComission: adminCom,
				QualityControllerComission: values.ambassadorComission.value,
				subQualityControllerComission: values.subAmbassadorComission.value,
				referralComission: values.referralComission.value,
				retailPrice: values.retailPrice.value,
				quantity: values.quantity.value,
				code: values.code.value,
				unit: values.unit.value,
				quality: values.quality.value,
				delivery: JSON.stringify([local, national, international]),
				country: values.country.value,
				damagePolicy: values.damagePolicy.value,
				// image: singleImages[0],
				// images: images,
				productDetail: values.productDetails.value,
				productionUnit: JSON.stringify(productionTime),
			};
			// sending response

			const data = await fetch(`${addRecProduct}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(parms),
			});

			const response = await data.json();

			if (data.ok) {
				console.log('response', response);
				toast.success('Recycling Product created successfully!');
			} else {
				toast.error('addition failed');
			}
		}
	};
	const UpdateRecyclingData = async (values) => {
		if (values?.title?.value?.length === 0) {
			toast('please Enter your title');
		} else if (values?.productPrice?.value?.length === 0) {
			toast('please Enter your product Price');
		} else if (values?.unit?.value?.length === 0) {
			toast('please Enter your unit');
		} else if (values?.youtubeVideoLink?.value?.length === 0) {
			toast('please Enter your youtube Video Link');
		} else if (values?.country?.value?.length === 0) {
			toast('please Enter your  country');
		} else if (values?.city?.value?.length === 0) {
			toast('please Enter your  city');
		} else if (values?.productDetail?.value?.length === 0) {
			toast('please Enter your  product Detail');
		} else if (values?.image?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (values?.multiImages?.value?.length === 0) {
			toast('please Enter your  image');
		} else if (!selectCategory) {
			toast('please Enter your  state');
		} else if (values?.status?.value?.length === 0) {
			toast('please Enter your status');
		} else {
			const params = new FormData();
			params.append('sellerID', sellerID);
			params.append('ambassadorID', ambassadorID);
			params.append('title', values.title.value);
			params.append('productPrice', changePrice);
			params.append('retailPrice', values.retailPrice.value);
			params.append('unit', values.unit.value);

			params.append('delivery', values.delivery.value);
			params.append('youtubeVideoLink', values.youtubeVideoLink.value);
			params.append('country', values.country.value);
			params.append('city', values.city.value);
			params.append('productDetails', values.productDetails.value);
			params.append('image', values.image.files[0]);
			for (let i = 0; i < images.length; i++) {
				params.append('multiImages', images[i]);
			}
			params.append('state', selectCategory);
			params.append('categoryID', values.categoryID.value);
			params.append('status', values.status.value);

			axios.post(`${ApiUrl}/productSelling/create`, params).then((res) => {
				if (res.data.status === 'success') {
				}
			});
		}
	};
	const onDeleteStudent = (id) => {
		Modals.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				axios.delete(`${delProduct}/${id}`).then((res) => {
					toast.success('Product deleted successfully!');
				});
			},
		});
	};
	const onDeleteRecycling = (id) => {
		Modals.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delRecProduct}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('product deleted successful');
						toast.success('product deleted  successful');
					} else {
						console.error('deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};
	const [quantity, setQuantity] = useState('');
	const addStockIn = async () => {
		const sumQty = racksDetail.reduce((acc, i) => {
			return acc + parseInt(i?.qty);
		}, 0);
		const params = {
			productID: stockProduct,
			racksDetail: racksDetail,
			sellerID: sellerID,
			productType: 'recycling',
			quantity: quantity,
		};
		if (!quantity.length) {
			return toast.error('Please specify quantity');
		} else if (parseInt(stockQuantity) < parseInt(quantity)) {
			return toast.error('Wrong Transfer Quantity');
		} else if (sumQty > quantity) {
			return toast.error('Product Transfer Quantity Not Set Properly');
		}
		axios
			.patch(`${ApiUrl}/mainStockRack/addProductInMainRacks`, params)
			.then((res) => {
				if (res.data.status === true) {
					toast.success('Product Sent to Main Stock Successfully');
					axios
						.get(`${ApiUrl}/recycling/getBySellerID?id=` + sellerID)
						.then((res) => {
							setAllRecycling(res.data);
						});
				}
			});
	};
	const addStockIn2 = async () => {
		const sumQty = racksDetail.reduce((acc, i) => {
			return acc + parseInt(i?.qty);
		}, 0);
		const params = {
			productID: stockProduct,
			sellerID: sellerID,
			racksDetail: racksDetail,
			productType: 'selling',
			quantity: quantity,
		};
		if (!quantity.length) {
			return toast.error('Please specify Quantity');
		} else if (parseInt(stockQuantity) < parseInt(quantity)) {
			return toast.error('Wrong Transfer Quantity');
		} else if (sumQty > quantity) {
			return toast.error('Product Transfer Quantity Not Set Properly');
		}
		axios
			.patch(`${ApiUrl}/mainStockRack/addProductInMainRacks`, params)
			.then((res) => {
				if (res.data.status === true) {
					toast.success('Product Sent to Main Stock Successfully');
					axios
						.get(`${ApiUrl}/productSelling/getBySellerID?id=` + sellerID)
						.then((res) => {
							setAllProduct(res.data);
						});
				}
			});
	};
	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						{/********************** Selling Product View Modal **********************/}
						<Modal
							show={show}
							onHide={() => setShow(false)}
							dialogClassName='modal-100w modal-lg'
							aria-labelledby='example-custom-modal-styling-title'
						>
							<Modal.Header closeButton>
								<Modal.Title id='example-custom-modal-styling-title'>
									{singleProduct?.name}
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='row g-3'>
									<div className='col-lg-6 col-md-6'>
										<DetailSlider
											data={data}
											onDataChange={handleSingleProduct}
										/>
									</div>
									<div className='col-lg-6 col-md-6'>
										<div className='details __h-100'>
											<div className='mb-3 text-start'>
												<span className='h5 font-weight-normal mr-1 text-success'>
													{singleProductCategory()}
												</span>
											</div>

											<div className='mb-2 text-start'>
												<span className='h5 font-weight-normal mr-1'>
													{singleProduct?.name}
												</span>
											</div>

											<div className='d-flex mb-2'>
												<div>Unit Price :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-2 h6'
													>
														{singleProduct?.productPrice} Rs /{' '}
														{singleProduct?.unit}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Company Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-2 h6'
													>
														{singleProduct?.companyComission}%
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Ambassador Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='h6'
													>
														{singleProduct?.ambassadorComission} %
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Referral Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='h6'
													>
														{singleProduct?.referralComission} %
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Selling Price :</div>
												<div>
													<span
														id='set-tax-amount'
														className='h6'
													>
														{singleProduct?.retailPrice} / {singleProduct?.unit}
													</span>
												</div>
											</div>
											<hr />

											<div className='d-flex mb-2'>
												<div>Product Code :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														{singleProduct?.code}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Product Quality :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														{singleProduct?.quality}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														Made in {countryIso()}
													</span>
												</div>
											</div>
											<hr />

											<div className='text-start mb-2'>
												<div className='ms-1'>
													<h6>Delivery :</h6>
												</div>
												<div>
													{singleProduct?.delivery?.map((delivery) => {
														if (delivery?.check !== '') {
															return (
																<>
																	<span
																		id='set-tax-amount'
																		className='mx-1'
																	>
																		<span className='text-success fw-bold'>
																			{delivery?.check}
																		</span>{' '}
																		<span className='fw-bold'>
																			(COD : {delivery?.cod})
																		</span>{' '}
																		Charges :{' '}
																		<span className='text-success fw-bold'>
																			{delivery?.charges}
																		</span>
																	</span>
																	<br />
																</>
															);
														}
													})}
												</div>
											</div>
											<hr />

											<div className='mb-2 text-start'>
												<div className='h6'>Product Production Time :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1'
													>
														{singleProduct?.productionUnit?.d}{' '}
														{singleProduct?.unit} in One Day
														<br />
														{singleProduct?.productionUnit?.w}{' '}
														{singleProduct?.unit} in One Week
														<br />
														{singleProduct?.productionUnit?.m}{' '}
														{singleProduct?.unit} in One Month
														<br />
													</span>
												</div>
											</div>
											<hr />
											<div className='d-flex ms-auto'>
												<button
													className='btn btn-error'
													onClick={() => setShow(false)}
												>
													Close
												</button>
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
						{/********************** Recycling Product View Modal **********************/}
						<Modal
							show={showRecycle}
							onHide={() => setShowRecycle(false)}
							dialogClassName='modal-100w modal-lg'
							aria-labelledby='example-custom-modal-styling-title'
						>
							{/* {
    "_id": "66026c4c0b435a164f2c956c",
    "category": "scrap",
    "subcategory": "electronics",
    "productType": "Food",
    "productName": "ensrig",
    "ambassadorCommission": 10,
    "subAmbassadorCommission": 5,
    "retailPrice": 1336,
    "productQuantity": 15,
    "hsCode": 852741,
    "unit": "Kilogram",
    "qualityOfProduct": "B Grade",
    "madeInCountry": "AZ",
    "damagePolicy": "jhgfdyhtgrfeytrewyutrewytre",
    "productDetails": "product detailsssssssssssssssssssssssss",
    "image": null,
    "images": [],
    "status": "pending",
    "__v": 0
} */}
							<Modal.Header closeButton>
								<Modal.Title id='example-custom-modal-styling-title'>
									{singleRecycleProduct?.productName} (Recycling)
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='row g-3'>
									<div className='col-lg-6 col-md-6'>
										{/* <DetailSlider
											data={data}
											onDataChange={handleSingleProduct}
										/> */}
									</div>
									<div className='col-lg-6 col-md-6'>
										<div className='details __h-100'>
											<div className='mb-2 text-start'>
												<span className='h5 font-weight-normal mr-1 text-success'>
													{/* {singleProductCategory()} */}
													{singleRecycleProduct?.category} -{' '}
												</span>
											</div>

											<div className='mb-3 text-start'>
												<span className='h5 font-weight-normal mr-1'>
													{singleRecycleProduct?.productName}
												</span>
											</div>

											<div className='d-flex mb-2'>
												<div>Unit Price :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-2 h6'
													>
														{singleRecycleProduct?.retailPrice} Rs /
														{singleRecycleProduct?.unit}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Company Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-2 h6'
													>
														{singleRecycleProduct?.companyComission}%
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Ambassador Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='h6'
													>
														{singleRecycleProduct?.ambassadorCommission} %
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Referral Comission :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-2 h6'
													>
														{singleRecycleProduct?.referralComission} %
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Selling Price :</div>
												<div>
													<span
														id='set-tax-amount'
														className='h6 mx-2'
													>
														{singleRecycleProduct?.retailPrice} /{' '}
														{singleRecycleProduct?.unit}
													</span>
												</div>
											</div>
											<hr />

											<div className='d-flex mb-2'>
												<div>Life Day from Sale Day :</div>
												<div>
													<span
														id='set-tax-amount'
														className=' mx-1 h6'
													>
														{parseInt(singleRecycleProduct?.daySelect?.y) ===
														0 ? null : (
															<>{singleRecycleProduct?.daySelect?.y} Year</>
														)}
														&nbsp;
														{parseInt(singleRecycleProduct?.daySelect?.m) ===
														0 ? null : (
															<>{singleRecycleProduct?.daySelect?.m} Month</>
														)}
														&nbsp;
														{parseInt(singleRecycleProduct?.daySelect?.w) ===
														0 ? null : (
															<>{singleRecycleProduct?.daySelect?.w} Week</>
														)}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Product Code :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														{singleRecycleProduct?.hscode}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>Product Quality :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														{singleRecycleProduct?.quality}
													</span>
												</div>
											</div>

											<div className='d-flex mb-2'>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1 h6'
													>
														Made in {singleRecycleProduct?.madeInCountry}
													</span>
												</div>
											</div>
											<hr />
											<div className='text-start mb-2 ms-1'>
												<div>
													<h6>Price Reduction :</h6>
												</div>
												<div>
													{parseInt(singleRecycleProduct?.priceReduction?.y) !==
													0
														? `Replace in ${singleRecycleProduct?.priceReduction?.y} year (per year reduction is ${singleProduct?.priceReduction?.perYearReduction} %)`
														: null}
												</div>
												<div>
													{parseInt(singleRecycleProduct?.priceReduction?.m) !==
													0
														? `Replace in ${singleRecycleProduct?.priceReduction?.m} month (per month reduction is ${singleProduct?.priceReduction?.perMonthReduction} %)`
														: null}
												</div>
												<div>
													{parseInt(singleRecycleProduct?.priceReduction?.w) !==
													0
														? `Replace in ${singleRecycleProduct?.priceReduction?.w} week (per week reduction is ${singleProduct?.priceReduction?.perWeekReduction} %)`
														: null}
												</div>
											</div>
											<hr />
											<div className='text-start mb-2'>
												<div className='ms-1'>
													<h6>Delivery :</h6>
												</div>
												{/* <div>
													{singleProduct?.delivery?.map((delivery) => {
														if (delivery?.check !== '') {
															return (
																<>
																	<span
																		id='set-tax-amount'
																		className='mx-1'
																	>
																		{delivery?.check} (COD : {delivery?.cod}){' '}
																		{delivery?.cod === 'on' &&
																			`Charges : ${delivery?.charges} Rs`}
																	</span>
																	<br />
																</>
															);
														}
													})}
												</div> */}
											</div>
											<hr />
											<div className='mb-2 text-start'>
												<div className='h6'>Product Production Time :</div>
												<div>
													<span
														id='set-tax-amount'
														className='mx-1'
													>
														{singleRecycleProduct?.productionUnit?.d}{' '}
														{singleRecycleProduct?.unit} in One Day
														<br />
														{singleRecycleProduct?.productionUnit?.w}{' '}
														{singleProduct?.unit} in One Week
														<br />
														{singleRecycleProduct?.productionUnit?.m}{' '}
														{singleRecycleProduct?.unit} in One Month
														<br />
													</span>
												</div>
											</div>
											<hr />
											<div className='d-flex ms-auto my-3'>
												<button
													className='btn btn-success'
													onClick={() => setShowRecycle(false)}
												>
													Close
												</button>
											</div>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>
						{/********************** Recycling Stock In Modal **********************/}
						<div
							class='modal fade'
							id='exampleModal2'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Move to Main Stock
										</h5>

										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<from>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Available Racks</label>
												</div>
												<div className='col-md-8 mb-3 text-start'>
													<span className='fw-bold'>
														{mainStockEmptyRack} / {allMainStockRackLength}
													</span>{' '}
													({mainStockEmptyRack} Empty Racks)
												</div>
											</div>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Product Name</label>
												</div>
												<div className='col-md-8 mb-3 text-start'>
													<input
														type='text'
														value={stockName}
														disabled
														style={{ height: '1rem' }}
														className='form-control py-3'
													/>
													<span className='mt-1'>
														<b>{stockQuantity}</b> Total Product
													</span>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Product Quantity</label>
												</div>
												<div className='col-md-8 mb-3'>
													<input
														name='quantity'
														id='quantity'
														type='number'
														onChange={(e) => {
															setQuantity(e.target.value);
														}}
														style={{ height: '1rem' }}
														className='form-control py-3'
														placeholder='Enter Product Quantity to Transferrrrr'
													/>
												</div>
											</div>
											<div className='row'>
												<ProductFilter
													allMainStockRack={allMainStockRack}
													rackId={rackId}
													setRacksId={setRacksId}
													racksDetail={racksDetail}
													setRacksDetail={setRacksDetail}
												/>
											</div>

											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													onClick={(e) => {
														e.preventDefault();
														addStockIn();
													}}
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
												>
													Add Stock In
												</button>
											</div>
										</from>
									</div>
								</div>
							</div>
						</div>
						{/********************** Selling Stock In Modal **********************/}
						<div
							class='modal fade'
							id='exampleModal3'
							tabindex='-1'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h5
											class='modal-title'
											id='exampleModalLabel'
										>
											Move to Main Stock
										</h5>

										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div class='modal-body'>
										<from>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Available Racks</label>
												</div>
												<div className='col-md-8 mb-3 text-start'>
													<span className='fw-bold'>
														{mainStockEmptyRack} / {allMainStockRackLength}
													</span>{' '}
													({mainStockEmptyRack} Empty Racks)
												</div>
											</div>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Product Name</label>
												</div>
												<div className='col-md-8 mb-3 text-start'>
													<input
														type='text'
														value={stockName}
														disabled
														style={{ height: '1rem' }}
														className='form-control py-3'
													/>
													<span className='mt-1'>
														<b>{stockQuantity}</b> Total Product
													</span>
												</div>
											</div>
											<div className='row'>
												<div className='col-md-4'>
													<label className=''>Product Quantity</label>
												</div>
												<div className='col-md-8 mb-3'>
													<input
														type='number'
														onChange={(e) => {
															setQuantity(e.target.value);
														}}
														style={{ height: '1rem' }}
														className='form-control py-3'
														placeholder='Enter Product Quantity to Transfer'
													/>
												</div>
											</div>
											<div className='row'>
												<ProductFilter
													allMainStockRack={allMainStockRack}
													rackId={rackId}
													setRacksId={setRacksId}
													racksDetail={racksDetail}
													setRacksDetail={setRacksDetail}
												/>
											</div>

											<div class='modal-footer'>
												<button
													type='button'
													class='btn btn-secondary'
													data-bs-dismiss='modal'
												>
													Close
												</button>
												<button
													onClick={(e) => {
														e.preventDefault();
														addStockIn2();
													}}
													type='submit'
													class='btn btn-success'
													data-bs-dismiss='modal'
												>
													Add Stock In
												</button>
											</div>
										</from>
									</div>
								</div>
							</div>
						</div>
						{/********************** Add Product Modal **********************/}
						<div
							className={!checkValue ? className : changeClassName}
							tabIndex={-1}
							id='offcanvasRight'
							aria-labelledby='offcanvasRightLabel'
						>
							<div className='offcanvas-header px-4'>
								<div className='content-header'>
									<h4>Manage Product Create / Update</h4>
									<p>Update products info, combinations and extras.</p>
								</div>

								<button
									type='button'
									className='btn-close bg-white shadow rounded-5 text-reset'
									data-bs-dismiss='offcanvas'
									aria-label='Close'
									style={{
										width: '5px',
										height: '30px',
										opacity: '0.8',
										fontSize: '12px',
									}}
								/>
							</div>
							{/* Canvas */}
							<div className='offcanvas-body all_categories p-0'>
								<div className='form-data'>
									<ul
										class='nav nav-pills'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='pills-home-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-home'
												type='button'
												role='tab'
												aria-controls='pills-home'
												aria-selected='true'
											>
												Recycling
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link'
												id='pills-profile-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-profile'
												type='button'
												role='tab'
												aria-controls='pills-profile'
												aria-selected='false'
											>
												Selling
											</button>
										</li>
									</ul>
									<div
										class='tab-content'
										id='pills-tabContent'
									>
										<div
											class='tab-pane fade show active'
											id='pills-home'
											role='tabpanel'
											aria-labelledby='pills-home-tab'
										>
											{/* Recycling Form */}
											<div className='wrap-data p-5'>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														RecyclingData(e.target);
													}}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																id='mySelect'
																name='categoryID'
																class='form-control-input-2'
																aria-label='Default select example'
																onChange={HandleCommissionFilterr}
															>
																<option
																	selected
																	disabled
																	value={''}
																>
																	---Select Category------
																</option>
																{recyclingCategoryType.map((item) => {
																	if (item?.category === 'recycling') {
																		return (
																			<>
																				<option value={item?.categoryTitle}>
																					{`${item?.categoryTitle}(Admin Commission Minimum ${item?.adminMinimumCommision}% )`}
																				</option>
																			</>
																		);
																	}
																})}
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Sub Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																id='mySelect'
																name='subCategoryID'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	disabled
																>
																	---Select Sub Category---
																</option>

																{recyclingSubCategoryType?.map(
																	(item, index) => {
																		return (
																			<option value={item?.subCategoryTitle}>
																				{item?.subCategoryTitle}
																			</option>
																		);
																	}
																)}
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Type
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='foodCategory'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option value='Food'>Food</option>
																<option value='No Food'>No Food</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='name'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Name'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Life Day from Sale Day
															</label>
														</div>
														<div className='col-md-8 row ms-1'>
															<input
																type='text'
																name='TimeframeYear'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Years'
																defaultValue={'Year'}
																disabled
															/>
															<input
																type='number'
																name='y'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Years'
																onChange={saveData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																name='TimeframeMonth'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Month'
																defaultValue={'Month'}
																disabled
															/>
															<input
																type='number'
																name='m'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Month (0-11)'
																onChange={saveData}
																min={0}
																max={11}
																style={{ paddingLeft: '1rem' }}
															/>

															<input
																type='text'
																name='TimeframeWeeks'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Weeks'
																defaultValue={'Weeks'}
																disabled
															/>
															<input
																type='number'
																name='w'
																min={0}
																max={3}
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Weeks (0-3)'
																onChange={saveData}
																style={{ paddingLeft: '1rem' }}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Price Reduction
															</label>
														</div>
														<div className='col-md-8 row ms-1'>
															<input
																type='text'
																name='TimeframeYear'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Replace in Year'}
																disabled
															/>
															<input
																type='number'
																name='y'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Years'
																defaultValue={0}
																onChange={reductionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Per Year Deduct Price (%)'}
																disabled
															/>
															<input
																type='text'
																name='perYearReduction'
																className='form-control-input col-2'
																aria-labelledby='passwordHelpInline'
																placeholder='-- %'
																onChange={reductionData}
															/>
															<input
																type='text'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Replace in Month'}
																disabled
															/>
															<input
																type='number'
																name='m'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Months'
																defaultValue={0}
																onChange={reductionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Per Month Deduct Price (%)'}
																disabled
															/>
															<input
																type='text'
																name='perMonthReduction'
																className='form-control-input col-2'
																aria-labelledby='passwordHelpInline'
																placeholder='-- %'
																onChange={reductionData}
															/>

															<input
																type='text'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Replace in Week'}
																disabled
															/>
															<input
																type='number'
																name='w'
																className='form-control-input col-3'
																aria-labelledby='passwordHelpInline'
																placeholder='How Many Weeks'
																defaultValue={0}
																onChange={reductionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																aria-labelledby='passwordHelpInline'
																defaultValue={'Per Week Deduct Price (%)'}
																disabled
															/>
															<input
																type='text'
																name='perWeekReduction'
																className='form-control-input col-2'
																aria-labelledby='passwordHelpInline'
																placeholder='-- %'
																onChange={reductionData}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productPrice'
																onChange={(e) => setChangePrice(e.target.value)}
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder=' Product Price'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Admin Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='Admin'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Admin Commission'
																value={adminCom}
																onChange={(e) => setAdminCom(e?.target.value)}
															/>
															{adminCom < com[0]?.adminCommission ? (
																<small className='text-danger'>
																	*Must add commission more then the admin
																	commission.
																</small>
															) : null}
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Ambassador Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='Ambassador'
																name='ambassadorComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Ambassador Commission'
																value={ambassadorCom}
																onChange={(e) =>
																	setAmbassadorCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Sub Ambassador Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='SubAmbassador'
																name='subAmbassadorComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Sub Ambassador Commission'
																value={subAmbassadorCom}
																onChange={(e) =>
																	setSubAmbassadorCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Referral Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='referral'
																name='referralComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Referral Commission'
																value={referralCom}
																onChange={(e) =>
																	setReferralCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Retail price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='retailPrice'
																id='Retail'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Retail Price'
																value={(
																	parseInt(changePrice) +
																	(parseInt(changePrice) * parseInt(adminCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(ambassadorCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(subAmbassadorCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(referralCom)) /
																		100
																).toFixed(2)}
																disabled
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Quantity
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																name='quantity'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Product Quantity'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																HS Code
															</label>
														</div>
														<div className='col-md-8 d-flex'>
															<div
																className='input-group'
																style={{ flexBasis: '64%' }}
															>
																<input
																	type='number'
																	name='code'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='HS Code'
																/>
															</div>
															<div
																className='input-group-append'
																style={{ flexBasis: '36%' }}
															>
																<span
																	className='btn bg-light text-dark disabled'
																	style={{
																		height: '3rem',
																		lineHeight: '2rem',
																	}}
																>
																	*FOR INTERNATIONAL EXPORT
																</span>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Unit
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='unit'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select Unit--
																</option>
																<option value='Piece'>Piece</option>
																<option value='Meter'>Meter</option>
																<option value='Kilogram'>KiloGram</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Quality of Product
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='quality'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option value='A Grade'>A+ Grade</option>
																<option value='A Grade'>A Grade</option>
																<option value='B Grade'>B Grade</option>
																<option value='C Grade'>C Grade</option>
															</select>
														</div>
													</div>

													{/* <div className="row pt-4  align-items-center">
                                <div className="col-md-4">
                                  <label
                                    for="inputPassword6"
                                    className="form-label"
                                  >
                                    Currency
                                  </label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    id="inputPassword6"
                                    name="currency"
                                    className="form-control-input"
                                    aria-labelledby="passwordHelpInline"
                                    placeholder="Currency"
                                  />
                                </div>
                              </div> */}

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Deliveryyyyy
															</label>
														</div>
														<div className='col-md-8 row ms-2'>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='Local'
																		onChange={handleLocalCheckboxChange}
																		checked={localCheckBox === 'Local'}
																	/>
																	<label className='form-check-label'>
																		Local
																	</label>
																</div>
																{localCheckBox === 'Local' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}

																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setLocalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3 tex-start'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='National'
																		onChange={handleNationalCheckboxChange}
																		checked={nationalCheckBox === 'National'}
																	/>
																	<label className='form-check-label'>
																		National
																	</label>
																</div>
																{nationalCheckBox === 'National' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='International'
																		onChange={handleInterNationalCheckboxChange}
																		checked={
																			interNationalCheckBox === 'International'
																		}
																	/>
																	<label className='form-check-label'>
																		International
																	</label>
																</div>
																{interNationalCheckBox === 'International' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setInterNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Made in Country
															</label>
														</div>
														<div className='col-md-8'>
															<select
																class='form-control-input-2'
																name='country'
																aria-label='Default select example'
																onChange={(e) =>
																	setCountryFilter(e.target.value)
																}
															>
																<option
																	value='1'
																	selected
																	disabled
																>
																	---Select Country---
																</option>
																{countries?.map((c) => (
																	<option
																		key={c.name}
																		value={c.iso2}
																	>
																		{c.name}
																	</option>
																))}
															</select>
														</div>
													</div>

													{/* <div className="row  pt-4  align-items-center">
                                <div className="col-md-4">
                                  <label
                                    for="inputPassword6"
                                    className="form-label"
                                  >
                                    City
                                  </label>
                                </div>
                                <div className="col-md-8">
                                  <select
                                    class="form-control-input-2"
                                    name="city"
                                    aria-label="Default select example"
                                  >
                                    <option value="" selected disabled>---Select City---</option>
                                    {cities?.map((ci) => (
                                      <option key={ci.id} value={ci.name}>
                                        {ci.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div> */}

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																In Case of Damage Policy
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='damagePolicy'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Write Note Here'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4 position-relative'>
																<div className=''>
																	{singleImages.length > 0 ? (
																		<>
																			<div className='col-md-2  position-relative'>
																				<img
																					src={URL?.createObjectURL(
																						singleImages[0]
																					)}
																					width='100%'
																					height='100%'
																					className='border rounded-3'
																					style={{ objectFit: 'cover' }}
																				/>
																				<span
																					className='border  p-1 py-0 rounded-circle bg-dark text-white position-absolute'
																					style={{
																						left: '100%',
																						lineHeight: '15px',
																						fontSize: 'x-small',
																						cursor: 'pointer',
																					}}
																					onClick={() => setSingleImages([])}
																				>
																					x
																				</span>
																			</div>
																		</>
																	) : (
																		<div className='w-full text-center'>
																			<div
																				className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																				role='button'
																				tabIndex={0}
																				style={{
																					borderStyle: 'dotted',
																					borderColor: '#0E9F6E',
																				}}
																			>
																				<input
																					accept='image/*'
																					multiple=''
																					type='file'
																					name='image'
																					onChange={(e) =>
																						setSingleImages(e.target.files)
																					}
																				/>
																				<span className='mx-auto flex justify-center'>
																					<svg
																						stroke='currentColor'
																						fill='none'
																						strokeWidth={2}
																						viewBox='0 0 24 24'
																						strokeLinecap='round'
																						strokeLinejoin='round'
																						className='text-3xl text-green-500'
																						height='1em'
																						width='1em'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<polyline points='16 16 12 12 8 16' />
																						<line
																							x1={12}
																							y1={12}
																							x2={12}
																							y2={21}
																						/>
																						<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																						<polyline points='16 16 12 12 8 16' />
																					</svg>
																				</span>
																				<p className='text-sm mt-2'>
																					Drag your images here
																				</p>
																				<em className='text-xs text-gray-400'>
																					(Only *.jpeg, *.webp and *.png images
																					will be accepted)
																				</em>
																			</div>
																			<div className='text-green-500' />
																			<aside className='flex flex-row flex-wrap mt-4' />
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Upload multiple product images
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#0E9F6E',
																		}}
																	>
																		<input
																			type='file'
																			onChange={imageHandler}
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
															<div className='col-md-12 d-flex gap-2 my-3'>
																{images &&
																	images?.map((i) => {
																		return (
																			<>
																				<div className='col-md-2  position-relative'>
																					<img
																						src={URL.createObjectURL(i)}
																						width='90px'
																						height='90px'
																						className='border rounded-3'
																					/>
																					<span
																						className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white'
																						style={{
																							left: '80px',
																							lineHeight: '15px',
																							fontSize: 'x-small',
																							cursor: 'pointer',
																						}}
																						onClick={() => handleRemoveItem(i)}
																					>
																						x
																					</span>
																				</div>
																			</>
																		);
																	})}
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Details
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productDetails'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Details'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Production Time
															</label>
														</div>
														<div className='col-md-8 row ms-1'>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Day'}
																disabled
															/>
															<input
																type='text'
																name='d'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Day'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Week'}
																disabled
															/>
															<input
																type='text'
																name='w'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Week'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Month'}
																disabled
															/>
															<input
																type='text'
																name='m'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Month'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
														</div>
													</div>

													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='btn text-light cancel-btn'
																		data-bs-dismiss='offcanvas'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	{checkValue ? (
																		<button
																			type='submit'
																			class='btn-close add-last-btn btn-lg'
																			data-bs-dismiss='offcanvas'
																			aria-label='Close'
																		>
																			Save
																		</button>
																	) : (
																		<button
																			type='submit'
																			class='add-last-btn btn-lg'
																			onClick={() => {
																				setTimeout(() => {
																					document.documentElement.click();
																				}, 3000);
																			}}
																		>
																			Save
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>

										{/* Model Selling */}
										<div
											class='tab-pane fade'
											id='pills-profile'
											role='tabpanel'
											aria-labelledby='pills-profile-tab'
										>
											<div className='text-end   me-3 pt-4'>
												<button
													id='All_Btn'
													onClick={() => {
														setSelectCategory('New Sale');
														document
															.getElementById('All_Btn')
															.setAttribute(
																'style',
																'background-color:#ffd700;color:white;'
															);

														document
															.getElementById('all_btn')
															.setAttribute(
																'style',
																"background-color:#ffd700;color:'black"
															);
													}}
													className='  product_button '
													type='button'
												>
													<span className='px-1'>New sale</span>
												</button>

												<button
													id='all_btn'
													onClick={() => {
														setSelectCategory('Resale');

														document
															.getElementById('all_btn')
															.setAttribute(
																'style',
																'background-color:#ffd700;color:white'
															);

														document
															.getElementById('All_Btn')
															.setAttribute(
																'style',
																"background-color:#ffd700;color:'black')"
															);
													}}
													className='ms-3 product_button '
													type='button'
												>
													<span className='px-1'>Resale</span>
												</button>
											</div>
											{/* Selling Form */}
											<div className='wrap-data p-5'>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														sendData(e.target);
													}}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Selling Type
															</label>
														</div>

														<div className='col-md-8'>
															<Radio.Group
																defaultValue=''
																name='sellingType'
																buttonStyle='solid'
																className='cat_radio'
															>
																<Radio.Button
																	value='B2B'
																	className='cat_option'
																>
																	B2B
																</Radio.Button>
																<Radio.Button
																	value='B2C'
																	className='cat_option'
																>
																	B2C
																</Radio.Button>
																<Radio.Button
																	value='Both'
																	className='cat_option'
																>
																	Both
																</Radio.Button>
															</Radio.Group>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='name'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Name'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Type
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='foodCategory'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option value='Food'>Food</option>
																<option value='No Food'>No Food</option>
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='categoryID'
																class='form-control-input-2'
																aria-label='Default select example'
																onChange={HandleCommissionFilterr}
															>
																<option
																	selected
																	value={''}
																>
																	--Select Category------
																</option>
																{/* {
    "_id": "66024a79f85ddf4e41cc0747",
    "category": "selling",
    "categoryTitle": "nike",
    "adminMinimumCommision": 40,
    "status": "active",
    "__v": 0
} */}
																{sellingCategoryType?.map((item, index) => {
																	if (item?.category === 'selling') {
																		return (
																			<>
																				<option value={item?.categoryTitle}>
																					{`${item?.categoryTitle}(Admin Commission Minimum ${item?.adminMinimumCommision}% )`}
																				</option>
																			</>
																		);
																	}
																})}
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Sub Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='subCategoryID'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																>
																	--Select Sub Category--
																</option>

																{/* {
    "_id": "66024a91f85ddf4e41cc0751",
    "categoryType": "selling",
    "category": "nike",
    "subCategoryTitle": "shoes",
    "__v": 0

	{recyclingSubCategoryType?.map(
																	(item, index) => {
																		return (
																			<option value={item?.subCategoryTitle}>
																				{item?.subCategoryTitle}
																			</option>
																		);
																	}
																)}
} */}
																{/* {sellingSubCategoryType?.map((item, index) => {
																	<option value={item?._id}>
																		{item?.subCategoryTitle}
																	</option>;
																})} */}

																{sellingSubCategoryType?.map((item, index) => {
																	return (
																		<option value={item?.subCategoryTitle}>
																			{item?.subCategoryTitle}
																		</option>
																	);
																})}
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Made in Country
															</label>
														</div>
														<div className='col-md-8'>
															<select
																class='form-control-input-2'
																name='country'
																aria-label='Default select example'
																onChange={(e) =>
																	setCountryFilter(e.target.value)
																}
															>
																<option
																	value='1'
																	selected
																	disabled
																>
																	---Select Country---
																</option>
																{countries?.map((c) => (
																	<option
																		key={c.name}
																		value={c.iso2}
																	>
																		{c.name}
																	</option>
																))}
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Owner Type
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='ownerType'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option value='Manufacturer'>
																	Manufacturer
																</option>
																<option value='Producer'>Producer</option>
																<option value='Whole Seller'>
																	Whole Seller
																</option>
																<option value='Seller'>Seller</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																HS Code
															</label>
														</div>
														<div className='col-md-8 d-flex'>
															<div
																className='input-group'
																style={{ flexBasis: '64%' }}
															>
																<input
																	type='number'
																	name='code'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='HS Code'
																/>
															</div>
															<div
																className='input-group-append'
																style={{ flexBasis: '36%' }}
															>
																<span
																	className='btn bg-light text-dark disabled'
																	style={{
																		height: '3rem',
																		lineHeight: '2rem',
																	}}
																>
																	*FOR INTERNATIONAL EXPORT
																</span>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productPrice'
																onChange={(e) => setChangePrice(e.target.value)}
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder=' Product Price'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Admin Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='Admin'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Admin Commission'
																value={adminCom}
																onChange={(e) => setAdminCom(e?.target.value)}
															/>
															{adminCom < com[0]?.adminCommission ? (
																<small className='text-danger'>
																	*Must add commission more then the admin
																	commission.
																</small>
															) : null}
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Ambassador Commissionnnn (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='Ambassador'
																name='ambassadorComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Ambassador Commission'
																value={ambassadorCom}
																onChange={(e) =>
																	setAmbassadorCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Sub Ambassador Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='SubAmbassador'
																name='subAmbassadorComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Sub Ambassador Commission'
																value={subAmbassadorCom}
																onChange={(e) =>
																	setSubAmbassadorCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Referral Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='referral'
																name='referralComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Referral Commission'
																value={referralCom}
																onChange={(e) =>
																	setReferralCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Retail price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='retailPrice'
																id='Retail'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Retail Price'
																value={(
																	parseInt(changePrice) +
																	(parseInt(changePrice) * parseInt(adminCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(ambassadorCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(subAmbassadorCom)) /
																		100 +
																	(parseInt(changePrice) *
																		parseInt(referralCom)) /
																		100
																).toFixed(2)}
																disabled
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Quantity
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																name='quantity'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Product Quantity'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Unit
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='unit'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select Unit--
																</option>
																<option value='Piece'>Piece</option>
																<option value='Meter'>Meter</option>
																<option value='KiloGram'>KiloGram</option>
																<option value='Litre'>Litre</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Quality of Product
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='quality'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	selected
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option value='A Grade'>A+ Grade</option>
																<option value='A Grade'>A Grade</option>
																<option value='B Grade'>B Grade</option>
																<option value='C Grade'>C Grade</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Deliveryyyyy
															</label>
														</div>
														<div className='col-md-8 row ms-2'>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='Local'
																		onChange={handleLocalCheckboxChange}
																		checked={localCheckBox === 'Local'}
																	/>
																	<label className='form-check-label'>
																		Local
																	</label>
																</div>
																{localCheckBox === 'Local' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}

																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setLocalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3 tex-start'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='National'
																		onChange={handleNationalCheckboxChange}
																		checked={nationalCheckBox === 'National'}
																	/>
																	<label className='form-check-label'>
																		National
																	</label>
																</div>
																{nationalCheckBox === 'National' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='International'
																		onChange={handleInterNationalCheckboxChange}
																		checked={
																			interNationalCheckBox === 'International'
																		}
																	/>
																	<label className='form-check-label'>
																		International
																	</label>
																</div>
																{interNationalCheckBox === 'International' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		onChange={(e) =>
																			setInterNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Delivery Time
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='date'
																name='deliveryTime'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4 position-relative'>
																<div className=''>
																	{singleImages.length > 0 ? (
																		<>
																			<div className='col-md-2  position-relative'>
																				<img
																					src={URL?.createObjectURL(
																						singleImages[0]
																					)}
																					width='100%'
																					height='100%'
																					alt=''
																					className='border rounded-3'
																					style={{ objectFit: 'cover' }}
																				/>
																				<span
																					className='border  p-1 py-0 rounded-circle bg-dark text-white position-absolute'
																					style={{
																						left: '100%',
																						lineHeight: '15px',
																						fontSize: 'x-small',
																						cursor: 'pointer',
																					}}
																					onClick={() => setSingleImages([])}
																				>
																					x
																				</span>
																			</div>
																		</>
																	) : (
																		<div className='w-full text-center'>
																			<div
																				className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																				role='button'
																				tabIndex={0}
																				style={{
																					borderStyle: 'dotted',
																					borderColor: '#0E9F6E',
																				}}
																			>
																				<input
																					accept='image/*'
																					multiple=''
																					type='file'
																					name='image'
																					onChange={(e) =>
																						setSingleImages(e.target.files)
																					}
																				/>
																				<span className='mx-auto flex justify-center'>
																					<svg
																						stroke='currentColor'
																						fill='none'
																						strokeWidth={2}
																						viewBox='0 0 24 24'
																						strokeLinecap='round'
																						strokeLinejoin='round'
																						className='text-3xl text-green-500'
																						height='1em'
																						width='1em'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<polyline points='16 16 12 12 8 16' />
																						<line
																							x1={12}
																							y1={12}
																							x2={12}
																							y2={21}
																						/>
																						<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																						<polyline points='16 16 12 12 8 16' />
																					</svg>
																				</span>
																				<p className='text-sm mt-2'>
																					Drag your images here
																				</p>
																				<em className='text-xs text-gray-400'>
																					(Only *.jpeg, *.webp and *.png images
																					will be accepted)
																				</em>
																			</div>
																			<div className='text-green-500' />
																			<aside className='flex flex-row flex-wrap mt-4' />
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Upload multiple product images
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#0E9F6E',
																		}}
																	>
																		<input
																			type='file'
																			onChange={imageHandler}
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
															<div className='col-md-12 d-flex gap-2 my-3'>
																{images &&
																	images?.map((i) => {
																		return (
																			<>
																				<div className='col-md-2  position-relative'>
																					<img
																						src={URL.createObjectURL(i)}
																						width='90px'
																						height='90px'
																						alt=''
																						className='border rounded-3'
																					/>
																					<span
																						className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white'
																						style={{
																							left: '80px',
																							lineHeight: '15px',
																							fontSize: 'x-small',
																							cursor: 'pointer',
																						}}
																						onClick={() => handleRemoveItem(i)}
																					>
																						x
																					</span>
																				</div>
																			</>
																		);
																	})}
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Detail
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productDetails'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Detail'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Manufacturing Date
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='date'
																name='mdfDate'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Expiration Date
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='date'
																name='expDate'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Production Time
															</label>
														</div>
														<div className='col-md-8 row ms-1'>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Day'}
																disabled
															/>
															<input
																type='text'
																name='d'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Day'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Week'}
																disabled
															/>
															<input
																type='text'
																name='w'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Week'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Month'}
																disabled
															/>
															<input
																type='text'
																name='m'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Month'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
															/>
														</div>
													</div>

													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	{checkValue ? (
																		<button
																			type='submit'
																			class='btn-close add-last-btn btn-lg'
																			data-bs-dismiss='offcanvas'
																			aria-label='Close'
																		>
																			Save
																		</button>
																	) : (
																		<button
																			type='submit'
																			class='add-last-btn btn-lg'
																			onClick={() => {
																				setTimeout(() => {
																					document.documentElement.click();
																				}, 3000);
																			}}
																		>
																			Save
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Canvas */}
						</div>
						{/********************** Update Selling Product Modal **********************/}
						<div
							className={!checkValue ? className : changeClassName}
							tabIndex={-1}
							id='updateSellingProduct'
							aria-labelledby='updateSellingProductLabel'
						>
							<div className='offcanvas-header px-4'>
								<div className='content-header'>
									<h4>Update Selling Product</h4>
									<p>Update products info, combinations and extras.</p>
								</div>
								<button
									type='button'
									className='btn-close bg-white shadow rounded-5 text-reset'
									data-bs-dismiss='offcanvas'
									aria-label='Close'
									style={{
										width: '5px',
										height: '30px',
										opacity: '0.8',
										fontSize: '12px',
									}}
								/>
							</div>
							<div className='offcanvas-body all_categories p-0'>
								<div className='form-data'>
									<div
										class='tab-content'
										id='pills-tabContent'
									>
										<div
											className='tab-pane active'
											role='tabpanel'
											aria-labelledby='pills-profile-tab'
										>
											<div className='text-end me-3 pt-4'>
												<button
													id='All_Btn'
													onClick={() => {
														setSelectCategory('New Sale');
														document
															.getElementById('All_Btn')
															.setAttribute(
																'style',
																'background-color:green;color:white;'
															);
														document
															.getElementById('all_btn')
															.setAttribute(
																'style',
																"background-color:#eee;color:'black"
															);
													}}
													className='  product_button '
													type='button'
												>
													<span className='px-1'>New sale</span>
												</button>
												<button
													id='all_btn'
													onClick={() => {
														setSelectCategory('Resale');

														document
															.getElementById('all_btn')
															.setAttribute(
																'style',
																'background-color:green;color:white'
															);

														document
															.getElementById('All_Btn')
															.setAttribute(
																'style',
																"background-color:#eee;color:'black')"
															);
													}}
													className='ms-3 product_button '
													type='button'
												>
													<span className='px-1'>Resale</span>
												</button>
											</div>
											<div className='wrap-data p-5'>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														sendData(e.target);
													}}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Selling Type
															</label>
														</div>
														<div className='col-md-8'>
															<Radio.Group
																value={sellingTypeUpdate}
																name='sellingType'
																buttonStyle='solid'
																className='cat_radio'
																onChange={(e) =>
																	setSellingTypeUpdate(e?.target?.value)
																}
															>
																<Radio.Button
																	value='B2B'
																	className='cat_option'
																>
																	B2B
																</Radio.Button>
																<Radio.Button
																	value='B2C'
																	className='cat_option'
																>
																	B2C
																</Radio.Button>
																<Radio.Button
																	value='Both'
																	className='cat_option'
																>
																	Both
																</Radio.Button>
															</Radio.Group>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='name'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Name'
																value={sellingProductNameUpdate}
																onChange={(e) =>
																	setSellingProductNameUpdate(e?.target?.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Type
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='foodCategory'
																class='form-control-input-2'
																aria-label='Default select example'
																value={sellingFoodType}
																onChange={(e) =>
																	setSellingFoodType(e?.target?.value)
																}
															>
																<option
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option
																	value='Food'
																	selected={
																		sellingProduct?.foodCategory === 'Food' &&
																		true
																	}
																>
																	Food
																</option>
																<option
																	value='No Food'
																	selected={
																		sellingProduct?.foodCategory ===
																			'No Food' && true
																	}
																>
																	No Food
																</option>
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='categoryID'
																class='form-control-input-2'
																aria-label='Default select example'
																value={sellingProductCategory}
																onChange={(e) => {
																	HandleCommissionFilterr();
																	setSellingProductCategory(e?.target?.value);
																}}
															>
																<option
																	selected
																	value={''}
																>
																	--Select Category------
																</option>
																{categories?.map((item) => {
																	if (item?.categoryType === 'selling') {
																		return (
																			<>
																				<option
																					value={item?._id}
																					selected={
																						sellingProduct?.categoryID ===
																							item?._id && true
																					}
																				>
																					{`${item?.title} (Admin Commission Minimum ${item?.adminCommission}% )`}
																				</option>
																			</>
																		);
																	}
																})}
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Sub Category
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='subCategoryID'
																class='form-control-input-2'
																aria-label='Default select example'
																value={sellingProductSubCategory}
																onChange={(e) =>
																	setSellingProductSubCategory(e?.target?.value)
																}
															>
																<option value={''}>
																	--Select Sub Category--
																</option>
																{subCategory?.map((item) => {
																	if (item?.category === selectedCat)
																		return (
																			<option
																				value={item?._id}
																				selected={
																					sellingProductCategory ===
																						item?._id && true
																				}
																			>
																				{item?.title}
																			</option>
																		);
																})}
															</select>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Made in Country
															</label>
														</div>
														<div className='col-md-8'>
															<select
																class='form-control-input-2'
																name='country'
																aria-label='Default select example'
																onChange={(e) => {
																	setCountryFilter(e.target.value);
																	setSellingCountryUpdate(e?.target?.value);
																}}
																value={sellingCountryUpdate}
															>
																<option value=''>---Select Country---</option>
																{countries?.map((c) => (
																	<option
																		key={c.name}
																		value={c.iso2}
																		selected={
																			sellingProduct?.country === c.iso2 && true
																		}
																	>
																		{c.name}
																	</option>
																))}
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Owner Type
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='ownerType'
																class='form-control-input-2'
																aria-label='Default select example'
																value={sellingOwnerType}
																onChange={(e) =>
																	setSellingOwnerType(e?.target?.value)
																}
															>
																<option
																	value={''}
																	disabled
																>
																	--Select--
																</option>
																<option
																	value='Manufacturer'
																	selected={
																		sellingProduct?.ownerType ===
																			'Manufacturer' && true
																	}
																>
																	Manufacturer
																</option>
																<option
																	value='Producer'
																	selected={
																		sellingProduct?.ownerType === 'Producer' &&
																		true
																	}
																>
																	Producer
																</option>
																<option
																	value='Whole Seller'
																	selected={
																		sellingProduct?.ownerType ===
																			'Whole Seller' && true
																	}
																>
																	Whole Seller
																</option>
																<option
																	value='Seller'
																	selected={
																		sellingProduct?.ownerType === 'Seller' &&
																		true
																	}
																>
																	Seller
																</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																HS Code
															</label>
														</div>
														<div className='col-md-8 d-flex'>
															<div
																className='input-group'
																style={{ flexBasis: '64%' }}
															>
																<input
																	type='number'
																	name='code'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='HS Code'
																	value={sellingCode}
																	onChange={(e) =>
																		setSellingCode(e?.target?.value)
																	}
																/>
															</div>
															<div
																className='input-group-append'
																style={{ flexBasis: '36%' }}
															>
																<span
																	className='btn bg-light text-dark disabled'
																	style={{
																		height: '3rem',
																		lineHeight: '2rem',
																	}}
																>
																	*FOR INTERNATIONAL EXPORT
																</span>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productPrice'
																onChange={(e) => {
																	setChangePrice(e.target.value);
																	setSellingProductPrice(e?.target?.value);
																}}
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder=' Product Price'
																value={sellingProductPrice}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Admin Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='Admin'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Admin Commission'
																value={adminCom}
																onChange={(e) => setAdminCom(e?.target.value)}
															/>
															{adminCom < com[0]?.adminCommission ? (
																<small className='text-danger'>
																	*Must add commission more then the admin
																	commission.
																</small>
															) : null}
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Ambassador Commissionnnn (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='Ambassador'
																name='ambassadorComission'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Ambassador Commission'
																value={ambassadorCom}
																onChange={(e) =>
																	setAmbassadorCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Referral Commission (%)
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																id='referral'
																name='referralComission'
																value={sellingProduct?.referralComission}
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Referral Commission'
																// value={referralCom}
																onChange={(e) =>
																	setReferralCom(e?.target.value)
																}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Retail price
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='retailPrice'
																id='Retail'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Retail Price'
																value={sellingProduct?.retailPrice}
																// value={
																//   parseInt(changePrice) +
																//   (parseInt(changePrice) * parseInt(adminCom)) /
																//     100 +
																//   (parseInt(changePrice) *
																//     parseInt(ambassadorCom)) /
																//     100 +
																//   (parseInt(changePrice) *
																//     parseInt(referralCom)) /
																//     100
																// }
																disabled
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Quantity
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='number'
																name='quantity'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Enter Product Quantity'
																value={sellingProduct?.quantity}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Unit
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='unit'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option
																	value={''}
																	disabled
																>
																	--Select Unit--
																</option>
																<option
																	value='piece'
																	selected={
																		sellingProduct?.unit === 'piece' && true
																	}
																>
																	Piece
																</option>
																<option
																	value='meter'
																	selected={
																		sellingProduct?.unit === 'meter' && true
																	}
																>
																	Meter
																</option>
																<option
																	value='kg'
																	selected={
																		sellingProduct?.unit === 'kg' && true
																	}
																>
																	KiloGram
																</option>
																<option
																	value='Litre'
																	selected={
																		sellingProduct?.unit === 'Litre' && true
																	}
																>
																	Litre
																</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Quality of Product
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='quality'
																class='form-control-input-2'
																aria-label='Default select example'
															>
																<option value={''}>--Select--</option>
																<option
																	value='A+ Grade'
																	selected={
																		sellingProduct?.quality === 'A+ Grade' &&
																		true
																	}
																>
																	A+ Grade
																</option>
																<option
																	value='A Grade'
																	selected={
																		sellingProduct?.quality === 'A Grade' &&
																		true
																	}
																>
																	A Grade
																</option>
																<option
																	value='B Grade'
																	selected={
																		sellingProduct?.quality === 'B Grade' &&
																		true
																	}
																>
																	B Grade
																</option>
																<option
																	value='C Grade'
																	selected={
																		sellingProduct?.quality === 'C Grade' &&
																		true
																	}
																>
																	C Grade
																</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Delivery
															</label>
														</div>
														<div className='col-md-8 row ms-2'>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='Local'
																		onChange={handleLocalCheckboxChange}
																		checked={
																			sellingProduct?.deliveryOptions[0]
																				?.check === 'Local' && true
																		}
																	/>
																	<label className='form-check-label'>
																		Local
																	</label>
																</div>
																{localCheckBox === 'Local' ||
																sellingProduct?.deliveryOptions[0]?.cod ===
																	'on' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			value={
																				sellingProduct?.deliveryOptions[0]
																					?.check === 'Local'
																					? 'on'
																					: 'off'
																			}
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setLocalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		value={
																			sellingProduct?.deliveryOptions[0]
																				?.check === 'Local' &&
																			sellingProduct?.deliveryOptions[0]
																				?.charges
																		}
																		onChange={(e) =>
																			setLocalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3 tex-start'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='National'
																		onChange={handleNationalCheckboxChange}
																		checked={
																			sellingProduct?.deliveryOptions[1]
																				?.check === 'National' && true
																		}
																	/>
																	<label className='form-check-label'>
																		National
																	</label>
																</div>
																{nationalCheckBox === 'National' ||
																sellingProduct?.deliveryOptions[1]?.cod ===
																	'on' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			value={
																				sellingProduct?.deliveryOptions[1]
																					?.check === 'National'
																					? 'on'
																					: 'off'
																			}
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		value={
																			sellingProduct?.deliveryOptions[1]
																				?.check === 'National' &&
																			sellingProduct?.deliveryOptions[1]
																				?.charges
																		}
																		onChange={(e) =>
																			setNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
															<div className='row dil_box'>
																<div className='col-3'>
																	<input
																		type='checkbox'
																		className='form-check-input me-2'
																		name='delivery'
																		value='International'
																		onChange={handleInterNationalCheckboxChange}
																		checked={
																			sellingProduct?.deliveryOptions[2]
																				?.check === 'International' && true
																		}
																	/>
																	<label className='form-check-label'>
																		International
																	</label>
																</div>
																{interNationalCheckBox === 'International' ||
																sellingProduct?.deliveryOptions[2]?.cod ===
																	'on' ? (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			value={
																				sellingProduct?.deliveryOptions[2]
																					?.check === 'International'
																					? 'on'
																					: 'off'
																			}
																			buttonStyle='solid'
																			className='dil_radio'
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																) : (
																	<div className='col-5'>
																		Cash on Delivery:
																		<Radio.Group
																			defaultValue='off'
																			buttonStyle='solid'
																			className='dil_radio'
																			disabled
																		>
																			<Radio.Button
																				value='on'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				On
																			</Radio.Button>
																			<Radio.Button
																				value='off'
																				className='dil_option'
																				onChange={(e) =>
																					setinterNationalInput(e.target.value)
																				}
																			>
																				OFF
																			</Radio.Button>
																		</Radio.Group>
																	</div>
																)}
																<div className='col-4'>
																	<input
																		className='form-control'
																		placeholder='Chargers'
																		type='number'
																		value={
																			sellingProduct?.deliveryOptions[2]
																				?.check === 'International' &&
																			sellingProduct?.deliveryOptions[2]
																				?.charges
																		}
																		onChange={(e) =>
																			setInterNationalCharges(e.target.value)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Delivery Time
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type={
																	moment(sellingProduct?.deliveryTime).format(
																		'MM/DD/YYYY'
																	)
																		? 'text'
																		: 'date'
																}
																name='deliveryTime'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																value={moment(
																	sellingProduct?.deliveryTime
																).format('MM/DD/YYYY')}
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4 position-relative'>
																<div className=''>
																	{singleImages.length === 0 ? (
																		<>
																			<div className='col-md-2  position-relative'>
																				<img
																					src={
																						singleImages.length > 0
																							? URL?.createObjectURL(
																									singleImages[0]
																							  )
																							: `${ApiUrl}/images/${sellingProduct?.image}`
																					}
																					width='100%'
																					height='100%'
																					alt=''
																					className='border rounded-3'
																					style={{ objectFit: 'cover' }}
																				/>
																				<span
																					className='border  p-1 py-0 rounded-circle bg-dark text-white position-absolute'
																					style={{
																						left: '100%',
																						lineHeight: '15px',
																						fontSize: 'x-small',
																						cursor: 'pointer',
																					}}
																					onClick={() => setSingleImages([])}
																				>
																					x
																				</span>
																			</div>
																		</>
																	) : (
																		<div className='w-full text-center'>
																			<div
																				className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																				role='button'
																				tabIndex={0}
																				style={{
																					borderStyle: 'dotted',
																					borderColor: '#0E9F6E',
																				}}
																			>
																				<input
																					accept='image/*'
																					multiple=''
																					type='file'
																					name='image'
																					onChange={(e) =>
																						setSingleImages(e.target.files)
																					}
																				/>
																				<span className='mx-auto flex justify-center'>
																					<svg
																						stroke='currentColor'
																						fill='none'
																						strokeWidth={2}
																						viewBox='0 0 24 24'
																						strokeLinecap='round'
																						strokeLinejoin='round'
																						className='text-3xl text-green-500'
																						height='1em'
																						width='1em'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<polyline points='16 16 12 12 8 16' />
																						<line
																							x1={12}
																							y1={12}
																							x2={12}
																							y2={21}
																						/>
																						<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																						<polyline points='16 16 12 12 8 16' />
																					</svg>
																				</span>
																				<p className='text-sm mt-2'>
																					Drag your images here
																				</p>
																				<em className='text-xs text-gray-400'>
																					(Only *.jpeg, *.webp and *.png images
																					will be accepted)
																				</em>
																			</div>
																			<div className='text-green-500' />
																			<aside className='flex flex-row flex-wrap mt-4' />
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Upload multiple product images
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#0E9F6E',
																		}}
																	>
																		<input
																			type='file'
																			onChange={imageHandler}
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
															<div className='col-md-12 d-flex gap-2 my-3'>
																{images &&
																	images?.map((i) => {
																		return (
																			<>
																				<div className='col-md-2  position-relative'>
																					<img
																						src={URL.createObjectURL(i)}
																						width='90px'
																						height='90px'
																						alt=''
																						className='border rounded-3'
																					/>
																					<span
																						className='border position-absolute p-1 py-0 rounded-circle bg-dark text-white'
																						style={{
																							left: '80px',
																							lineHeight: '15px',
																							fontSize: 'x-small',
																							cursor: 'pointer',
																						}}
																						onClick={() => handleRemoveItem(i)}
																					>
																						x
																					</span>
																				</div>
																			</>
																		);
																	})}
															</div>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Detail
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='productDetails'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Detail'
																value={sellingProduct?.productDetail}
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Manufacturing Date
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='date'
																name='mdfDate'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Expiration Date
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='date'
																name='expDate'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
															/>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Production Time
															</label>
														</div>
														<div className='col-md-8 row ms-1'>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Day'}
																disabled
															/>
															<input
																type='text'
																name='d'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Day'
																onChange={productionData}
																style={{ paddingLeft: '1rem' }}
																defaultValue={sellingProduct?.productionUnit?.d}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Week'}
																disabled
															/>
															<input
																type='text'
																name='w'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Week'
																onChange={productionData}
																defaultValue={sellingProduct?.productionUnit?.w}
																style={{ paddingLeft: '1rem' }}
															/>
															<input
																type='text'
																className='form-control-input col-4'
																defaultValue={'1 Month'}
																disabled
															/>
															<input
																type='text'
																name='m'
																className='form-control-input col-8'
																aria-labelledby='passwordHelpInline'
																placeholder='Product Production in One Month'
																onChange={productionData}
																defaultValue={sellingProduct?.productionUnit?.m}
																style={{ paddingLeft: '1rem' }}
															/>
														</div>
													</div>

													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	{checkValue ? (
																		<button
																			type='submit'
																			class='btn-close add-last-btn btn-lg'
																			data-bs-dismiss='offcanvas'
																			aria-label='Close'
																		>
																			Save
																		</button>
																	) : (
																		<button
																			type='submit'
																			class='add-last-btn btn-lg'
																			onClick={() => {
																				setTimeout(() => {
																					document.documentElement.click();
																				}, 3000);
																			}}
																		>
																			Save
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/********************************************/}
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage Products</h5>
								<ul
									class='nav nav-pills mb-3 d-flex '
									id='pills-tab'
									role='tablist'
									style={{ gap: '12px', justifyContent: 'flex-start' }}
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link active'
											id='pills-Recycling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Recycling'
											type='button'
											role='tab'
											aria-controls='pills-Recycling'
											aria-selected='true'
										>
											Recycling
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-Selling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Selling'
											type='button'
											role='tab'
											aria-controls='pills-Selling'
											aria-selected='false'
										>
											Selling
										</button>
									</li>
								</ul>

								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-Recycling'
										role='tabpanel'
										aria-labelledby='pills-Recycling-tab'
									>
										<div className='min-box  border'>
											<div className='products-actions d-flex p-4'>
												<div className='imort-product '>
													<ul
														class='nav nav-pills d-flex mb-3'
														id='pills-tab'
														role='tablist'
													>
														<li
															class='nav-item'
															role='presentation'
														>
															<button
																class='nav-link active'
																id='pills-Pending-tab'
																data-bs-toggle='pill'
																data-bs-target='#pills-Pending'
																type='button'
																role='tab'
																aria-controls='pills-Pending'
																aria-selected='true'
															>
																Pending
															</button>
														</li>
														<li
															class='nav-item'
															role='presentation'
														>
															<button
																class='nav-link'
																id='pills-Approved-tab'
																data-bs-toggle='pill'
																data-bs-target='#pills-Approved'
																type='button'
																role='tab'
																aria-controls='pills-Approved'
																aria-selected='false'
															>
																Approved
															</button>
														</li>
													</ul>
												</div>
												<div className='action-btn'>
													<div className='actions d-flex'>
														<button
															className='add-btn'
															type='button'
															data-bs-toggle='offcanvas'
															data-bs-target='#offcanvasRight'
															aria-controls='offcanvasRight'
														>
															<AiOutlinePlus />
															<span className='px-1'>Add Products</span>
														</button>
													</div>
												</div>
											</div>
										</div>

										<div
											class='tab-content'
											id='pills-tabContent'
										>
											<div
												class='tab-pane fade show active'
												id='pills-Pending'
												role='tabpanel'
												aria-labelledby='pills-Pending-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle'
															style={{
																borderColor: '#4f4f51',
																tableLayout: 'fixed',
															}}
														>
															<thead className='bg-light'>
																<tr>
																	<th>Id</th>
																	<th>Image</th>
																	<th>Category</th>
																	<th>Seller</th>
																	<th>Price($)</th>
																	<th>View</th>
																	<th>ACTIONS</th>
																</tr>
															</thead>
															{/* {
    "lifeDayFromSaleDay": {
        "year": 0,
        "month": 0,
        "weeks": 0
    },
    "priceReduction": {
        "replaceInYear": 0,
        "perYearDeductPrice": 0,
        "replaceInMonth": 0,
        "perMonthDeductPrice": 0,
        "replaceInWeek": 0,
        "perWeekDeductPrice": 0
    },
    "delivery": {
        "local": {
            "enabled": false,
            "cashOnDelivery": false,
            "charges": 0
        },
        "national": {
            "enabled": false,
            "cashOnDelivery": false,
            "charges": 0
        },
        "international": {
            "enabled": false,
            "cashOnDelivery": false,
            "charges": 0
        }
    },
    "_id": "66026550b50c8c223d49c084",
    "category": "scrap",
    "subcategory": "electronics",
    "productType": "Food",
    "productName": "usman",
    "ambassadorCommission": 20,
    "subAmbassadorCommission": 10,
    "retailPrice": 925,
    "productQuantity": 50,
    "hsCode": 654654654,
    "unit": "Piece",
    "qualityOfProduct": "A Grade",
    "madeInCountry": "PK",
    "damagePolicy": "jhgfdyhtgrfeytrewyutrewytre",
    "productDetails": "askjdhasdhalsdhlakdslakdjlaklasdkjladkj",
    "image": null,
    "images": [],
    "status": "pending",
    "__v": 0
} */}
															<tbody>
																{recyclingProductData?.map((item, index) => {
																	if (item?.status === 'pending') {
																		return (
																			<>
																				<tr>
																					<td>MP{item?.hsCode}</td>
																					<td>
																						<div className='prod-img'>
																							<img
																								src={`${ApiUrl}/images/${item?.image}`}
																								alt='image pending'
																							/>
																						</div>
																					</td>
																					<td>{item?.category}</td>
																					<td>{item?.productName}</td>
																					<td>{item?.retailPrice}</td>
																					<td>
																						<button
																							onClick={() => {
																								handleSingleRecycleProduct(
																									item
																								);
																								setShowRecycle(true);
																							}}
																							target='_blank'
																							class='btn btn-sm  py-1 text-center'
																						>
																							<AiFillEye className='fs-5' />
																						</button>
																					</td>
																					<td>
																						<div className='actions d-flex'>
																							<span
																								onClick={() => {
																									onDeleteRecycling(item?._id);
																								}}
																								style={{ fontSize: 21 }}
																								className='px-3'
																							>
																								<RiDeleteBinLine />
																							</span>
																						</div>
																					</td>
																				</tr>
																			</>
																		);
																	}
																})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='pills-Approved'
												role='tabpanel'
												aria-labelledby='pills-Approved-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle'
															style={{ borderColor: '#4f4f51' }}
														>
															<thead className='bg-light'>
																<tr>
																	<th>Id</th>
																	<th>Name</th>
																	<th>Image</th>
																	<th>Category</th>
																	<th>Seller</th>
																	<th>Price($)</th>
																	<th>Quantity</th>
																	<th>Add to Main Stock</th>
																	<th>View</th>
																	<th>ACTION</th>
																</tr>
															</thead>
															<tbody>
																{allrecycling?.map((item, index) => {
																	if (item?.productStatus === 'approved') {
																		return (
																			<>
																				<tr>
																					<td>MP{item?.code}</td>
																					<td>{item?.name}</td>
																					<td>
																						<div className='prod-img'>
																							<img
																								src={`${ApiUrl}/images/${item?.image}`}
																								alt=''
																							/>
																						</div>
																					</td>
																					<td>
																						{getcategories &&
																							getcategories[index]?.title}
																					</td>
																					<td>{seller?.name}</td>
																					<td>{item?.retailPrice}</td>
																					<td>
																						{item?.quantity} {item?.unit}
																					</td>
																					<td>
																						<button
																							onClick={() => {
																								setStockProduct(item?._id);
																								setStockName(item?.name);
																								setStockQuantity(
																									item?.quantity
																								);
																							}}
																							target='_blank'
																							class='btn btn-sm btn-success py-1'
																							data-bs-toggle='modal'
																							data-bs-target='#exampleModal2'
																						>
																							Stock In
																						</button>
																					</td>
																					<td>
																						<button
																							onClick={() => {
																								navigate(
																									`./productInfo/${item?._id}`
																								);
																								// handleSingleRecycleProduct(
																								//   item?._id
																								// );
																								// setShowRecycle(true);
																							}}
																							target='_blank'
																							class='btn btn-sm btn-success py-1 text-center'
																						>
																							<AiFillEye className='fs-5' />
																						</button>
																					</td>
																					<td>
																						<div className='actions d-flex'>
																							<span style={{ fontSize: 21 }}>
																								<FiEdit />
																							</span>
																						</div>
																					</td>
																				</tr>
																			</>
																		);
																	}
																})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class='tab-pane fade'
										id='pills-Selling'
										role='tabpanel'
										aria-labelledby='pills-Selling-tab'
									>
										<div className='min-box  border'>
											<div className='products-actions d-flex p-4'>
												<div className='imort-product '>
													<ul
														class='nav nav-pills d-flex mb-3'
														id='pills-tab'
														role='tablist'
													>
														<li
															class='nav-item'
															role='presentation'
														>
															<button
																class='nav-link active'
																id='pills-PendingSelling-tab'
																data-bs-toggle='pill'
																data-bs-target='#pills-PendingSelling'
																type='button'
																role='tab'
																aria-controls='pills-PendingSelling'
																aria-selected='true'
															>
																Pending
															</button>
														</li>
														<li
															class='nav-item'
															role='presentation'
														>
															<button
																class='nav-link'
																id='pills-ApprovedSelling-tab'
																data-bs-toggle='pill'
																data-bs-target='#pills-ApprovedSelling'
																type='button'
																role='tab'
																aria-controls='pills-ApprovedSelling'
																aria-selected='false'
															>
																Approved
															</button>
														</li>
													</ul>
												</div>
												<div className='action-btn'>
													<div className='actions d-flex'>
														{/* Modal-Del */}
														<button
															className='add-btn'
															type='button'
															data-bs-toggle='offcanvas'
															data-bs-target='#offcanvasRight'
															aria-controls='offcanvasRight'
														>
															<AiOutlinePlus />
															<span className='px-1'> Add Products</span>
														</button>
													</div>
												</div>
											</div>
										</div>

										<div
											class='tab-content'
											id='pills-tabContent'
										>
											{/* Selling Product Pending*/}
											<div
												class='tab-pane fade show active'
												id='pills-PendingSelling'
												role='tabpanel'
												aria-labelledby='pills-PendingSelling-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle'
															style={{ borderColor: '#4f4f51' }}
														>
															<thead className='bg-light'>
																<tr>
																	<th>
																		<input
																			className='form-check-input'
																			type='checkbox'
																			value=''
																			id='flexCheckDefault'
																		/>
																	</th>
																	<th>Name</th>
																	<th>Image</th>
																	<th>Category</th>
																	<th>Seller</th>
																	<th>Price($)</th>
																	<th>Featured</th>
																	<th>View</th>
																	<th>ACTIONS</th>
																</tr>
															</thead>
															<tbody>
																{sellingProductData?.map((item, index) => {
																	if (item?.status === 'pending') {
																		return (
																			<>
																				<tr>
																					<td>
																						<input
																							className='form-check-input'
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																					</td>
																					<td>{item?.name}</td>
																					<td>
																						<div className='prod-img'>
																							<img
																								src={`${ApiUrl}/images/${item?.image}`}
																								alt=''
																							/>
																						</div>
																					</td>
																					<td>
																						{getcategories &&
																							getcategories[index]?.title}
																					</td>
																					<td>{seller?.name}</td>
																					<td>{item?.retailPrice}</td>

																					<td>
																						<span
																							className='text-ligh  '
																							style={{
																								color: '#278a1a',
																								fontSize: '10px',
																								backgroundColor: '#41ff2848',
																								borderRadius: 20,
																								padding: '5px 10px',
																							}}
																						>
																							{item?.state}
																						</span>
																					</td>

																					<td className='text-center'>
																						<button
																							onClick={() => {
																								handleSingleProduct(item?._id);
																								setShow(true);
																							}}
																							target='_blank'
																							class='btn btn-sm  py-1 text-center'
																						>
																							<AiFillEye className='fs-5' />
																						</button>
																					</td>
																					<td>
																						<div className='actions d-flex'>
																							<span
																								onClick={() => {
																									onDeleteStudent(item?._id);
																								}}
																								style={{ fontSize: 21 }}
																								className='px-3'
																							>
																								<RiDeleteBinLine />
																							</span>
																						</div>
																					</td>
																				</tr>
																			</>
																		);
																	}
																})}
															</tbody>
														</table>
													</div>
												</div>
											</div>

											{/* Selling Product Approved*/}
											<div
												class='tab-pane fade'
												id='pills-ApprovedSelling'
												role='tabpanel'
												aria-labelledby='pills-ApprovedSelling-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle'
															style={{ borderColor: '#4f4f51' }}
														>
															<thead className='bg-light'>
																<tr>
																	<th>Name</th>
																	<th>Image</th>
																	<th>Category</th>
																	<th>Seller</th>
																	<th>Price($)</th>
																	<th>Featured</th>
																	<th>Product Quantity</th>
																	<th>Add to Main Stock</th>
																	<th>View</th>
																	<th>ACTION</th>
																</tr>
															</thead>
															<tbody>
																{allproduct?.map((item, index) => {
																	if (item?.productStatus === 'approved') {
																		return (
																			<>
																				<tr>
																					<td>{item?.name}</td>
																					<td>
																						<div className='prod-img'>
																							<img
																								src={`${ApiUrl}/images/${item?.image}`}
																								alt=''
																							/>
																						</div>
																					</td>
																					<td>
																						{getcategories &&
																							getcategories[index]?.title}
																					</td>
																					<td>{seller?.name}</td>

																					<td>{item?.retailPrice}</td>
																					<td>
																						<span
																							className='text-ligh  '
																							style={{
																								color: '#278a1a',
																								fontSize: '10px',
																								backgroundColor: '#41ff2848',
																								borderRadius: 20,
																								padding: '5px 10px',
																							}}
																						>
																							{item?.state}
																						</span>
																					</td>
																					<td>
																						{item?.quantity} {item?.unit}
																					</td>
																					<td>
																						<button
																							onClick={() => {
																								setStockProduct(item?._id);
																								setStockName(item?.name);
																								setStockQuantity(
																									item?.quantity
																								);
																							}}
																							target='_blank'
																							class='btn btn-sm btn-success py-1'
																							data-bs-toggle='modal'
																							data-bs-target='#exampleModal3'
																						>
																							Stock In
																						</button>
																					</td>
																					<td className='text-center'>
																						<button
																							onClick={() => {
																								navigate(
																									`./productInfo/${item?._id}`
																								);
																								// setShow(true);
																							}}
																							target='_blank'
																							class='btn btn-sm btn-success py-1 text-center'
																						>
																							<AiFillEye className='fs-5' />
																						</button>
																					</td>
																					<td>
																						<div className='actions d-flex'>
																							<span
																								style={{
																									fontSize: 21,
																									cursor: 'pointer',
																								}}
																								onClick={() => {
																									setSellingProduct(item);
																									console.log(item);
																								}}
																								data-bs-toggle='offcanvas'
																								data-bs-target='#updateSellingProduct'
																								aria-controls='offcanvasRight'
																							>
																								<FiEdit />
																							</span>
																						</div>
																					</td>
																				</tr>
																			</>
																		);
																	}
																})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Product);
