import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';

const AllCanvas = ({ orderDetails }) => {
	const [updateStatus, setUpdateStatus] = useState('');
	const [orderId, setOrderId] = useState('');
	const [allData, setAllData] = useState([]);

	const fn_updateStatus = () => {};

	console.log(orderDetails, 'pppppppppppppppppppppppppppppppppppppppppppppp');
	return (
		<div>
			{orderDetails && (
				<>
					{/* <MdArrowBackIosNew className="orderListBackBtn" onClick={fn_backBtn} /> */}
					<h4 className='my-4'>Order Details</h4>
					<div className=' container updateOrder22'>
						<form
							onSubmit={fn_updateStatus}
							className='d-flex mb-4'
						>
							<select
								className='form-select'
								onChange={(e) => setUpdateStatus(e?.target?.value)}
							>
								<option
									selected
									value={''}
								>
									---Select Status---
								</option>
								<option value={'pending'}>Pending</option>
								<option value={'confirmed'}>Confirmed</option>
								<option value={'out for delivery'}>Out for Delivery</option>
								<option value={'cancelled'}>Cancelled</option>
								<option value={'returned'}>Returned</option>
								<option value={'failed to deliver'}>Failed to Deliver</option>
							</select>
							<button
								variant='success'
								type='submit'
								style={{
									border: 'none',
									color: 'white',
									backgroundColor: '#0b9444',
									borderTopRightRadius: '5px',
									borderBottomRightRadius: '5px',
								}}
							>
								Update
							</button>
						</form>
					</div>

					<div>
						<table className='orderDetailsTxt'>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Order ID</th>
								<td>{orderDetails?._id}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Order Date</th>
								<td>{new Date(orderDetails?.createdAt).toDateString()}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>User</th>
								<td>{orderDetails?.userName}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>City</th>
								<td>{orderDetails?.shippingDetails?.city}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Country</th>
								<td>{orderDetails?.shippingDetails?.country}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Shipping Address</th>
								<td>
									<p className='detailOrdersAddress fw-bold'>Current Address</p>
									<p className='detailOrdersAddress'>
										{orderDetails?.shippingDetails?.currentAddress}
									</p>
									<p className='detailOrdersAddress fw-bold'>
										Permanent Address
									</p>
									<p className='detailOrdersAddress'>
										{orderDetails?.shippingDetails?.permanentAddress}
									</p>
								</td>
							</tr>
							{orderDetails?.ProductsOrder?.map((item) => (
								<tr className='orderDetailsTxttr'>
									<td className='detailOrdersTableData'>
										<div>
											<img
												src={`${ApiUrl}/images/${item?.image}`}
												style={{ height: '80px' }}
											/>
										</div>
									</td>
									<td>
										<p className='detailOrdersAddress fw-bold'>{item?.name}</p>
										<p className='detailOrdersAddress'>
											Unit Price : <b>${item?.retailPrice}</b>
										</p>
										<p className='detailOrdersAddress'>
											Qty : <b>{item?.addToCartQty}</b>
										</p>
									</td>
								</tr>
							))}
							<tr className='orderDetailsTxttr'>
								<th
									className='detailOrdersTableData'
									style={{ fontSize: '23px', padding: '10px' }}
								>
									Total
								</th>
								<b>
									<td style={{ fontSize: '23px', padding: '10px' }}>
										$
										{orderDetails?.ProductsOrder?.reduce((i, j) => {
											return i + parseInt(j?.retailPrice);
										}, 0)}
									</td>
								</b>
							</tr>
						</table>
					</div>
				</>
			)}
		</div>
	);
};

export default AllCanvas;
