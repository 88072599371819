import React, { useEffect, useState } from 'react';
import './Wallet.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import withAuth from '../../../hooks/withAuth';

const Wallet = () => {
	const [topupRecord, setTopupRecord] = useState([]);
	const [withdrawRecord, setWithdrawRecord] = useState([]);
	const [sellerWallet, setSellerWallet] = useState([]);

	const [withdrawAmount, setWithdrawAmount] = useState('');
	const [bankAccount, setBankAccount] = useState('');
	const [bankName, setBankName] = useState('');
	const [accountHolder, setAccountHolder] = useState('');

	const [topupAmount, setTopupAmount] = useState('');
	const [imageTopup, setImageTopup] = useState();

	const [walletId, setWalletId] = useState('');
	const [receiverInfo, setReceiverInfo] = useState([]);
	const [receiverVerify, setReceiverVerify] = useState([]);

	const [amountTransfer, setAmountTransfer] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [allBanks, setAllBanks] = useState([]);
	const [allPayments, setAllPayments] = useState([]);
	const [paymentDetails, setPaymentDetails] = useState('');
	const [transactionId, setTransactionId] = useState('');

	const handleWithDraw = (e) => {
		e.preventDefault();
	};

	const handleTopup = (e) => {
		e.preventDefault();
	};

	const handleVerify = (walletId) => {};
	const handleUpdatePayment = () => {};

	const filterBanks = allBanks?.filter(
		(banks) => banks.country === localStorage?.getItem('country')
	);

	return (
		<div className='walletBox'>
			<ToastContainer />

			<div className='walletButton'>
				<h3
					className='walletBalance '
					npm
				>
					Available Balance : {sellerWallet?.length !== 0 ? sellerWallet : 0}$
				</h3>
				<button
					className='btn '
					data-bs-toggle='modal'
					data-bs-target='#exampleModal3'
				>
					Withdraw
				</button>
				{/* Withdraw Model Start */}
				<div
					class='modal fade'
					id='exampleModal3'
					tabindex='-1'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'
				>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5
									class='modal-title'
									id='exampleModalLabel'
								>
									Withdraw Amount
								</h5>
							</div>
							<div class='modal-body'>
								<from>
									<div className='col-md-12 mb-2 text-start'>
										<label>Amount to withdraw ($)</label>
										<input
											type='number'
											className='form-control'
											placeholder='Enter Amount to withdraw'
											value={withdrawAmount}
											onChange={(e) => setWithdrawAmount(e.target.value)}
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Bank Account Number</label>
										<input
											type='number'
											className='form-control'
											placeholder='Enter Bank Account Number'
											value={bankAccount}
											onChange={(e) => setBankAccount(e.target.value)}
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Bank Account Name</label>
										<input
											type='text'
											className='form-control'
											placeholder='Enter Bank Account Name'
											value={bankName}
											onChange={(e) => setBankName(e.target.value)}
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Account Holder Name</label>
										<input
											type='text'
											className='form-control'
											placeholder='Enter Account Holder Name'
											value={accountHolder}
											onChange={(e) => setAccountHolder(e.target.value)}
										/>
									</div>

									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Close
										</button>
										<button
											type='submit'
											onClick={handleWithDraw}
											class='btn btn-success'
											data-bs-dismiss='modal'
											style={{
												cursor: 'pointer',
												color: 'black',
												backgroundColor: '#ffd700',
											}}
										>
											Submit
										</button>
									</div>
								</from>
							</div>
						</div>
					</div>
				</div>
				{/* Withdraw Model End */}
				<button
					className='btn '
					data-bs-toggle='modal'
					data-bs-target='#exampleModal4'
				>
					TopUp
				</button>
				{/* TopUp Model Start */}
				<div
					class='modal fade'
					id='exampleModal4'
					tabindex='-1'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'
				>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5
									class='modal-title'
									id='exampleModalLabel'
								>
									TopUp Amount
								</h5>
							</div>
							<div class='modal-body'>
								<from>
									<div className='col-md-12 mb-2 text-start'>
										<label>Payment Method</label>
										<br />
										{filterBanks?.map((bank) => (
											<>
												<input
													type='radio'
													id='Bank Account'
													name='paymentMethod'
													className='ms-2'
													value={bank?.bankName}
													onClick={(e) => {
														setPaymentDetails(bank);
														setPaymentMethod(e.target.value);
													}}
												/>
												<label className='ms-2'>{bank?.bankName}</label>
											</>
										))}
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Account Number</label>
										<input
											type='text'
											className='form-control'
											placeholder='Receiver Account Number'
											defaultValue={paymentDetails?.accountNumber}
											disabled
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Account Holder Name</label>
										<input
											type='text'
											className='form-control'
											placeholder='Receiver Account Name'
											defaultValue={paymentDetails?.accountName}
											disabled
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Amount to TopUp ($)</label>
										<input
											type='number'
											className='form-control'
											placeholder='Enter Amount to Topup'
											value={topupAmount}
											onChange={(e) => setTopupAmount(e.target.value)}
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Transaction ID</label>
										<input
											type='text'
											className='form-control'
											placeholder='Enter Transaction Id'
											value={transactionId}
											onChange={(e) => setTransactionId(e.target.value)}
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Submit Proof</label>
										<div className='input-group mb-3'>
											<input
												type='file'
												className='form-control'
												id='inputGroupFile02'
												onChange={(e) => setImageTopup(e.target.files[0])}
											/>
										</div>
									</div>

									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Close
										</button>
										<button
											type='submit'
											onClick={handleTopup}
											class='btn btn-success'
											data-bs-dismiss='modal'
											style={{
												cursor: 'pointer',
												color: 'black',
												backgroundColor: '#ffd700',
											}}
										>
											Submit
										</button>
									</div>
								</from>
							</div>
						</div>
					</div>
				</div>
				{/* TopUp Model End */}
				<button
					className='btn'
					data-bs-toggle='modal'
					data-bs-target='#exampleModal5'
				>
					Payment Transfer
				</button>
				{/* Payment Method Model Start */}
				<div
					class='modal fade'
					id='exampleModal5'
					tabindex='-1'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'
				>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5
									class='modal-title'
									id='exampleModalLabel'
								>
									Payment Transfer
								</h5>
							</div>
							<div class='modal-body'>
								<from>
									<div className='col-md-12 mb-2 text-start'>
										<label>Receiver Wallet ID</label>
										<input
											type='text'
											className='form-control'
											placeholder='Enter Receiver Wallet ID'
											value={walletId}
											onChange={(e) => setWalletId(e.target.value)}
										/>
									</div>

									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Cancel
										</button>
										{walletId.length === 0 ? (
											<button
												type='submit'
												onClick={(e) => {
													e.preventDefault();
													handleVerify(walletId);
												}}
												class='btn btn-success'
												style={{
													cursor: 'pointer',
													color: 'black',
													backgroundColor: '#ffd700',
												}}
											>
												Verify
											</button>
										) : (
											<button
												type='submit'
												onClick={(e) => {
													e.preventDefault();
													handleVerify(walletId);
												}}
												class='btn btn-success'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal6'
												style={{
													cursor: 'pointer',
													color: 'black',
													backgroundColor: '#ffd700',
												}}
											>
												Verify
											</button>
										)}
									</div>
								</from>
							</div>
						</div>
					</div>
				</div>
				{/* Payment Method Model End */}

				{/* Payment Method Second Model Start */}
				<div
					class='modal fade'
					id='exampleModal6'
					tabindex='-1'
					aria-labelledby='exampleModalLabel2'
					aria-hidden='true'
				>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h5
									class='modal-title'
									id='exampleModalLabel'
								>
									Verify Payment
								</h5>
							</div>
							<div class='modal-body'>
								<from>
									<div className='col-md-12 mb-2 text-start'>
										<label>Receiver Wallet ID</label>
										<input
											type='text'
											className='form-control'
											placeholder='Receiver Wallet ID'
											value={walletId}
											disabled
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Receiver Name</label>
										<input
											type='text'
											className='form-control'
											placeholder='Receiver Name'
											value={receiverVerify?.name}
											disabled
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Receiver Email</label>
										<input
											type='text'
											className='form-control'
											placeholder='Receiver Email'
											value={receiverVerify?.email}
											disabled
										/>
									</div>

									<div className='col-md-12 mb-2 text-start'>
										<label>Amount to Transfer</label>
										<input
											type='number'
											className='form-control'
											placeholder='Enter Amoun to Transfer'
											value={amountTransfer}
											onChange={(e) => setAmountTransfer(e.target.value)}
										/>
									</div>

									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Cancel
										</button>
										<button
											type='submit'
											onClick={(e) => {
												e.preventDefault();
												handleUpdatePayment(walletId);
											}}
											class='btn btn-success'
											data-bs-dismiss='modal'
											style={{
												cursor: 'pointer',
												color: 'black',
												backgroundColor: '#ffd700',
											}}
										>
											Transfer
										</button>
									</div>
								</from>
							</div>
						</div>
					</div>
				</div>
				{/* Payment Method Second Model End */}
			</div>
			<hr />
			{/* Table of Withdraw and Topup */}
			<div>
				<ul
					className='nav nav-pills mb-3 me-5 d-flex'
					id='pills-tab'
					role='tablist'
				>
					<li
						className='nav-item'
						role='presentation'
					>
						<button
							className='nav-link active'
							id='pills-home-tab'
							data-bs-toggle='pill'
							data-bs-target='#pills-home'
							type='button'
							role='tab'
							aria-controls='pills-home'
							aria-selected='true'
						>
							Withdraw
						</button>
					</li>
					<li
						className='nav-item'
						role='presentation'
					>
						<button
							className='nav-link'
							id='pills-profile-tab'
							data-bs-toggle='pill'
							data-bs-target='#pills-profile'
							type='button'
							role='tab'
							aria-controls='pills-profile'
							aria-selected='false'
						>
							Topup
						</button>
					</li>
					<li
						className='nav-item'
						role='presentation'
					>
						<button
							className='nav-link'
							id='pills-profile-tab2'
							data-bs-toggle='pill'
							data-bs-target='#pills-profile2'
							type='button'
							role='tab'
							aria-controls='pills-profile'
							aria-selected='false'
						>
							Payment Transfer
						</button>
					</li>
				</ul>
				<div
					className='tab-content'
					id='pills-tabContent'
				>
					<div
						className='tab-pane fade show active'
						id='pills-home'
						role='tabpanel'
						aria-labelledby='pills-home-tab'
						tabIndex={0}
					>
						<div className='products-table'>
							<table
								className='table border rounded align-middle'
								style={{ borderColor: '#4f4f51' }}
							>
								<thead className='bg-light'>
									<tr>
										<th>Withdraw Amount</th>
										<th>Bank Account Number</th>
										<th>Bank Account Name</th>
										<th>Account Holder Name</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{withdrawRecord?.map((wr) => {
										if (
											wr?.embassdorId?.length === 0 &&
											wr?.userId?.length === 0
										) {
											return (
												<tr>
													<td>{wr?.amount}</td>
													<td>{wr?.number}</td>
													<td>{wr?.name}</td>
													<td>{wr?.holder}</td>
													<td
														style={{ textTransform: 'capitalize' }}
														className={
															wr?.status === 'approved'
																? 'text-success'
																: 'text-danger'
														}
													>
														{wr?.status}
													</td>
												</tr>
											);
										}
									})}
								</tbody>
							</table>
						</div>
					</div>

					<div
						className='tab-pane fade'
						id='pills-profile'
						role='tabpanel'
						aria-labelledby='pills-profile-tab'
						tabIndex={0}
					>
						<div className='products-table px-5'>
							<table
								className='table border rounded align-middle'
								style={{ borderColor: '#4f4f51', tableLayout: 'fixed' }}
							>
								<thead className='bg-light'>
									<tr>
										<th>Topup Amount</th>
										<th>Image Proof</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{topupRecord?.map((tu) => {
										if (
											tu?.embassdorId?.length === 0 &&
											tu?.userId?.length === 0
										) {
											return (
												<>
													<tr>
														<td>{tu?.amount}</td>
														<td>
															<div className='prod-img'>
																<img
																	src={`${ApiUrl}/images/${tu?.image}`}
																	alt=''
																/>
															</div>
														</td>
														<td
															style={{ textTransform: 'capitalize' }}
															className={
																tu?.status === 'approved'
																	? 'text-success'
																	: 'text-danger'
															}
														>
															{tu?.status}
														</td>
													</tr>
												</>
											);
										} else {
											<td></td>;
										}
									})}
								</tbody>
							</table>
						</div>
					</div>

					<div
						className='tab-pane fade'
						id='pills-profile2'
						role='tabpanel'
						aria-labelledby='pills-profile-tab2'
						tabIndex={0}
					>
						<div className='products-table px-5'>
							<table
								className='table border rounded align-middle'
								style={{ borderColor: '#4f4f51', tableLayout: 'fixed' }}
							>
								<thead className='bg-light'>
									<tr>
										<th>Serial No.</th>
										<th>Receiver ID</th>
										<th>Transfer Amount</th>
									</tr>
								</thead>
								<tbody>
									{allPayments?.map((transfer, index) => {
										if (transfer.fromId === localStorage.getItem('sellerId')) {
											return (
												<>
													<tr>
														<td>{index + 1}</td>
														<td>{transfer?.toId}</td>
														<td>{transfer?.amount}</td>
													</tr>
												</>
											);
										}
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withAuth(Wallet);
