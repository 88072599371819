
// // import React from 'react';
// // import './StyledInput.css'; // Import your CSS file

// // const StyledInput = ({ placeholderText, Icon }) => {
// //   return (
// //     <div className="container" style={{ marginTop: "8rem" }}>
// //       <div className="inputBox">
// //         <Icon className="icon" />
// //         <input type="text" required="required" />
// //         <span>{placeholderText}</span>
// //       </div>
// //     </div>
// //   );
// // };

// // export default StyledInput;


// import React from 'react';
// import './StyledInput.css'; // Import your CSS file

// const StyledInput = ({ placeholderText, Icon, type, register, error }) => {
//   return (
//     <div className="inputBox">
//       <Icon className="icon" />
//       <input type={type} required {...register} />
//       <span>{placeholderText}</span>
//       {error && <p style={{ color: 'red' }}>{error.message}</p>}
//     </div>
//   );
// };

// export default StyledInput;



import React from 'react';
import './StyledInput.css'; // Import your CSS file

const StyledInput = ({ placeholderText, Icon, type, value, onChange }) => {
    return (
        <div className="inputBox">
            <Icon className="icon" />
            <input
                type={type}
                value={value}
                onChange={onChange}
                required
            />
            <span>{placeholderText}</span>
        </div>
    );
};

export default StyledInput;
