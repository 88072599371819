import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AiFillCloseCircle } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Radio } from 'antd';
import { MdLocationOn } from 'react-icons/md';
import withAuth from '../../../hooks/withAuth';

function MainStockRacks() {
	const { id } = useParams();

	const navigate = useNavigate();
	const [rackId, setRackId] = useState(null);
	const [productValue, setProductValue] = useState('');
	const [stores, setStores] = useState([]);
	const [racks, setRacks] = useState([]);
	const [ambName, setAmbName] = useState('');
	const [sellerName, setSellerName] = useState('');
	const [transferQty, setTransferQty] = useState('');
	const [storeName, setStoreName] = useState('');
	const [storeLocation, setStoreLocation] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [unitSelect, setUnitSelect] = useState('ft');
	const [unitSearch, setUnitSearch] = useState('');
	const [rentUnit, setRentUnit] = useState('ft');
	const [storesid, setStoreId] = useState([]);
	const [productId, setProductID] = useState('');
	const [productState, setProductState] = useState('');
	const [mainStockProduct, setMainStockProduct] = useState(null);
	const [transferToValue, setTransferToValue] = useState('store');
	const [statestoreId, setStoresId] = useState('');
	const [buyRacks, setBuyRacks] = useState(null);
	const [statequantity, setQuantity] = useState('');
	const [rackUpdate, setRackUpdate] = useState();
	const [rackType, setRackType] = useState('floor');
	const [rackSize, setRackSize] = useState(0);
	const [filterRack, setFilterRack] = useState(null);
	const uniqueObjectsById = {};
	const [location, setLocation] = useState(null);
	const [weather, setWeather] = useState(null);
	const [adminData, setAdminData] = useState();
	function success(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setLocation({ latitude, longitude });
		// console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=db0e108a510e5ec03389a47fdbeefddf&units=metric`
		)
			.then((response) => response.json())
			.then((data) => {
				setWeather(data);
				// console.log(data);
			})
			.catch((error) => console.log(error));
	}
	function error() {
		console.log('Unable to retrieve your location');
	}
	const handleTransferTo = (e) => {
		setTransferToValue(e.target.value);
	};

	const handleCreateRack = (values) => {};

	const ownedRacks = racks?.filter(
		(own) => own?.type === 'owned' && own?.forSell === false
	);
	const myRentalRacks = racks?.filter(
		(own) => own?.forSell === true || own?.isBuy === true
	);

	// const filteredData = racks?.filter((item) => {
	//   if (searchTerm.length > 0) {
	//     return item?.name?.includes(searchTerm);
	//   } else {
	//     return item?.unit?.includes(unitSearch);
	//   }
	// });
	// const filteredData = []
	const [racksArray, setRackArray] = useState([]);

	const handleRentRack = (e) => {
		if (e?.target?.checked) {
			console.log(racksArray);
			racksArray?.push(e.target?.value);
		} else {
			setRackArray(racksArray?.filter((racks) => racks !== e.target?.value));
		}
	};

	const [selectedRadios, setSelectedRadios] = useState([]);
	const [rIds, setRIds] = useState([]);

	const handleChange = (e, option) => {
		if (e?.target.checked === true) {
			selectedRadios.push(e?.target?.value);
			setRIds((prev) => [
				...prev,
				{
					rackId: option._id,
					name: option.name,
					price: 0,
					front: option?.front,
					back: option?.back,
					height: option?.height,
					location: option?.location,
					rackType: option?.rackType,
				},
			]);
			console.log(rIds);
		} else if (e?.target?.checked === false) {
			setSelectedRadios(
				selectedRadios.filter((rack) => rack !== e?.target?.value)
			);
			const filteredId = rIds.filter(({ Id }) => Id !== option._id);
			setRIds(filteredId);
		}
	};

	const handleSellRack = () => {};

	const updateSend = (values) => {};

	const [productTotalQuantity, setProductTotalQuantity] = useState();
	const [minusProductQty, setMinusProductQty] = useState();
	const [storeTransfer, setStoreTransfer] = useState();

	const [selectStoreRack, setSelectStoreRack] = useState([]);
	const [stockLocation, setStockLocation] = useState('');

	const [rId, setRId] = useState([]);
	const [racksDetail, setRacksDetail] = useState([]);
	const handleSelectRack = (e, rack) => {
		if (e?.target?.checked) {
			setRId((prev) => [
				...prev,
				{ rackId: rack._id, name: rack.name, qty: 0 },
			]);
		} else if (e?.target?.checked === false) {
			setRId(rId.filter((rack) => rack !== e?.target?.value));
		}
	};
	function fn_handleRacksData(e, rack) {
		const qty = e.target.value;
		const tempRacks = rId.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].qty = qty;
		setRacksDetail(tempRacks);
	}
	function fn_handleRacksData2(e, rack) {
		const price = e.target.value;
		const tempRacks = rIds.slice();
		const itemIndex = tempRacks.findIndex((r) => r.rackId === rack.rackId);
		tempRacks[itemIndex].price = price;
		setRacksDetail(tempRacks);
	}
	const addTransferIn = (values) => {};
	const fn_handleSearchRacks = () => {
		if (rackType === 'all') {
			return setFilterRack(racks);
		}
		if (rackType !== 'all' && !rackSize && !unitSearch) {
			const searchResult = racks?.filter((item) =>
				item.rackType.toLowerCase().match(rackType)
			);
			return setFilterRack(searchResult);
		}
		if (!rackType || !rackSize || !unitSearch) {
			return toast.success('Fields cant be empty');
		}

		const combineUnit = (rackSize + unitSearch).toString();
		var regexp = new RegExp(combineUnit, 'gi');
		const searchResult = racks?.filter(
			(item) =>
				item.rackType.toLowerCase().match(rackType) && item.front.match(regexp)
		);

		setFilterRack(searchResult);
	};

	const fn_transfer = (item) => {
		setStoreTransfer(item);
	};

	const handleTransferRack = () => {};

	return (
		<>
			{/* fn_transfer........................ */}

			{/* ...///////////////////////////////////// */}

			<div>
				<header>
					{/* Transfer Racks Modal Start */}
					<div
						class='modal fade'
						id='exampleModall'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h1
										class='modal-title fs-5'
										id='exampleModalLabel'
									>
										Transfer for Rent
									</h1>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div
									className='modal-body'
									style={{ textAlign: 'left' }}
								>
									<input
										type='text'
										disabled
										value={`Admin Commision : $${adminData?.[0]?.rentalRacksComission} `}
										className='form-control mb-2'
										placeholder='Admin Racks Comission'
									/>
									<div
										className='row justify-content-between'
										style={{ maxHeight: '300px', overflowY: 'scroll' }}
									>
										{racks.map((option, index) => {
											if (
												option?.forSell === false &&
												option?.isBuy === false
											) {
												return (
													<>
														<div class=' col-12'>
															<label
																htmlFor={`option${option._id}`}
																key={option._id}
																className='mx-2'
															>
																<input
																	type='checkbox'
																	id={`option${option._id}`}
																	value={option._id}
																	disabled={option.state !== 'empty'}
																	onChange={(e) => {
																		handleChange(e, option);
																	}}
																/>
																<b className='ms-1'>{option?.name}</b>{' '}
																<span style={{ fontSize: '12px' }}>
																	(F : {option?.front}, B : {option?.back}, H :{' '}
																	{option?.height})
																</span>
																<span
																	style={{
																		fontSize: '14px',
																		marginLeft: '0.5rem',
																	}}
																>
																	[ Type : {option?.rackType} ]
																</span>
															</label>
														</div>
													</>
												);
											}
										})}
										{rIds?.map((rackData) => (
											<div className='col-6 mt-2 mb-3'>
												<input
													type='number'
													placeholder={'Enter Price for ' + rackData?.name}
													className='racks_input'
													onChange={(e) => fn_handleRacksData2(e, rackData)}
												/>
											</div>
										))}
										<br />
										<p className='text-start mt-3 mb-1 fw-bold'>Rack Image</p>
										<input
											type='file'
											id='image'
											className='form-control'
											placeholder='Paste Image'
										/>
									</div>
								</div>
								<div class='modal-footer'>
									<button
										type='button'
										class='btn btn-secondary'
										data-bs-dismiss='modal'
									>
										Close
									</button>
									<button
										type='button'
										class='btn btn-success'
										data-bs-dismiss='modal'
										onClick={handleSellRack}
										style={{
											backgroundColor: '#ffd700',
											border: 'none',
											color: 'black',
										}}
									>
										Post to Rent
									</button>
								</div>
							</div>
						</div>
					</div>
					{/* Transfer Racks Modal End */}
					{/* Create Racks Modal Start */}
					<div
						class='modal fade'
						id='createRacks'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleCreateRack(e?.target);
							}}
						>
							<div class='modal-dialog'>
								<div class='modal-content'>
									<div class='modal-header'>
										<h1
											class='modal-title fs-5'
											id='exampleModalLabel'
										>
											Create Racks
										</h1>
										<button
											type='button'
											class='btn-close'
											data-bs-dismiss='modal'
											aria-label='Close'
										></button>
									</div>
									<div className='modal-body text-start'>
										<span style={{ fontWeight: '600' }}>WareHouse Name :</span>
										<input
											className='form-control mt-1 mb-2'
											placeholder='WareHouseName'
											value={storeName}
											disabled
										/>
										<span style={{ fontWeight: '600' }}>
											Number of Racks to be Made:
										</span>
										<input
											type='number'
											name='rackQuantity'
											className='form-control mt-1 mb-2'
											placeholder='Enter Quantity'
										/>
										<span style={{ fontWeight: '600' }}>Rack Type:</span>
										<br />
										<Radio.Group
											defaultValue={''}
											buttonStyle='solid'
											className='mx-1 mt-1 mb-2'
											name='rackType'
										>
											<Radio.Button value='Floor Rack'>Floor Rack</Radio.Button>
											<Radio.Button value='Hanger Rack'>
												Hanger Rack
											</Radio.Button>
											<Radio.Button value='Shelf Rack'>Shelf Rack</Radio.Button>
										</Radio.Group>
										<br />
										<span style={{ fontWeight: '600' }}>Rack Size:</span>
										<br />
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Front</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='frontUnit'
												>
													<Radio.Button value='ft'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Front Size'
													name='frontSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
												/>
											</div>
										</div>
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Back</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='backUnit'
												>
													<Radio.Button value='ft'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Back Size'
													name='backSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
												/>
											</div>
										</div>
										<div className='row mb-1'>
											<div
												className='col-2'
												style={{ display: 'grid', placeItems: 'center' }}
											>
												<label>Height</label>
											</div>
											<div className='col-5'>
												<Radio.Group
													defaultValue={''}
													buttonStyle='solid'
													className='mx-1'
													name='heightUnit'
												>
													<Radio.Button value='ft'>Feet</Radio.Button>
													<Radio.Button value='inch'>Inch</Radio.Button>
													<Radio.Button value='meter'>Meter</Radio.Button>
												</Radio.Group>
											</div>
											<div className='col-5'>
												<input
													placeholder='Enter Height'
													name='heightSize'
													style={{
														height: '2rem',
														borderRadius: '0.3rem',
														border: '1px solid #DEE2E6',
														paddingLeft: '0.5rem',
													}}
												/>
											</div>
										</div>
									</div>
									<div class='modal-footer'>
										<button
											type='button'
											class='btn btn-secondary'
											data-bs-dismiss='modal'
										>
											Close
										</button>
										<button
											type='submit'
											class='btn btn-success'
											data-bs-dismiss='modal'
											style={{
												backgroundColor: '#ffd700',
												border: 'none',
												color: 'black',
											}}
										>
											Create Racks
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					{/* Create Racks Modal End */}
					{/* Product Transfer Modal Start */}
					<div
						class='modal fade'
						id='exampleModal3'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h5
										class='modal-title'
										id='exampleModalLabel'
									>
										Product Transfer to Store
									</h5>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div class='modal-body'>
									<from>
										<div className='col-md-12 text-start mb-2'>
											<label>Product</label>
											<select
												onChange={(e) => {
													const selectedIndex = e.target.selectedIndex;
													const selectedProduct =
														mainStockProduct[selectedIndex - 1];
													if (selectedProduct) {
														const { _id, state } = selectedProduct;
														setProductState({ state }?.state);
														setProductID({ _id }?._id);
													}
												}}
												name='productID'
												id='productId'
												class='form-control-input-2'
											>
												<option
													selected
													value={''}
												>
													--select product---
												</option>
												{mainStockProduct?.map((item) => {
													return (
														<option
															value={item?._id}
															id={item?._id}
														>
															{item?.name} ({item?.state})
														</option>
													);
												})}
											</select>
											{productTotalQuantity > 0 && (
												<p>
													<b>{productTotalQuantity}</b> Available Quantity in
													Main Stock
												</p>
											)}
										</div>
										<div className='col-md-12 text-start mb-2'>
											<label className='fw-bold'>Transfer to :</label>
											<br />
											<input
												type='radio'
												name='transferTo'
												defaultChecked
												onChange={handleTransferTo}
												value={'store'}
												className='mx-1'
											/>
											Store Racks
											<input
												type='radio'
												name='transferTo'
												onChange={handleTransferTo}
												value={'Rental Racks'}
												className='ms-5 me-1'
											/>
											Rental Racks
										</div>
										{transferToValue === 'store' ? (
											<div className='col-md-12 text-start'>
												<label>Store</label>
												<select
													name='storeID'
													onChange={(e) => setStoresId(e.target.value)}
													class='form-control-input-2'
												>
													<option
														selected
														value={''}
													>
														--select store name---
													</option>
													{storesid &&
														storesid?.map((item) => {
															return (
																<option value={item?._id}>{item?.name}</option>
															);
														})}
												</select>
											</div>
										) : (
											<div>
												{buyRacks &&
													buyRacks?.map((rackNumber) => (
														<>
															<input
																type='checkbox'
																value={rackNumber?._id}
																onChange={handleRentRack}
															/>
															<label className='me-5'>{rackNumber?.name}</label>
														</>
													))}
											</div>
										)}
										{selectStoreRack.length > 0 && stockLocation && (
											<>
												<p
													style={{
														fontSize: '1.05rem',
														textAlign: 'right',
														margin: '0.5rem 0',
													}}
												>
													<MdLocationOn />
													&nbsp;
													{stockLocation}
												</p>
											</>
										)}
										<div
											className='mt-3'
											style={{
												display: 'grid',
												gridTemplateColumns: '1fr 1fr',
											}}
										>
											{selectStoreRack.length > 0 ? (
												selectStoreRack?.map((rack) => {
													if (rack?.state === 'occupied') {
														return (
															<div className='text-start mb-2'>
																<input
																	type='checkbox'
																	disabled
																/>
																<label>
																	<b style={{ color: 'grey' }}>{rack?.name}</b>{' '}
																	<span style={{ fontSize: '12px' }}>
																		(F : {rack?.front}, B : {rack?.back}, H :{' '}
																		{rack?.height})
																	</span>
																</label>
															</div>
														);
													} else {
														return (
															<div className='text-start mb-2'>
																<input
																	type='checkbox'
																	value={rack?._id}
																	onChange={(e) => handleSelectRack(e, rack)}
																/>
																<label>
																	<b>{rack?.name}</b>{' '}
																	<span style={{ fontSize: '12px' }}>
																		(F : {rack?.front}, B : {rack?.back}, H :{' '}
																		{rack?.height})
																	</span>
																</label>
															</div>
														);
													}
												})
											) : (
												<>No Stock Available</>
											)}
											{rId?.map((rackData) => (
												<div className='col-6 mt-2 mb-3'>
													<input
														type='number'
														style={{ width: '200px' }}
														placeholder={'Enter capacity for ' + rackData?.name}
														className='racks_input'
														onChange={(e) => fn_handleRacksData(e, rackData)}
													/>
												</div>
											))}
										</div>
										<div class='modal-footer'>
											<button
												type='button'
												class='btn btn-secondary'
												data-bs-dismiss='modal'
											>
												Close
											</button>
											<button
												type='submit'
												onClick={(e) => {
													e.preventDefault();
													addTransferIn(
														e.target,
														racksDetail.reduce((acc, i) => {
															return acc + parseInt(i?.qty);
														}, 0)
													);
												}}
												class='btn btn-success'
												data-bs-dismiss='modal'
												style={{
													backgroundColor: '#ffd700',
													border: 'none',
													color: 'black',
												}}
											>
												Save changes
											</button>
										</div>
									</from>
								</div>
							</div>
						</div>
					</div>
					{/* Product Transfer Modal End */}
					{/* Product Dispatch Modal Start */}
					<div
						class='modal fade'
						id='dispatchProduct'
						tabindex='-1'
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h5
										class='modal-title'
										id='exampleModalLabel'
									>
										Product Dispatching
									</h5>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div class='modal-body'>
									<from>
										<div className='text-start'>
											<div>
												{racks.map((item) => {
													if (item?.product !== '') {
														return (
															<div
																className='row col-12'
																style={{
																	display: 'flex',
																	placeItems: 'center',
																	margin: '0.2rem 0',
																}}
															>
																<div className='col-6'>
																	<input
																		type='checkBox'
																		value={item?._id}
																	/>
																	<label className='ms-2'>
																		<b>{item?.name}</b> ({item?.productName}) [
																		Qty = {item?.productQty} ]
																	</label>
																</div>
																<div className='col-6'>
																	<input
																		type='number'
																		placeholder='Quantity to Dispatch'
																		className='form-control'
																	/>
																</div>
															</div>
														);
													}
												})}
											</div>
										</div>
										<div class='modal-footer'>
											<button
												type='button'
												class='btn btn-secondary'
												data-bs-dismiss='modal'
											>
												Close
											</button>
											<button
												type='submit'
												onClick={(e) => {
													e.preventDefault();
												}}
												class='btn btn-success'
												data-bs-dismiss='modal'
												style={{
													backgroundColor: '#ffd700',
													border: 'none',
													color: 'black',
												}}
											>
												Save changes
											</button>
										</div>
									</from>
								</div>
							</div>
						</div>
					</div>
					{/* Product Dispatch Modal End */}
					{/* in store product transfer */}
					<div
						class='modal fade'
						id='instoreproducttransfer'
						tabindex='-1'
						aria-labelledby='instoreproducttransferLabel'
						aria-hidden='true'
						style={{ overflow: 'hidden' }}
					>
						<div class='modal-dialog'>
							<div class='modal-content'>
								<div class='modal-header'>
									<h1
										class='modal-title fs-5'
										id='instoreproducttransferLabel'
									>
										Transfer Product in Store
									</h1>
									<button
										type='button'
										class='btn-close'
										data-bs-dismiss='modal'
										aria-label='Close'
									></button>
								</div>
								<div
									className='modal-body'
									style={{ textAlign: 'left' }}
								>
									<div
										className='row justify-content-between'
										style={{ maxHeight: '300px', overflowY: 'scroll' }}
									>
										<p>Product Name: {storeTransfer?.productName}</p>
										<h5>Quantity: {storeTransfer?.productQty}</h5>
										<hr />
										<h5 style={{ textAlign: 'center' }}>
											{' '}
											from{' '}
											<span style={{ color: 'green' }}>
												{storeTransfer?.name}
											</span>
										</h5>
										<div
											class='row g-1 align-items-center '
											style={{ textAlign: 'center', marginLeft: '50px' }}
										>
											<div class='col-auto'>
												<label
													for='inputPassword6'
													class='col-form-label'
												>
													Enter Quantity
												</label>
											</div>
											<div class='col-auto'>
												<input
													placeholder=' Quantity in Number'
													type='number'
													id='inputPassword6'
													class='form-control'
													aria-describedby='passwordHelpInline'
													onChange={(e) => setTransferQty(e.target.value)}
												/>
											</div>
										</div>

										<h5
											className='mt-3 mb-3'
											style={{ textAlign: 'center' }}
										>
											to
										</h5>
										<hr />
										{racks.map((option, index) => {
											if (
												option?.forSell === false &&
												option?.hasDimensions === true
											) {
												return (
													<>
														<div class=' col-12'>
															<label
																htmlFor={`option${option._id}`}
																key={option._id}
																className='mx-2'
															>
																<input
																	type='radio'
																	name='transrackPro'
																	id={`option${option._id}`}
																	value={option._id}
																	onChange={(e) => {
																		if (e?.target?.checked) {
																			setProductValue(option._id);
																		} else {
																			setProductValue('');
																		}
																	}}
																/>
																<b className='ms-1'>{option?.name}</b>{' '}
																<span style={{ fontSize: '12px' }}>
																	(F : {option?.front}, B : {option?.back}, H :{' '}
																	{option?.height})
																</span>
																<span
																	style={{
																		fontSize: '14px',
																		marginLeft: '0.5rem',
																	}}
																>
																	[ Type : {option?.rackType} ]
																</span>
																<br />
																{option?.state === 'occupied' ? (
																	<span
																		style={{
																			fontSize: '16px',
																			marginLeft: '0.5rem',
																		}}
																	>
																		Product : {option?.productName} | Qty :{' '}
																		{option?.productQty}
																	</span>
																) : (
																	<span
																		style={{
																			fontSize: '14px',
																			marginLeft: '0.5rem',
																			color: 'red',
																		}}
																	>
																		Empty Rack
																	</span>
																)}
																{productValue === option._id && <></>}
															</label>
															<hr />
														</div>
													</>
												);
											}
										})}
										{rIds?.map((rackData) => (
											<div className='col-6 mt-2 mb-3'>
												<input
													type='number'
													placeholder={'Enter Price for ' + rackData?.name}
													className='racks_input'
													onChange={(e) => fn_handleRacksData2(e, rackData)}
												/>
											</div>
										))}
										<br />
									</div>
								</div>
								<div class='modal-footer'>
									<button
										type='button'
										class='btn btn-secondary'
										data-bs-dismiss='modal'
									>
										Close
									</button>
									<button
										type='button'
										class='btn btn-success'
										data-bs-dismiss='modal'
										onClick={handleTransferRack}
									>
										Transfer
									</button>
								</div>
							</div>
						</div>
					</div>
					{/* in store product transfer End */}
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between align-items-center'>
									<div>
										<h5 className='kk ps-3'>Manage Main Stock Racks</h5>
									</div>
								</div>
								<div className='d-flex justify-content-between'>
									<ul
										class='nav nav-pills mb-3 d-flex justify-content-start'
										id='pills-tab'
										role='tablist'
									>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link active'
												id='pills-Owned-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-Owned'
												type='button'
												role='tab'
												aria-controls='pills-Owned'
												aria-selected='true'
											>
												Owned
											</button>
										</li>
										<li
											class='nav-item'
											role='presentation'
										>
											<button
												class='nav-link '
												id='pills-rentSide-tab'
												data-bs-toggle='pill'
												data-bs-target='#pills-rentSide'
												type='button'
												role='tab'
												aria-controls='pills-rentSide'
												aria-selected='false'
											>
												My Rental
											</button>
										</li>
									</ul>
									<div>
										<button
											type='button'
											class='btn btn-success btn-sm me-2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModall'
											style={{
												backgroundColor: '#ffd700',
												border: 'none',
												color: 'black',
											}}
										>
											Transfer Racks
										</button>
										<button
											type='button'
											class='btn btn-success btn-sm'
											data-bs-toggle='modal'
											data-bs-target='#dispatchProduct'
											style={{
												backgroundColor: '#ffd700',
												border: 'none',
												color: 'black',
											}}
										>
											Dispatch Product
										</button>
										<button
											type='button'
											class='btn btn-success btn-sm  ms-2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal3'
											style={{
												backgroundColor: '#ffd700',
												border: 'none',
												color: 'black',
											}}
										>
											Product Transfer
										</button>
										<button
											type='button'
											class='btn btn-success btn-sm ms-2'
											data-bs-toggle='modal'
											data-bs-target='#createRacks'
											style={{
												backgroundColor: '#ffd700',
												border: 'none',
												color: 'black',
											}}
										>
											Create Racks
										</button>
									</div>
								</div>
								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-Owned'
										role='tabpanel'
										aria-labelledby='pills-Owned-tab'
									>
										<div className='d-flex justify-content-between'>
											<div style={{ width: '15%' }}>
												<select
													style={{
														height: '100%',
														width: '100%',
														border: '1px solid rgba(0,0,0,0.1)',
													}}
													onChange={(e) => setRackType(e.target.value)}
												>
													<option
														disabled
														selected
													>
														---Select----
													</option>
													<option value='all'>All</option>
													<option value='hanger'>Hanger Rack</option>
													<option value='shelf'>Shelf Rack</option>
													<option value='floor'>Floor Rack</option>
												</select>
											</div>
											<div
												className='d-flex'
												style={{ height: '3rem' }}
											>
												<Radio.Group
													defaultValue=''
													onChange={(e) => {
														setUnitSearch(e?.target?.value);
														setSearchTerm('');
													}}
													buttonStyle='solid'
													style={{
														display: 'flex',
														flexDirection: 'row',
														margin: '0 1rem',
													}}
												>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='ft'
													>
														Feet
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='inch'
													>
														Inches
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='meter'
													>
														Meter
													</Radio.Button>
												</Radio.Group>
												<input
													type='number'
													className='form-control'
													value={rackSize}
													onChange={(e) => setRackSize(e.target.value)}
													placeholder='Enter rack size'
												/>
												<button
													type='button'
													class='btn btn-success ms-2'
													style={{
														width: '16rem',
														fontSize: '13px',
														backgroundColor: '#ffd700',
														border: 'none',
														color: 'black',
													}}
													onClick={fn_handleSearchRacks}
												>
													Search Racks
												</button>
											</div>
										</div>
										<div style={{ display: 'flex', margin: '1rem 0' }}>
											<div style={{ flex: '70%' }}>
												<h4>Total Number of Racks: {ownedRacks.length}</h4>
											</div>

											<input
												type='text'
												className='form-control'
												value={searchTerm}
												onChange={(e) => setSearchTerm(e.target.value)}
												placeholder='Search Rackss'
												style={{ flex: '30%' }}
											/>
										</div>
										<div className='row g-4'>
											{(filterRack ?? racks)?.map((item) => {
												if (!item?.forSell && !item?.isBuy) {
													if (item?.type === 'owned') {
														return (
															<div className='col-md-3'>
																<div
																	className='p-2'
																	style={{
																		backgroundColor: '#fff',
																		border: ' 1px solid rgb(243, 239, 239)',
																		borderRadius: '10px',
																	}}
																>
																	<div
																		className='order'
																		style={{
																			display: 'flex',
																			flexDirection: 'column',
																			justifyContent: 'space-between',
																		}}
																	>
																		<div
																			style={{ cursor: 'pointer' }}
																			data-bs-toggle='modal'
																			data-bs-target='#ownedModal1'
																			onClick={() => {
																				setRackId(item?._id);
																				setRackUpdate(item);
																			}}
																		>
																			<h4>{item?.name}</h4>
																		</div>
																		<div style={{ zIndex: '999' }}>
																			{item?.hasDimensions && (
																				<span
																					style={{
																						fontSize: '0.95rem',
																						backgroundColor: 'rgb(220 231 244)',
																						padding: '0.2rem 0.08rem',
																						borderRadius: '0.1rem',
																						textAlign: 'center',
																					}}
																				>
																					front:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.front}
																					</span>
																					, back:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.back}
																					</span>
																					, height:{' '}
																					<span style={{ fontWeight: '600' }}>
																						{item?.height}
																					</span>
																				</span>
																			)}
																		</div>
																	</div>

																	<div className='d-flex justify-content-between pt-3'>
																		<p>
																			{item?.state === 'empty' ? (
																				<span
																					style={{
																						textTransform: 'capitalize',
																					}}
																				>
																					{item?.state}
																				</span>
																			) : (
																				<>
																					Product : <b>{item?.productName}</b>
																					<br />
																					Qty :{' '}
																					<b>
																						{item?.productQty}{' '}
																						{item?.productUnit}
																					</b>
																					<br />
																					Product Type :{' '}
																					<b
																						style={{
																							textTransform: 'capitalize',
																						}}
																					>
																						{item?.productDetails?.state}
																					</b>
																					<p
																						style={{
																							marginTop: '10px',
																							fontSize: '14px',
																							padding: '10px',
																							backgroundColor: '#0b9444',
																							color: 'white',
																							borderRadius: '20px',
																							textAlign: 'center',
																							display: 'flex',
																							justifyContent: 'space-Between',
																							width: '200px',
																							alignItems: 'center',
																							marginBottom: '-20px',
																						}}
																						class='btn btn-success  btn-sm me-2'
																						data-bs-toggle='modal'
																						data-bs-target='#instoreproducttransfer'
																						onClick={() => fn_transfer(item)}
																					>
																						Transfer{' '}
																						<i class='fa-solid fa-arrow-right-arrow-left'></i>
																					</p>
																				</>
																			)}
																		</p>
																		{item?.state === 'empty' ? (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<AiFillCloseCircle
																					size={20}
																					color='red'
																				/>
																			</div>
																		) : (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<i
																					class='fa-solid fa-check'
																					style={{
																						fontSize: '18px',

																						color: ' #ffd700',
																					}}
																				></i>
																			</div>
																		)}
																	</div>
																	<hr />
																	{item?.hasDimensions && (
																		<div className='mb-2'>
																			<span>
																				Type : <b>{item?.rackType}</b>
																			</span>
																		</div>
																	)}
																	<div>
																		<button
																			onClick={() => {
																				navigate(`/viewProduct/${item?._id}`);
																				localStorage.setItem(
																					'RackName',
																					item?.name
																				);
																			}}
																			class='btn w-100 text-right btn-success'
																		>
																			View Rack
																		</button>
																	</div>
																</div>
															</div>
														);
													}
												}
											})}
										</div>
										{/* owned model1 */}
										<div
											class='modal fade'
											id='ownedModal1'
											tabindex='-1'
											aria-labelledby='exampleModalLabel'
											aria-hidden='true'
										>
											<div class='modal-dialog w-100'>
												<div class='modal-content w-100'>
													<div class='modal-body w-100'>
														<form
															onSubmit={(e) => {
																e.preventDefault();
																updateSend(e.target);
															}}
														>
															<div className='row pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Select Unit
																	</label>
																</div>
																<div className='col-md-9'>
																	<Radio.Group
																		defaultValue={'ft'}
																		buttonStyle='solid'
																		name='unit'
																		onChange={(e) =>
																			setUnitSelect(e?.target?.value)
																		}
																	>
																		<Radio.Button value='ft'>Feet</Radio.Button>
																		<Radio.Button value='inch'>
																			Inch
																		</Radio.Button>
																		<Radio.Button value='meter'>
																			Meter
																		</Radio.Button>
																	</Radio.Group>
																</div>
															</div>
															<div className='row pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Front ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='front'
																		placeholder='Enter Rack Front Size'
																		defaultValue={rackUpdate?.front}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Back ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='back'
																		placeholder='Enter Rack Back Size'
																		defaultValue={rackUpdate?.back}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Height ({unitSelect})
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		name='height'
																		placeholder='Enter Rack Height'
																		defaultValue={rackUpdate?.height}
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Rack Type
																	</label>
																</div>
																<div className='col-md-9'>
																	<Radio.Group
																		defaultValue={rackUpdate?.rackType.toString()}
																		buttonStyle='solid'
																		name='rackType'
																	>
																		<Radio.Button value='Floor Rack'>
																			Floor Rack
																		</Radio.Button>
																		<Radio.Button value='Hanger Rack'>
																			Hanger Rack
																		</Radio.Button>
																		<Radio.Button value='Shelf Rack'>
																			Shelf Rack
																		</Radio.Button>
																	</Radio.Group>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-3'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Type
																	</label>
																</div>
																<div className='col-md-9'>
																	<input
																		className='form-control'
																		value={'Owned'}
																		disabled
																	/>
																</div>
															</div>
															{/* <div className="row  pt-4  align-items-center">
                                <div className="col-md-2">
                                  <label
                                    for="inputPassword6"
                                    className="form-label"
                                  >
                                    State
                                  </label>
                                </div>
                                <div className="col-md-10">
                                  <select
                                    class="form-control-input-2"
                                    name="state"
                                  >
                                    <option value="" disabled selected>
                                      ---Select---
                                    </option>
                                    <option value="occupied">Occupied</option>
                                    <option value="empty">Empty</option>
                                  </select>
                                </div>
                              </div> */}
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																		data-bs-dismiss='modal'
																		style={{
																			backgroundColor: '#ffd700',
																			border: 'none',
																			color: 'black',
																		}}
																	>
																		Save
																	</button>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										class='tab-pane fade '
										id='pills-rentSide'
										role='tabpanel'
										aria-labelledby='pills-rentSide-tab'
									>
										<div className='d-flex justify-content-between'>
											<h3 className='py-4'>
												Total Number of Racks: {myRentalRacks.length}
											</h3>
											<div
												className='d-flex'
												style={{ height: '3rem' }}
											>
												<Radio.Group
													defaultValue=''
													onChange={(e) => {
														setUnitSearch(e?.target?.value);
														setSearchTerm('');
													}}
													buttonStyle='solid'
													style={{
														display: 'flex',
														flexDirection: 'row',
														margin: '0 1rem',
													}}
												>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='ft'
													>
														Feet
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='inch'
													>
														Inches
													</Radio.Button>
													<Radio.Button
														style={{ height: '3rem', lineHeight: '2.9rem' }}
														value='meter'
													>
														Meter
													</Radio.Button>
												</Radio.Group>
												<input
													type='text'
													className='form-control'
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													placeholder='Search Rack'
												/>
												<button
													type='button'
													class='btn btn-success ms-2'
													data-bs-toggle='modal'
													data-bs-target='#exampleModall'
													style={{
														width: '16rem',
														fontSize: '13px',
														backgroundColor: '#ffd700',
														border: 'none',
														color: 'black',
													}}
													onClick={fn_handleSearchRacks}
												>
													Search Racks
												</button>
											</div>
										</div>
										<div className='row g-4'>
											{(filterRack ?? racks)?.map((item, index) => {
												if (item?.forSell || item?.isBuy) {
													return (
														<div className='col-md-3'>
															<div
																className='p-2'
																style={{
																	backgroundColor: '#fff',
																	border: ' 1px solid rgb(243, 239, 239)',
																	borderRadius: '10px',
																}}
															>
																<div
																	className='order'
																	style={{
																		display: 'flex',
																		justifyContent: 'space-between',
																		flexDirection: 'column',
																	}}
																>
																	<div
																		style={{ cursor: 'pointer' }}
																		data-bs-toggle='modal'
																		data-bs-target='#ownedModal1'
																		onClick={() => {
																			setRackId(item?._id);
																		}}
																	>
																		<h4>{item?.name}</h4>
																	</div>
																	<div style={{ zIndex: '999' }}>
																		{item?.hasDimensions && (
																			<span
																				style={{
																					fontSize: '0.95rem',
																					backgroundColor: 'rgb(220 231 244)',
																					padding: '0.2rem 0.08rem',
																					borderRadius: '0.1rem',
																					textAlign: 'center',
																				}}
																			>
																				front:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{item?.front}
																				</span>
																				, back:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{item?.back}
																				</span>
																				, height:{' '}
																				<span style={{ fontWeight: '600' }}>
																					{item?.height}
																				</span>
																			</span>
																		)}
																	</div>
																</div>

																<div className='d-flex justify-content-between pt-3'>
																	<p>{item?.state}</p>
																	{item?.state === 'empty' ? (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<AiFillCloseCircle
																				size={20}
																				color='red'
																			/>
																		</div>
																	) : (
																		<div
																			className=''
																			style={{
																				background: '#FEECDC',
																				width: '30px',
																				height: '30px',
																				lineHeight: '30px',
																				borderRadius: '50%',
																				justifyContent: 'center',
																				alignItems: 'center',
																				display: 'flex',
																			}}
																		>
																			<i
																				class='fa-solid fa-check'
																				style={{
																					fontSize: '18px',

																					color: ' #ffd700',
																				}}
																			></i>
																		</div>
																	)}
																</div>
																<hr />
																{item?.hasDimensions && (
																	<div className='mb-2'>
																		<span>
																			Type : <b>{item?.rackType}</b>
																		</span>
																	</div>
																)}
																<div>
																	<button
																		onClick={() => {
																			navigate(`/viewProduct/${item?._id}`);
																			localStorage.setItem(
																				'RackName',
																				item?.name
																			);
																		}}
																		class='btn  w-100 text-right btn-success'
																	>
																		View Rack
																	</button>
																</div>
															</div>
														</div>
													);
												}
											})}
										</div>

										<div
											class='modal fade'
											id='myRentalModal1'
											tabindex='-1'
											aria-labelledby='exampleModalLabel'
											aria-hidden='true'
										>
											<div class='modal-dialog w-100'>
												<div class='modal-content w-100'>
													<div class='modal-body w-100'>
														<form
															onSubmit={(e) => {
																e.preventDefault();
																updateSend(e.target);
															}}
														>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		width (ft)
																	</label>
																</div>
																<div className='col-md-10'>
																	<input
																		className='form-control'
																		name='width'
																		placeholder='Enter Size'
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Length (ft)
																	</label>
																</div>
																<div className='col-md-10'>
																	<input
																		className='form-control'
																		name='height'
																		placeholder='Enter Size'
																	/>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		Type
																	</label>
																</div>
																<div className='col-md-10'>
																	<select
																		class='form-control-input-2 p-1'
																		aria-label='Default select example'
																		name='type'
																	>
																		<option
																			value=''
																			disabled
																			selected
																		>
																			---Select---
																		</option>
																		<option value='owned'>Owned</option>
																		<option value='myrental'>My Rental</option>
																		{/* <option value="onrental">On Rental</option> */}
																	</select>
																</div>
															</div>
															<div className='row  pt-4  align-items-center'>
																<div className='col-md-2'>
																	<label
																		for='inputPassword6'
																		className='form-label'
																	>
																		State
																	</label>
																</div>
																<div className='col-md-10'>
																	<select
																		class='form-control-input-2'
																		name='state'
																	>
																		<option
																			value=''
																			disabled
																			selected
																		>
																			---Select---
																		</option>
																		<option value='occupied'>Occupied</option>
																		<option value='empty'>Empty</option>
																	</select>
																</div>
															</div>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																		data-bs-dismiss='modal'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																		data-bs-dismiss='modal'
																		style={{
																			backgroundColor: '#ffd700',
																			border: 'none',
																			color: 'black',
																		}}
																	>
																		Save
																	</button>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(MainStockRacks);
