import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ApiUrl from '../BaseUrl';
import { useParams } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';
const ViewProduct = () => {
	const { id } = useParams();
	const [rack, setRack] = useState();
	const [sellerAllRacks, setSellerAllRacks] = useState();
	const [rackRecord, setRackRecord] = useState();
	const [sellerId, setSellerId] = useState('');
	const [sellerData, setSellerData] = useState();
	const [buyerId, setBuyerId] = useState('');
	const [buyerData, setBuyerData] = useState();
	const [sellerName, setSellerName] = useState('');
	const [singleRack, setSingleRack] = useState();
	const [categories, setCategories] = useState();

	return (
		<>
			<div>
				<div className='container-fluid py-5'>
					<div className='row pt-5'>
						<div className='col-md-10 mx-auto'>
							{categories ? (
								<article className='postcard dark blue'>
									<a className='postcard__img_link'>
										<img
											className='postcard__img'
											src={`${ApiUrl}/images/${categories?.image}`}
											alt='Image Title'
										/>
									</a>
									<div className='postcard__text'>
										<h2 className='ms-3'>{categories?.name}</h2>
										<div className='postcard__bar' />
										<div className=''>
											Available Quantity :{' '}
											<b className='text-info h5'>
												{categories?.quantity} {categories?.unit}
											</b>
										</div>
										<div className=''>
											Product Price :{' '}
											<b className='text-info h5'>
												{categories?.productPrice} Rs
											</b>
										</div>
										<div className=''>
											Admin Comission :{' '}
											<b className='text-info h5'>
												{categories?.companyComission} %
											</b>
										</div>
										<div className=''>
											Ambassador Comission :{' '}
											<b className='text-info h5'>
												{categories?.ambassadorComission} %
											</b>
										</div>
										<div className=''>
											Referral Comission :{' '}
											<b className='text-info h5'>
												{categories?.referralComission} %
											</b>
										</div>
										<div className=''>
											Product Retail Price :{' '}
											<b className='text-info h5'>
												{categories?.retailPrice} Rs
											</b>
										</div>
										<hr />
										<div className=''>
											Product Code :{' '}
											<b className='text-info h5'>{categories?.code}</b>
										</div>
										<div className=''>
											Product Quality :{' '}
											<b className='text-info h5'>{categories?.quality}</b>
										</div>
										<div className=''>
											<b className='text-info h5'>
												Made in {categories?.country}
											</b>
										</div>
									</div>
									<hr />
								</article>
							) : (
								<h4 className='text-danger text-center mb-4'>
									No Product Available in this Rack
								</h4>
							)}
							{/* Rack Details */}
							<div className='viewRackDetails row'>
								<h2 className='text-center text-underline'>Rack Details</h2>
								<div className='col-md-12'>
									<p className='rackText'>
										Rack Name : <b>{localStorage.getItem('RackName')}</b>
									</p>
									<p
										className='rackText'
										style={{ textTransform: 'capitalize' }}
									>
										State : <b>{rackRecord?.state}</b>
									</p>
									<p className='rackText'>
										Rack Type :{' '}
										<b>
											{rackRecord?.type === 'owned' ? 'Owned' : 'My Rental'}
										</b>
									</p>
									{rackRecord?.type !== 'owned' && (
										<p
											className='rackText'
											style={{ textTransform: 'capitalize' }}
										>
											Buying Condition :{' '}
											<b className='text-danger'>
												{rackRecord?.isBuy === true &&
												rackRecord?.forSell === false
													? 'Bought'
													: 'Pending'}
											</b>
										</p>
									)}
									{rackRecord?.front.length > 0 ? (
										<p className='rackText'>
											Size :{' '}
											<b>
												F: {rackRecord?.front}, B: {rackRecord?.back}, H:{' '}
												{rackRecord?.height}
											</b>
										</p>
									) : (
										<b className='ms-2 text-danger'>**Size don't Specified</b>
									)}
								</div>
							</div>
							{/* Owner Details */}
							{rackRecord?.sellerID !== '' && (
								<div className='viewRackDetails row mt-5'>
									<h2 className='text-center text-underline'>Owner Details</h2>
									<div className='col-md-12'>
										<p className='rackText'>
											Buyer Name : <b>{sellerData?.name}</b>
										</p>
										<p
											className='rackText'
											style={{ textTransform: 'capitalize' }}
										>
											Contact Number : <b>{sellerData?.phoneNo}</b>
										</p>
										<p className='rackText'>
											Office Address : <b>{sellerData?.address}</b>
										</p>
										<p className='rackText'>
											Buyer City : <b>{sellerData?.city}</b>
										</p>
										<p className='rackText'>
											Office Country : <b>{sellerData?.country}</b>
										</p>
									</div>
								</div>
							)}
							{/* Buyer Details */}
							{rackRecord?.buyerId !== '' && (
								<div className='viewRackDetails row mt-5'>
									<h2 className='text-center text-underline'>Buyer Details</h2>
									<div className='col-md-12'>
										<p className='rackText'>
											Buyer Name : <b>{buyerData?.name}</b>
										</p>
										<p
											className='rackText'
											style={{ textTransform: 'capitalize' }}
										>
											Contact Number : <b>{buyerData?.phoneNo}</b>
										</p>
										<p className='rackText'>
											Office Address : <b>{buyerData?.address}</b>
										</p>
										<p className='rackText'>
											Buyer City : <b>{buyerData?.city}</b>
										</p>
										<p className='rackText'>
											Buyer Country : <b>{buyerData?.country}</b>
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withAuth(ViewProduct);
